

// import TrelloViewTest from "./Components/test2";
// import SummaryComponent from "./Components/test";
// function App() {
//   return (
//     <div CreateInvoice="App">
  
//      <SummaryComponent/>
//      {/* <YourComponent/> */}
   

    
     
//     </div>
//   );
// }
// export default App;





import CreateInvoice from "./Components/createInvoice";
import CompanyDetails from "./Components/companyDetails";
import AddCompany from "./Components/newCompany";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./Components/header";
import Dashboard from "./Components/dashboard"
import SettingsMain from "./Components/settings/Settings"
import NewAppWebsh from "./Components/appwebsh/NewAppWebsh"

const App = () => {
  return (
    <Router>
        <Routes>
          <Route path="/" element={<Header />} >
          <Route index element={<Dashboard />} />
          <Route path="create-invoice" element={<CreateInvoice/>}/>
            <Route path="add-company" element={<AddCompany/>}/>
            <Route path="company-details" element={<CompanyDetails/>}/>
            <Route path="settings" element={<SettingsMain/>}/>
            <Route path="app-websh" element={<NewAppWebsh/>}/>
           
          </Route>
        </Routes>
      
    </Router>
  );
};

export default App;

