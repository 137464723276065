import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Box, Button, TextField } from "@mui/material";
import FormLabel from '@mui/material/FormLabel';
import FormControl from "@mui/material/FormControl";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from "@mui/material/FormControlLabel";

import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

const invoiceTexRates = [
  { label: "Normal" },
  { label: "Strange" },
  { label: "Extraordinary" },
  { label: "Irregular" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ImportExportTab({ handleDrawerClose,selectedTab }) {
  const [state, setState] = React.useState({
    jason: true,
    AutoArchiveCancelled: true,


    ShowPDFPreview: true,
    UseBrowserPDFViewer: false,

   
   
  });

  // const [valueRedio, setvalueRedio] = React.useState('Bottom');
  const [radioValues, setRadioValues] = React.useState({
    previewLocation: 'Bottom',
    layout: 'Desktop',
    CollapseValue:"Collapse",
    FloatValue:"Float",
    HidePreviewValue:"Hide Preview",
    EditRecordValue:"Edit Record",
  });
  
  const handleChangeRadio = (event) => {
    setRadioValues({
      ...radioValues,
      [event.target.name]: event.target.value
    });
  };

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Options")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Colors")}
            {...a11yProps(1)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>
  {/* for mobile */}

  <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Options")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Colors")}
            {...a11yProps(1)}
          />
          
        </Tabs>

      
      </Box>

<div className={"mobilVied"}>
<TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "240px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
         
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
    
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Layout
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="layout"
                  value={radioValues.layout}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      // justifyContent: "space-between",
                      gridTemplateColumns:'47% 47%',
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <FormControlLabel
                      value="Desktop"
                      control={<Radio />}
                      label="Desktop"
                    />
                    <FormControlLabel
                      value="Mobile"
                      control={<Radio />}
                      label=" Mobile"
                    />
                  </span>
                </RadioGroup>


                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                 Menu Sidebar
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="CollapseValue"
                  value={radioValues.CollapseValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      // justifyContent: "space-between",
                      gridTemplateColumns:'47% 47%',
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <FormControlLabel
                      value="Collapse"
                      control={<Radio />}
                      label="Collapse"
                    />
                    <FormControlLabel
                      value=" Float"
                      control={<Radio />}
                      label="  Float"
                    />
                  </span>
                </RadioGroup>




                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                 History Sidebar
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="FloatValue"
                  value={radioValues.FloatValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      // justifyContent: "space-between",
                      gridTemplateColumns:'47% 47%',
                     
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <FormControlLabel
                      value="Show/hide"
                      control={<Radio />}
                      label="Show/hide"
                    />
                    <FormControlLabel
                      value="Float"
                      control={<Radio />}
                      label=" Float"
                    />
                  </span>
                </RadioGroup>



                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Click Selected
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="HidePreviewValue"
                  value={radioValues.HidePreviewValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      // justifyContent: "space-between",
                      gridTemplateColumns:'47% 47%',
                   
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <FormControlLabel
                      value="Hide Preview"
                      control={<Radio />}
                      label="Hide Preview"
                    />
                    <FormControlLabel
                      value="Edit Record"
                      control={<Radio />}
                      label=" Edit Record"
                    />
                  </span>
                </RadioGroup>


                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  After Saving
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="EditRecordValue"
                  value={radioValues.EditRecordValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      // justifyContent: "space-between",
                      gridTemplateColumns:'47% 47%',
                     
                      alignItems: "center",
                      columnGap: "10px",
                    }}
                  >
                    <FormControlLabel
                      value="View Record"
                      control={<Radio />}
                      label="View Record"
                    />
                    <FormControlLabel
                      value="Edit Record"
                      control={<Radio />}
                      label=" Edit Record"
                    />
                  </span>
                </RadioGroup>
              </FormControl>
          
          </Box>
        </div>



        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
         
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl component="fieldset" variant="standard">
            <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/PDF.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Show PDF Preview
         </Typography>
       </div>
     }
       control={<Switch checked={state.ShowPDFPreview} onChange={handleChangebutton} name="ShowPDFPreview" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Display PDF preview while <br/>editing invoices</FormHelperText>




          <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/PDF.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Use Browser PDF Viewer
         </Typography>
       </div>
     }
       control={<Switch checked={state.UseBrowserPDFViewer} onChange={handleChangebutton} name="UseBrowserPDFViewer" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Warning:prevents interacting with <br/> app over the PDF</FormHelperText>

            

             
        

            </FormControl>

            <div>
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Preview Location
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="previewLocation"
                  value={radioValues.previewLocation}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"67% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="Bottom"
                      control={<Radio />}
                      label="Bottom"
                    />
                    <FormControlLabel
                      value="Side"
                      control={<Radio />}
                      label=" Side"
                    />
                  </span>
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom: "100px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Font Size" variant="standard" />
              )}
            />

            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/searchLogo.svg" alt="Imagee" />
                    <Typography variant="body1" component="span">
                      Enable Flexibl Search
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.AutoArchivePaid}
                    onChange={handleChangebutton}
                    name="AutoArchivePaid"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Match non-contiguous characters,<br/> ie. ct matches cat
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/Enable.svg" alt="Images" />
                    <Typography variant="body1" component="span">
                      Enable Touch Events
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.AutoArchiveCancelled}
                    onChange={handleChangebutton}
                    name="AutoArchiveCancelled"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Suport drag events to scroll
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/MM.svg" alt="Imagee" />
                    <Typography variant="body1" component="span">
                      Enable Tooltips
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.jason}
                    onChange={handleChangebutton}
                    name="jason"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Show tooltips when hovering <br/> the mouse
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/Dd.svg" alt="Images" />
                    <Typography variant="body1" component="span">
                      Persist Data
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.antoine}
                    onChange={handleChangebutton}
                    name="antoine"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Save data locally to enable the app <br/> to start faster,disabling
                may improve <br/> performance in large accounts
              </FormHelperText>
            </FormControl>
          </Box>
        </div>
      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={1}>
        Item Two
      </TabPanel>
</div>
<div className={"webview"}>
<TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
         
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
    
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Layout
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="layout"
                  value={radioValues.layout}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"20% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="Desktop"
                      control={<Radio />}
                      label="Desktop"
                    />
                    <FormControlLabel
                      value="Mobile"
                      control={<Radio />}
                      label=" Mobile"
                    />
                  </span>
                </RadioGroup>


                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                 Menu Sidebar
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="CollapseValue"
                  value={radioValues.CollapseValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"20% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="Collapse"
                      control={<Radio />}
                      label="Collapse"
                    />
                    <FormControlLabel
                      value=" Float"
                      control={<Radio />}
                      label="  Float"
                    />
                  </span>
                </RadioGroup>




                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                 History Sidebar
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="FloatValue"
                  value={radioValues.FloatValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"20% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="Show/hide"
                      control={<Radio />}
                      label="Show/hide"
                    />
                    <FormControlLabel
                      value="Float"
                      control={<Radio />}
                      label=" Float"
                    />
                  </span>
                </RadioGroup>



                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Click Selected
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="HidePreviewValue"
                  value={radioValues.HidePreviewValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"20% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="Hide Preview"
                      control={<Radio />}
                      label="Hide Preview"
                    />
                    <FormControlLabel
                      value="Edit Record"
                      control={<Radio />}
                      label=" Edit Record"
                    />
                  </span>
                </RadioGroup>


                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  After Saving
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="EditRecordValue"
                  value={radioValues.EditRecordValue}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"20% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="View Record"
                      control={<Radio />}
                      label="View Record"
                    />
                    <FormControlLabel
                      value="Edit Record"
                      control={<Radio />}
                      label=" Edit Record"
                    />
                  </span>
                </RadioGroup>
              </FormControl>
          
          </Box>
        </div>



        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
         
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl component="fieldset" variant="standard">
            <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/PDF.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Show PDF Preview
         </Typography>
       </div>
     }
       control={<Switch checked={state.ShowPDFPreview} onChange={handleChangebutton} name="ShowPDFPreview" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Display PDF preview while editing invoices</FormHelperText>




          <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/PDF.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Use Browser PDF Viewer
         </Typography>
       </div>
     }
       control={<Switch checked={state.UseBrowserPDFViewer} onChange={handleChangebutton} name="UseBrowserPDFViewer" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Warning:prevents interacting with app over the PDF</FormHelperText>

            

             
        

            </FormControl>

            <div>
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  style={{ fontSize: "12px", fontWeight: "400" }}
                >
                  Preview Location
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="previewLocation"
                  value={radioValues.previewLocation}
                  onChange={handleChangeRadio}
                >
                  <span
                    style={{
                      display: "grid",
                      gridTemplateColumns:"67% 20%",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "60px",
                    }}
                  >
                    <FormControlLabel
                      value="Bottom"
                      control={<Radio />}
                      label="Bottom"
                    />
                    <FormControlLabel
                      value="Side"
                      control={<Radio />}
                      label=" Side"
                    />
                  </span>
                </RadioGroup>
              </FormControl>
            </div>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom: "100px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Font Size" variant="standard" />
              )}
            />

            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/searchLogo.svg" alt="Imagee" />
                    <Typography variant="body1" component="span">
                      Enable Flexibl Search
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.AutoArchivePaid}
                    onChange={handleChangebutton}
                    name="AutoArchivePaid"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Match non-contiguous characters, ie. ct matches cat
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/Enable.svg" alt="Images" />
                    <Typography variant="body1" component="span">
                      Enable Touch Events
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.AutoArchiveCancelled}
                    onChange={handleChangebutton}
                    name="AutoArchiveCancelled"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Suport drag events to scroll
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/MM.svg" alt="Imagee" />
                    <Typography variant="body1" component="span">
                      Enable Tooltips
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.jason}
                    onChange={handleChangebutton}
                    name="jason"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Show tooltips when hovering the mouse
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      columnGap: "15px",
                    }}
                  >
                    <img src="/assets/images/Dd.svg" alt="Images" />
                    <Typography variant="body1" component="span">
                      Persist Data
                    </Typography>
                  </div>
                }
                control={
                  <Switch
                    checked={state.antoine}
                    onChange={handleChangebutton}
                    name="antoine"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "32px",
                  margin: "-5px",
                }}
              >
                Save data locally to enable the app to start faster,disabling
                may improve performance in large accounts
              </FormHelperText>
            </FormControl>
          </Box>
        </div>
      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={1}>
        Item Two
      </TabPanel>
</div>
   
    </Box>
  );
}
