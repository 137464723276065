import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Footer from "./footer";
import CreateInvoice from "./createInvoice";
import {useState} from 'react';
import {InputBase } from '@mui/material';
import { FaArrowRight as ArrowRightIcon } from 'react-icons/fa';
import { FaArrowLeft as ArrowLeftIcon } from 'react-icons/fa';
import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';
import { MdAccountCircle as AccountCircleIcon } from 'react-icons/md';
import SettingsIcon from '@mui/icons-material/Settings';
import MenuItemX from './MenuItem';
import {Outlet} from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

// fontWsome
import '@fortawesome/fontawesome-free/css/all.css';




const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);




export default function Header() {
 
//  for mobile
const [state, setState] = React.useState({
  left: false,
});

const toggleDrawer = (anchor, open) => (event) => {
  if (
    event &&
    event.type === "keydown" &&
    (event.key === "Tab" || event.key === "Shift")
  ) {
    return;
  }

  setState({ ...state, [anchor]: open });
};

const list = (anchor) => (
  <Box
    sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
    role="presentation"
    onClick={toggleDrawer(anchor, false)}
    onKeyDown={toggleDrawer(anchor, false)}
  >
  
 
    <List style={{marginTop:'80px'}}>
      {icons.map((text, index) => (
        <ListItem key={index} >
          <ListItemButton>
            <ListItemIcon>
            {
              <img src={text.icon} alt={text.text} />
            }
            </ListItemIcon>
            <ListItemText primary={text.text} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
);



// end
  const buttonStyle = {
    backgroundColor: '#00A1E4',
    color: '#fff',
    padding: '07px 22px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize:'14px',
    fontWeight:'400'
  };
  const divStyle={
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: '30px',
    paddingRight: '20px',
    borderRight: '1px solid #ffffff42',
    height: '60px'
  }
  const userInfoDiv={
    display:'flex',
    justifyContent: 'center',
    alignItems: 'center',
   
    height: '60px'
  }
  const userImage = '/assets/images/photo-1633332755192-727a05c4013d.jpg';
  const userInfo={
    Name:"Sabtain",
    userName:"sabtain22@gmail.com"
  }

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
 
  const [isLeftDivOpen, setIsLeftDivOpen] = useState(true);
  // const [isIconText,setIconText]=useState(false)
  const handleDrawerOpen = () => {
    setIsLeftDivOpen(!isLeftDivOpen);
    // setIconText(true)
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsLeftDivOpen(!isLeftDivOpen);
  };

  const icons = [
    {
      text: 'Home',
      icon:'/assets/images/home.svg',
      color:'#ffff',
      showMenu: false,
      subMenu: [
     
      ]
     
    },

    {
      showMenu: true,
      text: 'Dashboard',
      icon: '/assets/images/rr.svg',
      color:'#ffff',
      menuItemIcon: '/assets/images/dash.svg',
      menuItemTitle: "Dashboard",
      subMenu: [
      
        {
          icon:'/assets/images/one.svg',
          name: "Over view",
          url: "#"
        },
        {
          icon:'/assets/images/two.svg',
          name: "Analytics",
          url: "#"
        },
        {
          icon:'/assets/images/three.svg',
          name: "Contacts",
          url: "#"
        },
        {
          icon:'/assets/images/four.svg',
          name: "Projects",
          url: "#"
        },
       
      ]
    },


     {
      showMenu: true,
      text: 'Application',
      icon: '/assets/images/r.svg',
      color:'#ffff',
      menuItemIcon: '/assets/images/settingCopy.svg',
      menuItemTitle: "Application",
      subMenu: [
        {
          icon:'/assets/images/five.svg',
          name: "Leads ",
          url: "#"
        }, 
        {
          icon:'/assets/images/six.svg',
          name: "Company ",
          url: "#"
        }, 
        {
          icon:'/assets/images/seven.svg',
          name: "Clients ",
          url: "#"
        },
          {
          icon:'/assets/images/eight.svg',
          name: "Calendar ",
          url: "#"
        },
      ]
    },

  
    {
      showMenu: true,
      text: 'Reports',
      icon: '/assets/images/g.svg',
      color:'#ffff',
      menuItemIcon: '/assets/images/repor.svg',
      menuItemTitle: "Reports",
      subMenu: [
        {
          icon:'/assets/images/nine.svg',
          name: "Income ",
          url: "#"
        }, 
        {
          icon:'/assets/images/ten.svg',
          name: "Expense ",
          url: "#"
        }, 
        {
          icon:'/assets/images/eleven.svg',
          name: "Project ",
          url: "#"
        },
          {
          icon:'/assets/images/twelve.svg',
          name: "Profit ",
          url: "#"
        },
           {
          icon:'/assets/images/thirteen.svg',
          name: "Calculator ",
          url: "#"
        },  
         {
          icon:'/assets/images/fourteen.svg',
          name: "Tax ",
          url: "#"
        },
      ]
    },

    {
      showMenu: true,
      text: 'Customer care',
      icon: '/assets/images/Vector.svg',
      color:'#D8D0D0',
      menuItemIcon: '/assets/images/hphone.svg',
      menuItemTitle: "Customer care",
      subMenu: [
        {
          icon:'/assets/images/fifteen.svg',
          name: "Support",
          url: "#"
        }, 
        {
          icon:'/assets/images/sixteen.svg',
          name: "FAQ",
          url: "#"
        }, 
        {
          icon:'/assets/images/seventeen.svg',
          name: "Documentation",
          url: "#"
        },
      
      ]

    },
     {
      showMenu: true,
      text: 'Settings',
      icon: '/assets/images/m.svg',
      color:'#ffff',
      menuItemIcon: '/assets/images/Settings.svg',
      menuItemTitle: "Settings",
      route:'Settings',


      subMenu: [
        {
          icon:'/assets/images/eighteen.svg',
          name: "Front end settings",
          url: "#"
        }, 
        {
          icon:'/assets/images/ninteen.svg',
          name: "Account ",
          url: "#"
        }, 
        {
          icon:'/assets/images/twenty.svg',
          name: "Profile",
          url: "#"
        },
      
      ]
    }
  ];



 
  return (
    <>

<Box className={'webView'} sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} >
        <Toolbar className='header' style={{paddingLeft:'0px',backgroundColor:"#2C2C2C", display:'grid',gridTemplateColumns: "5% 95%" }}>
        
        {isLeftDivOpen?
        <IconButton  className='right'  edge="start"  aria-label="open drawer"  onClick={handleDrawerOpen} style={{padding:" 0px 20px" ,fontSize:"30px"}} color="inherit" >
               <ArrowRightIcon  />
         </IconButton>:
         <IconButton className='left'  edge="start"  aria-label="open drawer"  onClick={handleDrawerClose} style={{padding:" 0px 20px" ,fontSize:"30px"}} color="inherit" >
          <ArrowLeftIcon   />
    </IconButton>
    
      
         } 
       
 
         
        
        <div style={{display: 'flex',justifyContent: 'space-between',alignItems: 'center'}}>
        <Typography variant="h6">
          <div style={{paddingLeft:'60px',backgroundColor:"#1F1F1F", display: 'flex', alignItems: 'center',justifyContent:'center',height: '60px' }}>
            <SearchIcon style={{ margin: ' 0px 10px',alignItems:"center",fontSize:'30px'}} />
            <InputBase style={{ height: '60px',color:'white' }} placeholder="Search" className={"searh-input"}/>
          </div>
        </Typography>
         <div style={{  display:'flex',justifyContent: 'center', alignItems: 'center', height: '60px'}}>
           <div style={divStyle}>
              <SettingsIcon />
              <img src='/assets/images/Vector.png'></img>
              <img src='/assets/images/m.png'></img>
              
              <button style={buttonStyle}>
              Logout
            </button>
        
            </div>


       <div style={userInfoDiv}>
         {userImage? <img style={{width: '32px',height: '32px',borderRadius:'100%' ,margin:'0px 10px 0px 20px'}} src={userImage} alt="User" />:  <IconButton color="inherit" aria-label="sign-in"> <AccountCircleIcon />   </IconButton> } 
       <div style={{display: 'flex',flexDirection: 'column',gap: '0px'
}}>
          <p style={{fontWeight:'400',fontSize:'16px',padding:'0px',margin: '0px',fontFamily: 'Trebuchet MS'}}>{userInfo.Name}</p>
          <span style={{fontWeight:'400',fontSize:'14px',color: 'rgba(255, 255, 255, 0.61)'}}>{userInfo.userName ? userInfo.userName: 'info@gmail.com'}</span>
       </div>

       </div>
        </div>
       
    </div>
        </Toolbar>
      </AppBar>
      {/* sx={{height: "auto!important",overflowY:'none!important'}} */}
      <Drawer variant="permanent" open={open} sx={{ display: 'flex',}} >
        <DrawerHeader>
          <IconButton >
            
          </IconButton>
        </DrawerHeader>
        <Divider  />
        <List 
          sx={{
            overflowY:"scroll",
            overflowX:"hidden",
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '0.4em',
              display: "none"
            },
            '&::-webkit-scrollbar-track': {
              background: "#f1f1f1",
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#555'
            }
          }}
        >
          {icons.map((text, index) => (
            <MenuItemX  key={index}   open={open} text={text} route menuItemIcon menuItemTitle showMenu={text.showMenu}/>
          
          ))}
        </List>
        <Divider />
     
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, }}>
        <DrawerHeader />
        <Typography paragraph>
          <Outlet/>
     
   
    <Footer/>
        </Typography>
       
      </Box>
    </Box>



    {/* mobile */}
    <div className={'mobilVied'}style={{ margin: "0px!important" }}>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              backgroundColor: "#1975D2",
              padding: "20px 0px 50px 15px",
              columnGap: "20px",
              position: 'fixed',
              top:'0px',
              left: 'auto',
              right: '0px',
              width:'100%',
              zIndex: '1202'
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(anchor, true)}
              edge="start"
            >
              <MenuIcon style={{ fontSize: "45px", color: "white" }} />
            </IconButton>

            <div
              style={{
                backgroundColor: "white",
                paddingLeft: "17px",
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                height: "35px",
                borderRadius: "10px",
                color: "black",
              }}
            >
              <InputBase
                style={{ height: "40px", color: "black" }}
                placeholder="Search here"
                className={"search-input"}
                inputProps={{
                  style: { color: "black", width: "140px" }, // Replace with your desired color
                }}
              />
              <SearchIcon
                style={{
                  margin: "0px 10px",
                  alignItems: "center",
                  fontSize: "25px",
                }}
              />
            </div>
            <div style={{ textAlign: "center", margin: "auto" }}>
              <img
                style={{ width: "27px", height: "27px" }}
                src="/assets/images/undow.png"
              ></img>
            </div>
          </div>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>

          <Outlet/>
             
          <Footer/>
        </React.Fragment>
      ))}
    </div>
    </>
 

  );
}