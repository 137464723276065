import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import ClearIcon from "@mui/icons-material/Clear";

import {
  Box,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AccountManagementTab({ handleDrawerClose ,selectedTab}) {


  const [valueText, setValueText] = useState({
    fildOne: "",
    fildTwo: "",
    fildThree: "",
    fildFour: "",
    fildFive: "",
    fildSiX: "",
  });

  const handleClear = () => {
    const temp = { ...valueText };
    temp.fildOne = "";
    setValueText(temp);
  }; 
  const handleClearTwo = () => {
    const temp = { ...valueText };
    temp.fildTwo = "";
    setValueText(temp);
  };
   const handleClearThree = () => {
    const temp = { ...valueText };
    temp.fildThree = "";
    setValueText(temp);
  };
   const handleClearFour = () => {
    const temp = { ...valueText };
    temp.fildFour = "";
    setValueText(temp);
  }; 
  const handleClearFIve = () => {
    const temp = { ...valueText };
    temp.fildFive = "";
    setValueText(temp);
  }; 
  const handleClearSix = () => {
    const temp = { ...valueText };
    temp.fildSiX = "";
    setValueText(temp);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

// upload image
const [open, setOpen] = React.useState(false);
const [selectedImage, setSelectedImage] = React.useState(null);

const handleUploadClick = () => {
  setOpen(true);
};

const handleImageChange = (event) => {
  const file = event.target.files[0];
  setSelectedImage(URL.createObjectURL(file));
  setOpen(false);
};

const DeletUploadImage=()=>{
    setSelectedImage(null)
}

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "grid",
          gridTemplateColumns: "70% 30%",
          columnGap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Logo")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Details")}
            {...a11yProps(1)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 2 ? "#00A1E4" : "white",
              color: value === 2 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Address")}
            {...a11yProps(2)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 3 ? "#00A1E4" : "white",
              color: value === 3 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Defaults")}
            {...a11yProps(3)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 4 ? "#00A1E4" : "white",
              color: value === 4 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Documents")}
            {...a11yProps(4)}
          />
         
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

      
       {/* for mobile */}

       <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs

          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Logo")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Details")}
            {...a11yProps(1)}
          />

<Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 2 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Address")}
            {...a11yProps(2)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 3 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Defaults")}
            {...a11yProps(3)}
          />
           <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 4 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Documents")}
            {...a11yProps(4)}
          /> 
         
         
          



        </Tabs>

      
      </Box>

      <div className={"Othertab boxTwo mobilVied"}>
      <TabPanel
        style={{}}
      
        value={value}
        index={0}
      >
      
      <div
          style={{
            overflow: "hidden",
            margin: "260px 10px 25px 15px",

            padding: "15px 10px",
            marginBottom: "100px",
            display: "block",
            
          }}
        >
          <div style={{ width: "100%",alignItems:'center',display:"flex",justifyContent:"center" }}>
          
            <button   onClick={DeletUploadImage}
              style={{
                margin: "24px 0px",
               
                height: "30px",
                border:"1px solid rgba(0, 0, 0, 0.18)",
                color: "black",
                borderRadius:"6px",
                width: "230px",
                backgroundColor:"white",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/tras.png" alt=""></img>DELETE
              </span>
            </button>
          </div>
          <div style={{ width: "100%",alignItems:'center',display:"flex",justifyContent:"center"  }}>
            

            <Button variant="contained" component="label"  onClick={handleUploadClick}
              style={{
                margin: "24px 0px",
                border: "none",
                height: "30px",
                backgroundColor:"rgba(66, 146, 3, 0.84)",
                color: "white",
                borderRadius:"6px",
                width: "230px",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
                 
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <input type="file" hidden onChange={handleImageChange} />
                <img src="/assets/images/fff.png" alt=""></img>UPLOAD LOGO
              </span>
            </Button>
          </div>
        </div>
        {selectedImage &&
        <div style={{textAlign:'center'}}>
         <img style={{width:'200px',height:'300px'}} src={selectedImage} alt="Selected" />
        </div> }
       
      </TabPanel>
      <TabPanel   value={value} index={1}>


      <div
       
        style={{
            overflow: "hidden",
            margin: "250px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "400px",
          }}
        >
       <TextField style={{width:'100%', margin:" 10px 0px 20px 0px" }}
            label="Street"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

<TextField style={{width:'100%',margin:" 2px 0px 20px 0px"}}
            label="Apt/Suite"
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTwo}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />


<TextField  style={{width:'100%',margin:" 2px 0px 20px 0px"}}
            label="City"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearThree}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          


          <TextField   sx={{ width: "100%", margin:" 2px 0px 27px 0px" }}
            label="State/Province"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFour}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />


<TextField   sx={{ width: "100%", margin:" 2px 0px 27px 0px" }}
            label="Postal Code"
            variant="standard"
            value={valueText.fildFive}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFive = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFIve}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />



<TextField   sx={{ width: "100%", margin:" 2px 0px 27px 0px" }}
            label="Country"
            variant="standard"
            value={valueText.fildSiX}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSiX = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSix}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
    
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={4}>
        Item Four
      </TabPanel>
      </div>
      <div className={"Othertab boxTwo webview"}>
      <TabPanel
        style={{}}
      
        value={value}
        index={0}
      >
      
      <div
          style={{
            overflow: "hidden",
            margin: "0px 10px 25px 15px",

            padding: "15px 10px",
            marginBottom: "100px",
            display: "grid",
            gridTemplateColumns: "45% 45%",
            columnGap: "10%",
          }}
        >
          <div style={{ width: "100%",alignItems:'center',display:"flex",justifyContent:"center" }}>
          
            <button   onClick={DeletUploadImage}
              style={{
                margin: "24px 0px",
               
                height: "30px",
                border:"1px solid rgba(0, 0, 0, 0.18)",
                color: "black",
                borderRadius:"6px",
                width: "230px",
                backgroundColor:"white",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/tras.png" alt=""></img>DELETE
              </span>
            </button>
          </div>
          <div style={{ width: "100%",alignItems:'center',display:"flex",justifyContent:"center"  }}>
            

            <Button variant="contained" component="label"  onClick={handleUploadClick}
              style={{
                margin: "24px 0px",
                border: "none",
                height: "30px",
                backgroundColor:"rgba(66, 146, 3, 0.84)",
                color: "white",
                borderRadius:"6px",
                width: "230px",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
                 
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <input type="file" hidden onChange={handleImageChange} />
                <img src="/assets/images/fff.png" alt=""></img>UPLOAD LOGO
              </span>
            </Button>
          </div>
        </div>
        {selectedImage &&
        <div style={{textAlign:'center'}}>
         <img style={{width:'200px',height:'300px'}} src={selectedImage} alt="Selected" />
        </div> }
       
      </TabPanel>
      <TabPanel   value={value} index={1}>


      <div
       
        style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "400px",
          }}
        >
       <TextField style={{width:'100%', margin:" 10px 0px 20px 0px" }}
            label="Street"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

<TextField style={{width:'100%',margin:" 2px 0px 20px 0px"}}
            label="Apt/Suite"
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTwo}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />


<TextField  style={{width:'100%',margin:" 2px 0px 20px 0px"}}
            label="City"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearThree}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          


          <TextField   sx={{ width: "100%", margin:" 2px 0px 27px 0px" }}
            label="State/Province"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFour}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />


<TextField   sx={{ width: "100%", margin:" 2px 0px 27px 0px" }}
            label="Postal Code"
            variant="standard"
            value={valueText.fildFive}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFive = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFIve}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />



<TextField   sx={{ width: "100%", margin:" 2px 0px 27px 0px" }}
            label="Country"
            variant="standard"
            value={valueText.fildSiX}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSiX = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSix}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </div>
    
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={4}>
        Item Four
      </TabPanel>
      </div>
      
    </Box>
  );
}
