import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { MdArrowUpward } from "react-icons/md";
import { Bar } from "react-chartjs-2";
import { MdArrowDownward } from "react-icons/md";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";

// Investment overview
function TabPanelTwo(props) {
  // const { children, value, index, ...other } = props;
  const { children, valueTwo, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueTwo !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {valueTwo === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelTwo.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueTwo: PropTypes.number.isRequired,
};

function a11yPropsTwo(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// end investment overview

// chart
const labels = ["", "", "", "", ""];
export const data = {
  labels,
  datasets: [
    {
      label: "none",

      data: [7, 10, 5, 12, 10],
      backgroundColor: [
        "#E0E4FF",
        "#E0E4FF",
        "#E0E4FF",
        "#E0E4FF",
        "#00A1E4",
        "#E0E4FF",
        "#00A1E4",
      ],
      borderWidth: 0,
    },
  ],
};
const options2 = {
  LinearScale: false,
  responsive: true,
  plugins: {
    legend: {
      position: "none",
    },
  },

  scales: {
    y: {
      beginAtZero: true,
      label: false,
      grid: {
        borderColor: "rgba(0, 0, 0, 0)!important", // Set border color to transparent
        drawBorder: false, // Remove the vertical line on the right side
        display: false, // Remove the vertical grid lines
      },
      ticks: {
        display: false, // Hide the left side values
      },
    },
    x: {
      grid: {
        display: false, // Remove the horizontal grid lines
      },
      ticks: {
        display: false, // Hide the left side values
      },
    },
  },
};

// chartEnd

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// textFormate
const formatLabel = (label) => {
  const formattedLabel =
    label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  return formattedLabel;
};

const totalDeposit = [
  {
    diposit: "49,398.397",
    withdraw: "",
    balance: "",
    parcent: "3.9",
    month: "321,2.2",
    week: "321,2.2",
  },
  {
    diposit: "",
    withdraw: "59,398.397",
    balance: "",
    parcent: "3.9",
    month: "321,2.2",
    week: "321,2.2",
  },
  {
    diposit: "",
    withdraw: "",
    balance: "69,398.397",
    parcent: "3.9",
    month: "321,2.2",
    week: "321,2.2",
  },
];

// const CheckBoxData=[
const drewerData = [
  {
    title: "Tasks",

    name: "Komende",
    OptionData: [
      { label: "option 1" },
      { label: "option 2" },
      { label: "option3" },
      { label: "option4" },
    ],

    CheckBoxData: [
      {
        lableData: "Te Starten Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak buiten de tijd (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Te starten taak (test data)",
        date: "22march 2023",
      },
    ],
  },

  {
    title: "Meeting Today",

    name: "Komende 7 dagen",
    OptionData: [
      { label: "option 1" },
      { label: "option 2" },
      { label: "option3" },
      { label: "option4" },
    ],

    CheckBoxData: [
      {
        lableData: "Te Starten Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak buiten de tijd (test data)",
        date: "22march 2023",
      },
    ],
  },

  {
    title: "Calls today",

    name: "Komende 7 dagen",
    OptionData: [
      { label: "option 1" },
      { label: "option 2" },
      { label: "option3" },
      { label: "option4" },
    ],

    CheckBoxData: [
      {
        lableData: "Te Starten Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak buiten de tijd (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Te starten taak (test data)",
        date: "22march 2023",
      },
    ],
  },

  {
    title: "Tasks",

    name: "Komende ",
    OptionData: [
      { label: "option 1" },
      { label: "option 2" },
      { label: "option3" },
      { label: "option4" },
    ],

    CheckBoxData: [
      {
        lableData: "Te Starten Taak (test data)",
        date: "22march 2023",
      },
      {
        lableData: "Taak (test data)",
        date: "22march 2023",
      },
    ],
  },
];

const DateSelect = [
  { date: "Feb 18 - Feb 22" },
  { date: "Feb 20 - Feb 24" },
  { date: "Feb 22 - Feb 26" },
  { date: "Feb 24 - Feb 28" },
  { date: "Feb 25 - Feb 28" },
];

export default function DeshBoardMobile() {
  const [value, setValue] = React.useState(0);
  const [showDivs, setShowDivs] = useState(false);
  const className = showDivs ? "blockStyle" : "flexStyle";
  // const chartBar = showDivs ? "blockStyle" : "flexStyle";
  const className2 = showDivs ? "blockS" : "blockSReverse";
  const className3 = showDivs ? "blockSs" : "blockSReversee";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const user = [
    {
      name: "Keith Jensen requested to Widthdrawl.",

      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Harry Simpson placed a Order.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Stephanie Marshall got a huge bonus.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Timothy Moreno placed a Order.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
  ];

  const values = [
    { name: "Silver Plan", val: 50.4, color: "#1a90ff" },
    { name: "Silver Plan", val: 40.4, color: "#FFA353" },

    { name: "Diamond Plan", val: 60.4, color: "#20C997" },
    {
      name: "Platinium Plan",
      val: 60.4,
      color: "#FF63A5",
    },
    {
      name: "Vibranium Plan",
      val: 60.4,
      color: "#00A1E4",
    },
  ];

  // investMent Overview
  const [valueTwo, setValueTwo] = React.useState(0);

  const handleChangeTwo = (event, newValue) => {
    setValueTwo(newValue);
  };

  // end envestment overview

  const defaultPropsTwo = {
    options: DateSelect,
    getOptionLabel: (option) => option.date,
  };

  return (
    <Box sx={{ width: "100%", paddingTop: "74px" }}>
      <Box
        style={{
          backgroundColor: "#1975D2",
          zIndex: "1203",
          height: "83",
          paddingTop: "38px",
          position: "fixed",
          // top: "13%",
          left: "auto",
          right: "0px",
          width: "100%",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: {
              backgroundColor: "white",
              borderBottom: "2px solid white",
            }, // Change the border color here
          }}
        >
          <Tab
            style={{
              color: value === 0 ? "white" : "white",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "16px",
            }}
            label={formatLabel("Transaction")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              color: value === 1 ? "white" : "white",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "16px",
            }}
            label={formatLabel("Investment")}
            {...a11yProps(1)}
          />
          <Tab
            style={{
              color: value === 2 ? "white" : "white",
              textTransform: "none",
              fontWeight: "400",
              fontSize: "16px",
            }}
            label={formatLabel("Activities")}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <div
        style={{ backgroundColor: "white", height: "80px", marginTop: "50px" }}
      >
        <div
          style={{
            padding: "42px 15px 0px 15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                columnGap: "20px",
              }}
            >
              <img
                style={{ height: "10px" }}
                src="/assets/images/l.svg"
                alt=""
              ></img>
              <img
                style={{ height: "10px" }}
                src="/assets/images/rs.svg"
                alt=""
              ></img>
            </div>
            <Autocomplete
              {...defaultPropsTwo}
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    sx: { width: "163px", fontSize: "14px" }, // Adjust the width value as per your needs
                  }}
                  // label="Select Date"
                />
              )}
            />
          </div>
          <img
            style={{ cursor: "pointer" }}
            src="/assets/images/filter.svg"
          ></img>
        </div>
      </div>
      <TabPanel value={value} index={0}>
        <div
          style={{
            display: "block",
            backgroundColor: " #F5F5F5",
            columnGap: "10px",
            // marginTop:"86px",
            paddingBottom: "73px",
            // maxHeight: "100vh",
          }}
          className={"scroolDemMobile tab"}
        >
          <div style={{ margin: "10px 20px" }}>
            {totalDeposit.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: "30px 10px 0px 15px ",
                  backgroundColor: "#FFFFFF",
                  border: "2px solid rgba(0, 0, 0, 0.15)",
                  borderRadius: "2px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                {item.diposit !== "" ? (
                  <>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#000000",
                        margin: "0px",
                      }}
                    >
                      Total deposit
                    </p>
                    <h2
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        margin: "0px",
                        paddingTop: "10px",
                      }}
                    >
                      {item.diposit} USD
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                          color: "#30DDBE",
                        }}
                      >
                        <MdArrowUpward />
                        {item.parcent} <span sx={{ paddingTop: "3px" }}>%</span>
                      </span>
                    </h2>
                  </>
                ) : item.withdraw !== "" ? (
                  <>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#000000",
                        margin: "0px",
                      }}
                    >
                      Total withdraw
                    </p>
                    <h2
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        margin: "0px",
                        paddingTop: "10px",
                      }}
                    >
                      {item.withdraw} USD
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                          color: "#30DDBE",
                        }}
                      >
                        <MdArrowUpward />
                        {item.parcent} <span sx={{ paddingTop: "3px" }}>%</span>
                      </span>
                    </h2>
                  </>
                ) : item.balance !== "" ? (
                  <>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        color: "#000000",
                        margin: "0px",
                      }}
                    >
                      Balance in Acount
                    </p>
                    <h2
                      style={{
                        fontWeight: "600",
                        fontSize: "20px",
                        margin: "0px",
                        paddingTop: "10px",
                      }}
                    >
                      {item.balance} USD
                      <span
                        style={{
                          fontWeight: "600",
                          fontSize: "15px",
                          color: "#30DDBE",
                        }}
                      >
                        <MdArrowUpward />
                        {item.parcent} <span sx={{ paddingTop: "3px" }}>%</span>
                      </span>
                    </h2>
                  </>
                ) : (
                  ""
                )}
                <div className={"mobChart"}>
                  <div className={className}>
                    <div className={className2}>
                      <p
                        className={className3}
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          color: "#000000",
                          margin: "0px",
                          paddingBottom: "5px",
                        }}
                      >
                        This Month
                      </p>
                      <h2
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          margin: "0px",
                        }}
                      >
                        {item.month} USD
                      </h2>
                    </div>

                    <div>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "13px",
                          color: "#000000",
                          margin: "0px",
                          paddingBottom: "5px",
                        }}
                      >
                        This week
                      </p>
                      <h2
                        style={{
                          fontWeight: "600",
                          fontSize: "14px",
                          margin: "0px",
                        }}
                      >
                        {item.week} USD
                      </h2>
                    </div>
                  </div>

                  <div>
                    <Box className={"chart"}>
                      <Bar
                        data={data}
                        options={options2}
                        width={100}
                        maxHeight={100}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div
          style={{
            display: "block",
            backgroundColor: " #F5F5F5",
            columnGap: "10px",
            // paddingTop: "20px",
            // marginTop:"66px",
            marginBottom: "70px",
          }}
          className={"scroolDemMobile tab"}
        >
          <div style={{ margin: "10px 20px" }}>
            <div
              style={{
                border: "2px solid rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                padding: "10px 20px",
                marginBottom: "10px",
                backgroundColor: "white",
              }}
            >
              <div>
                <p
                  style={{
                    paddingLeft: "17px",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                >
                  Investment overview
                </p>
                <p
                  style={{
                    paddingLeft: "17px",
                    fontWeight: "400",
                    fontSize: "15px",
                  }}
                >
                  The investment overview of your platform.
                  <span style={{ color: "#1975D2" }}>All investment</span>
                </p>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      valueTwo={valueTwo}
                      onChange={handleChangeTwo}
                      aria-label="basic tabs example"
                      style={{ padding: "0px 10px" }}
                    >
                      <Tab
                        style={{
                          fontWeight: "700",
                          fontSize: "16px",
                          padding: "10px 0px",
                          textTransform: "none",
                          borderBottom:
                            valueTwo === 0
                              ? "2px solid #00A1E4"
                              : "2px solid white",
                        }}
                        label={formatLabel("Overview")}
                        {...a11yPropsTwo(0)}
                      />
                      <Tab
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          textTransform: "none",
                          borderBottom:
                            valueTwo === 1
                              ? "2px solid #00A1E4"
                              : "2px solid white",
                        }}
                        label={formatLabel("This year")}
                        {...a11yPropsTwo(1)}
                      />
                      <Tab
                        style={{
                          fontWeight: "600",
                          fontSize: "16px",
                          textTransform: "none",
                          borderBottom:
                            valueTwo === 2
                              ? "2px solid #00A1E4"
                              : "2px solid white",
                        }}
                        label={formatLabel("All time")}
                        {...a11yPropsTwo(2)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanelTwo valueTwo={valueTwo} index={0}>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      Currently Activated Investment
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "59% 39%",
                        columnGap: "8%",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingBottom: "5px",
                          }}
                        >
                          49,398.397 USD
                        </h2>
                        <span> Amount</span>
                      </div>
                      <div>
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            margin: "0px",
                          }}
                        >
                          56{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward sx={{ fontSize: "15px" }} /> 3.9 %
                          </span>
                        </p>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            margin: "0px",
                          }}
                        >
                          Plans
                        </p>
                      </div>
                    </div>

                    <div style={{ padding: "20px 0px" }}>
                      <h2
                        style={{
                          fontWeight: "600",
                          fontSize: "20px",
                          margin: "0px",
                          paddingBottom: "5px",
                        }}
                      >
                        49,398.397 USD
                      </h2>
                      <span>Paid Profit</span>
                    </div>
                    {/* try */}
                    <hr style={{ color: "2px solid rgba(0, 0, 0, 0.15)" }} />

                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "15px",
                        marginTop: "20px",
                      }}
                    >
                      Investment in this monts
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "59% 39%",
                        columnGap: "8%",
                      }}
                    >
                      <div>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingBottom: "5px",
                          }}
                        >
                          49,398.397 USD
                        </h2>
                        <span> Amount</span>
                      </div>
                      <div style={{ paddingTop: "10px" }}>
                        <p
                          style={{
                            fontSize: "15px",
                            fontWeight: "400",
                            margin: "0px",
                          }}
                        >
                          56{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "600",
                              color: "#F50000",
                            }}
                          >
                            <MdArrowDownward sx={{ fontSize: "15px" }} /> 3.9 %
                          </span>
                        </p>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            margin: "0px",
                          }}
                        >
                          Plans
                        </p>
                      </div>
                    </div>
                  </TabPanelTwo>
                  <TabPanelTwo valueTwo={valueTwo} index={1}>
                    Item Two
                  </TabPanelTwo>
                  <TabPanelTwo valueTwo={valueTwo} index={2}>
                    Item Three
                  </TabPanelTwo>
                </Box>
              </div>
            </div>
            <div
              style={{
                border: "2px solid rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                backgroundColor: "white",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 20px",
                }}
              >
                <div>
                  <h2 style={{ fontWeight: "600", fontSize: "15px" }}>
                    Top Invested Plan
                  </h2>
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "15px",
                      margin: "0px",
                    }}
                  >
                    In the last 30 days top invested plan
                  </p>
                </div>

                <MoreVertIcon />
              </div>
              <>
                {values.map((item, i) => (
                  <Box key={i} sx={{ margin: "20px" }}>
                    <Typography
                      variant="subtitle1"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        margin: "0px 20px 10px 5px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "#000000",
                        }}
                      >
                        {item.name}
                      </div>
                      <div
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          color: "rgba(0, 0, 0, 0.44)",
                        }}
                      >
                        {" "}
                        {item.val}%
                      </div>
                    </Typography>
                    <LinearProgress
                      sx={{
                        backgroundColor: "#E0E4FF",
                        "& .MuiLinearProgress-bar": {
                          backgroundColor: item.color,
                        },
                        height: 5,
                      }}
                      value={item.val}
                      variant="determinate"
                    />
                  </Box>
                ))}
              </>
            </div>
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.15)",
                borderRadius: "2px",
                backgroundColor: "white",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "12px 0px 0px 20px",
                }}
              >
                <h2 style={{ fontWeight: "600", fontSize: "15px" }}>
                  Recent Activities{" "}
                </h2>
              </div>

              <div className={"scroolDem"} style={{ height: "500px" }}>
                <>
                  {user.map((item, i) => (
                    <div
                      key={i}
                      style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          columnGap: "20px",
                          margin: "14px",
                        }}
                      >
                        {item.image ? (
                          <img
                            style={{
                              width: "32px",
                              height: "32px",
                              borderRadius: "100%",
                              objectFit: "cover",
                            }}
                            src={item.image}
                            alt=""
                          ></img>
                        ) : (
                          <img src="https://static.vecteezy.com/system/resources/previews/008/442/086/original/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg" />
                        )}

                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              margin: "0px",
                            }}
                          >
                            {item.name}
                          </p>
                          <span
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "rgba(0, 0, 0, 0.51)",
                              margin: "0px",
                            }}
                          >
                            {item.activeStatus}{" "}
                          </span>
                        </div>
                      </div>
                      {/* <hr style={{ color: "rgba(0, 0, 0, 0.13)" }} /> */}
                    </div>
                  ))}
                </>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box
          style={{ backgroundColor: "#F5F5F5", marginBottom: "63px" }}
          className={"scroolDemMobile tab"}
        >
          {drewerData.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: "white",
                margin: "20px",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
              }}
            >
              <div
                style={{
                  background: "#F5F5F5",
                  boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                  height: "48px",
                  // margin: "10px 20px 20px 20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0px 10px 0px 15px",
                }}
              >
                <p style={{ fontWeight: "600", fontSize: "14px" }}>
                  {item.title}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    columnGap: "20px",
                  }}
                >
                  <img src="/assets/images/plssBg.svg" />
                  <img src="/assets/images/menu2.svg" />
                </div>
              </div>

              <div key={index} style={{ margin: "5px 23px 20px 23px" }}>
                <Autocomplete
                  id="combo-box-demo"
                  options={item.OptionData}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      label={item.name}
                    />
                  )}
                />
              </div>

              <FormGroup style={{ padding: "5px 0px 0px 0px" }}>
                {item.CheckBoxData.map((data, index) => (
                  <div
                    key={index}
                    style={{
                      paddingLeft: "20px",

                      borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                    }}
                  >
                    <FormControlLabel
                      control={<Checkbox Checkbox />}
                      label={data.lableData}
                    />
                    {/* <span
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        paddingTop: "22px",
                      }}
                    >
                      {" "}
                      {data.date}
                    </span> */}
                  </div>
                ))}
              </FormGroup>
            </div>
          ))}

          {}
        </Box>
      </TabPanel>
    </Box>
  );
}
