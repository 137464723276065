

export const InvoiceInfo=[
    {   id: '04',
        Name:'John Doe',
        Date:' 1/June/2023',
        Amount: '10.00',
        Status: 'Pending'
    },
    {
       id: '03',
       Name: 'Jane Smith',
       Date: ' 28/May/2023',
       Amount:'15.00',
       Status:'Completed'
    },
    {
        id: '02',
        Name:'David Johnson',
        Date: '3/June/2023',
        Amount: '20.00',
        Status: 'Pending'
    },
    {
        id: '01',
        Name: 'Emily Brown',
        Date: '2/June/2023',
        Amount: '10.00',
        Status: 'Completed'
    },
    {
        id: '07',
        Name: 'Michael Davis',
        Date: '30/May/2023',
        Amount: '15.00',
        Status: 'Pending'

    },
    {
        id: '06',
        Name: 'Sarah Wilson',
        Date: '29/May/2023',
        Amount: '15.00',
        Status: 'Completed'
    },
    {
        id: '05',
        Name: 'Robert Anderson',
        Date: '4/June/2023',
        Amount: '10.00',
        Status: 'Pending'
    },
    {
        id: '08',
        Name: 'Olivia Martin',
        Date: '1/June/2023',
        Amount: '20.00',
        Status: 'Completed'
    },
    {
        id: '09',
        Name: 'William Taylor',
        Date: '3/February/2023',
        Amount: '20.00',
        Status: 'Pending'
    },
  

]
export default InvoiceInfo;


