import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button,IconButton, TextField,InputAdornment } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

const InvoiceDesign = [
  { label: "Clean" },
  { label: "option1" },
  { label: "option2" },
  { label: "Irregular" },
];
const QuoteDesign  = [
  { label: "Clean" },
  { label: "option1" },
  { label: "option2" },
  { label: "Irregular" },
];
const CreditDesign  = [
  { label: "Clean" },
  { label: "option1" },
  { label: "option2" },
  { label: "Irregular" },
];
const PurchaseOrderDesign  = [
  { label: "Clean" },
  { label: "option1" },
  { label: "option2" },
  { label: "Irregular" },
];
const PageLayout = [
  { label: "Portrait " },
  { label: "Option1" },
  { label: "Extraordinary" },
  { label: "Option2" },
];
const PageSize = [
  { label: "A4"},
  { label: "A5" },
  { label: "A2" },
  { label: "A3" },
];
const FontSize = [
  { label: "12"},
  { label: "14" },
  { label: "16" },
  { label: "22" },
];
const LogoSize  = [
  { label: "Percent"},
  { label: "Option1" },
  { label: "Option2" },
  { label: "Option3" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InvoiceDesignTab({ handleDrawerClose ,selectedTab}) {
  const [state, setState] = React.useState({
    ShowPreview: false,



    EnablePDFMarkdown: false,
    EnableEmailMarkdown: true,

    IncludeDrafts: false,
    IncludeDeleted: false,
  });

  // const [valueRedio, setvalueRedio] = React.useState('Bottom');

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };



    //  inputFild with cross button

    const [valueText, setValueText] = useState({
        fildOne: "Roboto",
        fildTwo: "inherit",
        fildThree: "#2F7DC3",
        fildFour: "#00FF00",
        
      });
      const handleFontFamilyChange = (e) => {
        const temp = { ...valueText };
        temp.fildOne = e.target.value;
        setValueText(temp);
      };
      const handleClear = (e) => {
        const temp = { ...valueText };
        temp.fildOne = "";
        setValueText(temp);
      };
      const handleClearTwo = () => {
        const temp = { ...valueText };
        temp.fildTwo = "";
        setValueText(temp);
      };
      const handleClearThree = () => {
        const temp = { ...valueText };
        temp.fildThree = "";
        setValueText(temp);
      };
      const handleClearFour = () => {
        const temp = { ...valueText };
        temp.fildFour = "";
        setValueText(temp);
      };
  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "grid",
          gridTemplateColumns:"70% 30%",
          justifyContent: "space-between",
          columnGap:"20px",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
         value={value}
         onChange={handleChange}
         variant="scrollable"
         scrollButtons
         allowScrollButtonsMobile
         aria-label="scrollable force tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("General Setting")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Client Details")}
            {...a11yProps(1)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 2 ? "#00A1E4" : "white",
              color: value === 2 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Company Details ")}
            {...a11yProps(2)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 3 ? "#00A1E4" : "white",
              color: value === 3 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Company Address ")}
            {...a11yProps(3)}
          />
          
           <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 4 ? "#00A1E4" : "white",
              color: value === 4 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Inovice Details ")}
            {...a11yProps(4)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>
{/* for mobile */}
      <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs

          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("General setting")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Client details")}
            {...a11yProps(1)}
          />

<Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 2 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Company details ")}
            {...a11yProps(2)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 3 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Company address ")}
            {...a11yProps(3)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 4 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Inovice details")}
            {...a11yProps(4)}
          />




        </Tabs>

      
      </Box>
      <div className={"mobilVied"}>
      <TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "300px 10px 5px 7px",
            padding: "8px 0px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
            style={{display:"block",textAlign:'center'}}
          >
            
          
            <button
              style={{
               width:'80%',
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
            
             
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >    
                COUSTOMIZE
              </span>
            </button>
         
            <FormControl component="fieldset" variant="standard" style={{width:"100%"}}>
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // paddingRight: "20px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Show Preview"
                control={
                  <Switch
                    checked={state.ShowPreview}
                    onChange={handleChangebutton}
                    name="ShowPreview"
                  />
                }
                labelPlacement="start"
              />
        

             

            
            </FormControl>

          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "0px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            {/* 1 */}
             <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={InvoiceDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Invoice Design" variant="standard" />
              )}
            />
            {/* 2 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={QuoteDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Quote Design " variant="standard" />
              )}
            />
            {/* 3 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={CreditDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Credit Design" variant="standard" />
              )}
            />
            {/* 4 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PurchaseOrderDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Purchase Order Design" variant="standard" />
              )}
            />
            {/* 5 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PageLayout}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Page Layout" variant="standard" />
              )}
            />
            {/* 6 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PageSize }
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Page Size " variant="standard" />
              )}
            />
            {/* 7 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={FontSize}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Font Size" variant="standard" />
              )}
            />
            {/* 8 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={LogoSize }
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Logo Size " variant="standard" />
              )}
            />
          </Box>
        </div>




        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"62px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            {/* 1 */}
            <TextField
  sx={{ width: "100%" }}
  label="Primary Font"
  variant="standard"
  value={valueText.fildOne}
  onChange={handleFontFamilyChange}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end" style={{ margin: "0px 20px 5px 0px" }}>
      
        <IconButton edge="end" onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
{/* 2 */}
<TextField
            sx={{ width: "100%" }}
            label="Secondary Font"
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  
                  <IconButton edge="end" onClick={handleClearTwo}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />



{/* 3 */}
<TextField
            sx={{ width: "100%" }}
            label="Primary Color"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  <InputAdornment position="end" type="color">
                    <input
                      type="color"
                      value={valueText.fildThree}
                      onChange={(e) => {
                        const temp = { ...valueText };
                        temp.fildThree = e.target.value;
                        setValueText(temp);
                      }}
                      style={{
                        marginRight: "30px",
                        width: "100px",
                        height: "36px",
                        marginBottom: "15px",
                      }}
                    />
                  </InputAdornment>
                  <IconButton edge="end" onClick={handleClearThree}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
{/* 4 */}
<TextField
            sx={{ width: "100%" }}
            label="Accent Color"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  <InputAdornment position="end" type="color">
                    <input
                      type="color"
                      value={valueText.fildFour}
                      onChange={(e) => {
                        const temp = { ...valueText };
                        temp.fildFour = e.target.value;
                        setValueText(temp);
                      }}
                      style={{
                        marginRight: "30px",
                        width: "100px",
                        height: "36px",
                        marginBottom: "15px",
                      }}
                    />
                  </InputAdornment>
                  <IconButton edge="end" onClick={handleClearFour}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          </Box>
        </div>

    
      </TabPanel>
      <TabPanel className={"Othertab "} value={value} index={1}>
        Item Two
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
       <TabPanel className={"Othertab"} value={value} index={4}>
        Item Four
      </TabPanel>
      </div>

      
      <div className={"webview"}>
      <TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "0px 10px 5px 7px",
            padding: "8px 0px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
            style={{display:"grid",gridTemplateColumns:"30% 58%",justifyContent:'space-between',alignItems:"center",}}
          >
            
          
            <button
              style={{
               
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
             
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                }}
              >    
                COUSTOMIZE
              </span>
            </button>
         
            <FormControl component="fieldset" variant="standard" style={{width:"100%"}}>
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "20px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Show Preview"
                control={
                  <Switch
                    checked={state.ShowPreview}
                    onChange={handleChangebutton}
                    name="ShowPreview"
                  />
                }
                labelPlacement="start"
              />
        

             

            
            </FormControl>

          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "0px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            {/* 1 */}
             <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={InvoiceDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Invoice Design" variant="standard" />
              )}
            />
            {/* 2 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={QuoteDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Quote Design " variant="standard" />
              )}
            />
            {/* 3 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={CreditDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Credit Design" variant="standard" />
              )}
            />
            {/* 4 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PurchaseOrderDesign}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Purchase Order Design" variant="standard" />
              )}
            />
            {/* 5 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PageLayout}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Page Layout" variant="standard" />
              )}
            />
            {/* 6 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={PageSize }
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Page Size " variant="standard" />
              )}
            />
            {/* 7 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={FontSize}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Font Size" variant="standard" />
              )}
            />
            {/* 8 */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={LogoSize }
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Logo Size " variant="standard" />
              )}
            />
          </Box>
        </div>




        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"62px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            {/* 1 */}
            <TextField
  sx={{ width: "100%" }}
  label="Primary Font"
  variant="standard"
  value={valueText.fildOne}
  onChange={handleFontFamilyChange}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end" style={{ margin: "0px 20px 5px 0px" }}>
      
        <IconButton edge="end" onClick={handleClear}>
          <ClearIcon />
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
{/* 2 */}
<TextField
            sx={{ width: "100%" }}
            label="Secondary Font"
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  
                  <IconButton edge="end" onClick={handleClearTwo}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />



{/* 3 */}
<TextField
            sx={{ width: "100%" }}
            label="Primary Color"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  <InputAdornment position="end" type="color">
                    <input
                      type="color"
                      value={valueText.fildThree}
                      onChange={(e) => {
                        const temp = { ...valueText };
                        temp.fildThree = e.target.value;
                        setValueText(temp);
                      }}
                      style={{
                        marginRight: "30px",
                        width: "100px",
                        height: "36px",
                        marginBottom: "15px",
                      }}
                    />
                  </InputAdornment>
                  <IconButton edge="end" onClick={handleClearThree}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
{/* 4 */}
<TextField
            sx={{ width: "100%" }}
            label="Accent Color"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  <InputAdornment position="end" type="color">
                    <input
                      type="color"
                      value={valueText.fildFour}
                      onChange={(e) => {
                        const temp = { ...valueText };
                        temp.fildFour = e.target.value;
                        setValueText(temp);
                      }}
                      style={{
                        marginRight: "30px",
                        width: "100px",
                        height: "36px",
                        marginBottom: "15px",
                      }}
                    />
                  </InputAdornment>
                  <IconButton edge="end" onClick={handleClearFour}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          </Box>
        </div>

    
      </TabPanel>
      <TabPanel className={"Othertab "} value={value} index={1}>
        Item Two
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
       <TabPanel className={"Othertab"} value={value} index={4}>
        Item Four
      </TabPanel>
      </div>

     
    </Box>
  );
}
