import React, { useState } from "react";

import Typography from "@mui/material/Typography";

import { MdOutlineCancel } from "react-icons/md";

import { MdOutlineCloudUpload } from "react-icons/md";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';

import {
  Box,
  Button,
 
} from "@mui/material";










export default function TaskSettingTab({ handleDrawerClose ,selectedTab}) {
  
    const [state, setState] = React.useState({
       
        AutoStartTasks:false,
        showTaskEndDate:false,
        ShowTaskItemDescription:false,
        AllowBillableTaskItems:true,
        jason: true,
        antoine: true,
         overPayment:false, 
        underPayment :false,



        onlinePaymentEmail:false,
        manualPaymentEmail:false,
        markPaidPaymentEmail:false ,
        convertProducts:false 

      });
    
      const handleChangebutton = (event) => {
        setState({
          ...state,
          [event.target.name]: event.target.checked,
        });
      };

  //  inputFild with cross button

 



  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{ 
          display: 'flex',
          justifyContent:'end',
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
     

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

 {/* for mobile */}

 <Box
        className={"mobilVied"}
        style={{
          backgroundColor: "#1975D2",

          height: "56px",
          paddingBottom: "47px",
          paddingTop: "119px",
          position: "fixed",
          width: "100%",
          zIndex: "2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 20px 0px 20px",
          }}
        >
          <Button onClick={handleDrawerClose}>
            <img
              style={{ width: "14.98px", height: "14.66px" }}
              src="/assets/images/leftl.png"
              alt=""
            />

            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              {selectedTab}
            </span>
          </Button>
          <Button>
            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              Save
            </span>
          </Button>
        </div>
      </Box>

      <div
        style={{}}
        className={"Othertab boxTwo mobilVied"}
     
      >
      <div
          style={{
            overflow: "hidden",
            margin: "195px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"

          >

            <div style={{width:"100%",borderBottom:"1px solid black"}}>
                <p style={{fontSize:"16px",fontWeight:"400",color:"black"}}>Default Task Rate</p>
            </div>
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Auto Start Tasks"
       control={<Switch checked={state.AutoStartTasks} onChange={handleChangebutton} name="AutoStartTasks" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Start tasks before saving</FormHelperText>




<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="show Task End Date"
       control={<Switch checked={state.showTaskEndDate} onChange={handleChangebutton} name="showTaskEndDate" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Enable specifying the task end date </FormHelperText>
  


      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Show Task Item Description"
       control={<Switch checked={state.ShowTaskItemDescription} onChange={handleChangebutton} name="ShowTaskItemDescription" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Enable specifying task item descriptions </FormHelperText>
  




      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Allow Billable Task Items"
       control={<Switch checked={state.AllowBillableTaskItems} onChange={handleChangebutton} name="AllowBillableTaskItems" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Enable configuring which task items are billed </FormHelperText>
  
 </FormControl>

           

          
            
          </Box>
        </div>

        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor:"#00A1E4",
              color:"#fff",
            
             
            }}
          > <span style={{display:'flex',alignItems:'center',justifyContent:'start',columnGap:'52px'}}>
             <img style={{marginLeft:"169px"}} src="/assets/images/set.svg"></img>
             CONFIGURE STATUSES
          </span>
           
          </button>
        </div>
       
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Invoice Task Datelog"
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add date details to the invoice line items</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Invoice Task Timelog"
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add time details to the invoice line items</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Invoice Task Hours"
       control={<Switch checked={state.overPayment} onChange={handleChangebutton} name="overPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add the hours to the invoice line items</FormHelperText>
  



      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Invoice Task Project"
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add the project to the invoice line items </FormHelperText>
  


 </FormControl>

           

          
            
          </Box>
        </div>


        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"20px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Show Tasks Table"
       control={<Switch checked={state.onlinePaymentEmail} onChange={handleChangebutton} name="onlinePaymentEmail" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Always show the tasks section when creating invoices</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Lock Invoiced Tasks"
       control={<Switch checked={state.manualPaymentEmail} onChange={handleChangebutton} name="manualPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Prevent tasks from being edited once invoiced</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Add Documents to Invoice"
       control={<Switch checked={state.markPaidPaymentEmail} onChange={handleChangebutton} name="markPaidPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Make the documents visibile to clients</FormHelperText>
  


 </FormControl>
     
            
          </Box>
        </div>
        
        
        
        
         <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"200px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
        <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px',marginBottom: '10px'}}>
         <img src="/assets/images/reUse.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Client Portal
         </Typography>
       </div>
     }
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400'}}>Tasks shown in portal</FormHelperText>


      <FormHelperText style={{fontSize:"12px",fontWeight:'400'}}>Invoiced</FormHelperText>
  

 </FormControl>
     
            
          </Box>
        </div>

        
      </div>


      <div
        style={{}}
        className={"Othertab boxTwo webview"}
     
      >
      <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"

          >

            <div style={{width:"100%",borderBottom:"1px solid black"}}>
                <p style={{fontSize:"16px",fontWeight:"400",color:"black"}}>Default Task Rate</p>
            </div>
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Auto Start Tasks"
       control={<Switch checked={state.AutoStartTasks} onChange={handleChangebutton} name="AutoStartTasks" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Start tasks before saving</FormHelperText>




<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="show Task End Date"
       control={<Switch checked={state.showTaskEndDate} onChange={handleChangebutton} name="showTaskEndDate" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Enable specifying the task end date </FormHelperText>
  


      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Show Task Item Description"
       control={<Switch checked={state.ShowTaskItemDescription} onChange={handleChangebutton} name="ShowTaskItemDescription" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Enable specifying task item descriptions </FormHelperText>
  




      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Allow Billable Task Items"
       control={<Switch checked={state.AllowBillableTaskItems} onChange={handleChangebutton} name="AllowBillableTaskItems" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Enable configuring which task items are billed </FormHelperText>
  
 </FormControl>

           

          
            
          </Box>
        </div>

        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "100%",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor:"#00A1E4",
              color:"#fff",
            
             
            }}
          > <span style={{display:'flex',alignItems:'center',justifyContent:'start',columnGap:'52px'}}>
             <img style={{marginLeft:"169px"}} src="/assets/images/set.svg"></img>
             CONFIGURE STATUSES
          </span>
           
          </button>
        </div>
       
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Invoice Task Datelog"
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add date details to the invoice line items</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Invoice Task Timelog"
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add time details to the invoice line items</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Invoice Task Hours"
       control={<Switch checked={state.overPayment} onChange={handleChangebutton} name="overPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add the hours to the invoice line items</FormHelperText>
  



      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Invoice Task Project"
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Add the project to the invoice line items </FormHelperText>
  


 </FormControl>

           

          
            
          </Box>
        </div>


        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"20px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Show Tasks Table"
       control={<Switch checked={state.onlinePaymentEmail} onChange={handleChangebutton} name="onlinePaymentEmail" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Always show the tasks section when creating invoices</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Lock Invoiced Tasks"
       control={<Switch checked={state.manualPaymentEmail} onChange={handleChangebutton} name="manualPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Prevent tasks from being edited once invoiced</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Add Documents to Invoice"
       control={<Switch checked={state.markPaidPaymentEmail} onChange={handleChangebutton} name="markPaidPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Make the documents visibile to clients</FormHelperText>
  


 </FormControl>
     
            
          </Box>
        </div>
        
        
        
        
         <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"200px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
        <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px',marginBottom: '10px'}}>
         <img src="/assets/images/reUse.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Client Portal
         </Typography>
       </div>
     }
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400'}}>Tasks shown in portal</FormHelperText>


      <FormHelperText style={{fontSize:"12px",fontWeight:'400'}}>Invoiced</FormHelperText>
  

 </FormControl>
     
            
          </Box>
        </div>

        
      </div>


      
      
    </Box>
  );
}
