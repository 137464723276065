import React, { useState } from "react";
import { BottomNavigation } from "@mui/material";
import { Box, Button, IconButton, Menu, MenuItem, Table } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import { MdAssignmentReturn } from "react-icons/md";
import { MdSdCard } from "react-icons/md";
import { MdAddBox } from "react-icons/md";
import AddIcon from "@mui/icons-material/Add";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "chart.js/auto";
import { MdArrowUpward } from "react-icons/md";
import { MdArrowDownward } from "react-icons/md";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgressWithLabel from "../Components/spinner";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import DeshBoardMobile from "./deshBoardMobile";

// chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
// drowerSideTab
function TabPanelDrower(props) {
  const { children, Sidevalue, i, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={Sidevalue !== i}
      id={`simple-side-tabpanel-${i}`}
      aria-labelledby={`simple-tab-${i}`}
      {...other}
    >
      {Sidevalue === i && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelDrower.propTypes = {
  children: PropTypes.node,
  i: PropTypes.number.isRequired,
  Sidevalue: PropTypes.number.isRequired,
};

function a11yPropsDrower(i) {
  return {
    id: `simple-tab-${i}`,
    "aria-controls": `simple-side-tabpanel-${i}`,
  };
}
// end

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const labels = ["", "", "", "", ""];

export const data = {
  labels,
  datasets: [
    {
      label: "none",

      data: [7, 10, 5, 12, 10],
      backgroundColor: [
        "#E0E4FF",
        "#E0E4FF",
        "#E0E4FF",
        "#E0E4FF",
        "#00A1E4",
        "#E0E4FF",
        "#00A1E4",
      ],
      borderWidth: 0,
    },
  ],
};
const options2 = {
  // CategoryScale:false,
  LinearScale: false,
  responsive: true,
  plugins: {
    legend: {
      position: "none",
    },
  },

  scales: {
    y: {
      beginAtZero: true,
      label: false,
      grid: {
        borderColor: "rgba(0, 0, 0, 0)!important", // Set border color to transparent
        drawBorder: false, // Remove the vertical line on the right side
        display: false, // Remove the vertical grid lines
      },
      ticks: {
        display: false, // Hide the left side values
      },
    },
    x: {
      grid: {
        display: false, // Remove the horizontal grid lines
      },
      ticks: {
        display: false, // Hide the left side values
      },
    },
  },
};

const Dashboard = () => {
  //  drowerSideTab

  const [Sidevalue, setValueDrower] = React.useState(0);

  const handleChangeDrawer = (event, newValue) => {
    setValueDrower(newValue);
  };
  // end
  const [loadingOther, setLoadingOther] = useState(false);

  const dashboard = {
    // fontFamily: "Inter",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
    textTransform: "none",
  };
  const [showDivs, setShowDivs] = useState(false);
  const className = showDivs ? "blockStyle" : "flexStyle";
  const className2 = showDivs ? "blockS" : "blockSReverse";
  const className3 = showDivs ? "blockSs" : "blockSReversee";

  const handleClick = () => {
    setShowDivs(true);
    setLoadingOther(true);
    setTimeout(() => {
      setLoadingOther(false); // Set loading state to false after 1 seconds
    }, 2000);
  };

  const handleClose = () => {
    setShowDivs(false);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const values = [
    { name: "Silver Plan", val: 50.4, color: "#1a90ff" },
    { name: "Silver Plan", val: 40.4, color: "#FFA353" },

    { name: "Diamond Plan", val: 60.4, color: "#20C997" },
    {
      name: "Platinium Plan",
      val: 60.4,
      color: "#FF63A5",
    },
    {
      name: "Vibranium Plan",
      val: 60.4,
      color: "#00A1E4",
    },
  ];
  const user = [
    {
      name: "Keith Jensen requested to Widthdrawl.",

      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Harry Simpson placed a Order.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Stephanie Marshall got a huge bonus.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Timothy Moreno placed a Order.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
    {
      name: "Keith Jensen requested to Widthdrawl.",
      image: "/assets/images/download.jpg",
      activeStatus: "2 hours ago ",
    },
  ];
  const totalDeposit = [
    {
      diposit: "49,398.397",
      withdraw: "",
      balance: "",
      parcent: "3.9",
      month: "321,2.2",
      week: "321,2.2",
    },
    {
      diposit: "",
      withdraw: "59,398.397",
      balance: "",
      parcent: "3.9",
      month: "321,2.2",
      week: "321,2.2",
    },
    {
      diposit: "",
      withdraw: "",
      balance: "69,398.397",
      parcent: "3.9",
      month: "321,2.2",
      week: "321,2.2",
    },
  ];

  // const CheckBoxData=[
  const drewerData = [
    {
      title: "Tasks",

      name: "Komende",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak buiten de tijd (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Te starten taak (test data)",
          date: "22march 2023",
        },
      ],
    },

    {
      title: "Meeting Today",

      name: "Komende 7 dagen",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak buiten de tijd (test data)",
          date: "22march 2023",
        },
      ],
    },

    {
      title: "Calls today",

      name: "Komende 7 dagen",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak buiten de tijd (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Te starten taak (test data)",
          date: "22march 2023",
        },
      ],
    },

    {
      title: "Tasks",

      name: "Komende ",
      OptionData: [
        { label: "option 1" },
        { label: "option 2" },
        { label: "option3" },
        { label: "option4" },
      ],

      CheckBoxData: [
        {
          lableData: "Te Starten Taak (test data)",
          date: "22march 2023",
        },
        {
          lableData: "Taak (test data)",
          date: "22march 2023",
        },
      ],
    },
  ];
  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };
  return (
    <>
      <div
        className={"webview "}
        style={{ display: "flex", maxHeight: "88vh" }}
      >
        <div
          style={{
            width: showDivs ? "58.3%" : "100%",
            transition: "width 0.3s linear ",

            // maxHeight: "100%",
            // overflowY: "scroll"
            // marginBottom:"1000px"
          }}
        >
          <BottomNavigation
            style={{
              backgroundColor: "#ffffff",
              // position:'fixed',
              // width: showDivs  ? "55.4%" : "100%",
              height: "49px",
              transition: "width 0.3s linear ",
              zIndex: "2",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 15px 0px 10px",
              boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignContent: "center",
                columnGap: "30px",
              }}
            >
              <p style={dashboard}>Dashboard</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "5px",
                }}
              >
                {/* <HomeIcon /> */}
                <IconButton aria-label="delete" sx={{ padding: "0px" }}>
                  <img src="/assets/images/homeTwo.svg" alt=""></img>
                </IconButton>
                <p style={{ fontSize: "14px", fontWeight: "600" }}>
                  Home{" "}
                  <span style={{ color: "#00A1E4", padding: "0px 5px" }}>
                    {" "}
                    /{" "}
                  </span>
                  <span style={{ color: "#00A1E4" }}>Dashboard</span>{" "}
                </p>
              </div>
            </div>

            <div>
              <IconButton aria-label="delete">
                <img src="/assets/images/logOut.svg" alt=""></img>
              </IconButton>
              <IconButton aria-label="delete">
                <img src="/assets/images/memory.svg" alt></img>
              </IconButton>

              <Button
                style={{
                  color: "black",
                  fontSize: "14px",
                  fontWeight: "600",
                  textTransform: "none",
                }}
                size="small"
              >
                Reports
              </Button>
              <IconButton
                aria-label="delete"
                sx={{ paddingLeft: "20px" }}
                onClick={handleClick}
              >
                <img
                  style={{ height: "30px" }}
                  src="/assets/images/pls.png"
                  alt=""
                ></img>
              </IconButton>
            </div>
          </BottomNavigation>
          <div className={"scroolDemDashboard"}>
            <div
              style={{ margin: "17px 30px 0px 14px", paddingBottom: "200px" }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: " 33% 33% 33%",
                  columnGap: "10px",
                  // maxHeight: "100vh",
                }}
              >
                {totalDeposit.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      padding: "30px 10px 0px 15px ",
                      backgroundColor: "#FFFFFF",
                      border: "2px solid rgba(0, 0, 0, 0.15)",
                      borderRadius: "2px",
                      width: "100%",
                    }}
                  >
                    {item.diposit !== "" ? (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            color: "#000000",
                            margin: "0px",
                          }}
                        >
                          Total deposit
                        </p>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          {item.diposit} USD
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward />
                            {item.parcent}{" "}
                            <span sx={{ paddingTop: "3px" }}>%</span>
                          </span>
                        </h2>
                      </>
                    ) : item.withdraw !== "" ? (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            color: "#000000",
                            margin: "0px",
                          }}
                        >
                          Total withdraw
                        </p>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          {item.withdraw} USD
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward />
                            {item.parcent}{" "}
                            <span sx={{ paddingTop: "3px" }}>%</span>
                          </span>
                        </h2>
                      </>
                    ) : item.balance !== "" ? (
                      <>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            color: "#000000",
                            margin: "0px",
                          }}
                        >
                          Balance in Acount
                        </p>
                        <h2
                          style={{
                            fontWeight: "600",
                            fontSize: "20px",
                            margin: "0px",
                            paddingTop: "10px",
                          }}
                        >
                          {item.balance} USD
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: "15px",
                              color: "#30DDBE",
                            }}
                          >
                            <MdArrowUpward />
                            {item.parcent}{" "}
                            <span sx={{ paddingTop: "3px" }}>%</span>
                          </span>
                        </h2>
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "54% 27%",
                        justifyContent: "start",
                        alignItems: "center",
                        columnGap: "10%",
                      }}
                    >
                      <div className={className}>
                        <div className={className2}>
                          <p
                            className={className3}
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                              margin: "0px",
                              paddingBottom: "5px",
                            }}
                          >
                            This Month
                          </p>
                          <h2
                            style={{
                              fontWeight: "600",
                              fontSize: "17px",
                              margin: "0px",
                            }}
                          >
                            {item.month} USD
                          </h2>
                        </div>

                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                              margin: "0px",
                              paddingBottom: "5px",
                            }}
                          >
                            This week
                          </p>
                          <h2
                            style={{
                              fontWeight: "600",
                              fontSize: "17px",
                              margin: "0px",
                            }}
                          >
                            {item.week} USD
                          </h2>
                        </div>
                      </div>

                      <div>
                        <Box
                          sx={{
                            height: "131px",
                            width: "84px",
                            border: "none",

                            overflowY: "hidden",
                          }}
                        >
                          <Bar
                            data={data}
                            options={options2}
                            width={100}
                            maxHeight={100}
                          />
                        </Box>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: " 33% 33% 33%",
                  columnGap: "10px",
                  paddingTop: "20px",
                }}
              >
                <div
                  style={{
                    border: "2px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                  }}
                >
                  <div>
                    <p
                      style={{
                        paddingLeft: "17px",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      Investment overview
                    </p>
                    <p
                      style={{
                        paddingLeft: "17px",
                        fontWeight: "400",
                        fontSize: "15px",
                      }}
                    >
                      The investment overview of your platform.
                      <span style={{ color: "#1975D2" }}>All investment</span>
                    </p>
                    <Box sx={{ width: "100%" }}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          style={{ padding: "0px 10px" }}
                        >
                          <Tab
                            style={{
                              fontWeight: "700",
                              fontSize: "16px",
                              padding: "10px 0px",
                              textTransform: "none",
                            }}
                            label={formatLabel("Overview")}
                            {...a11yProps(0)}
                          />
                          <Tab
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              textTransform: "none",
                            }}
                            label={formatLabel("This year")}
                            {...a11yProps(1)}
                          />
                          <Tab
                            style={{
                              fontWeight: "600",
                              fontSize: "16px",
                              textTransform: "none",
                            }}
                            label={formatLabel("All time")}
                            {...a11yProps(2)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                          }}
                        >
                          Currently Activated Investment
                        </p>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "59% 39%",
                            columnGap: "8%",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <h2
                              style={{
                                fontWeight: "600",
                                fontSize: "20px",
                                margin: "0px",
                                paddingBottom: "5px",
                              }}
                            >
                              49,398.397 USD
                            </h2>
                            <span> Amount</span>
                          </div>
                          <div>
                            <p
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                margin: "0px",
                              }}
                            >
                              56{" "}
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#30DDBE",
                                }}
                              >
                                <MdArrowUpward sx={{ fontSize: "15px" }} /> 3.9
                                %
                              </span>
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "15px",
                                margin: "0px",
                              }}
                            >
                              Plans
                            </p>
                          </div>
                        </div>

                        <div style={{ padding: "20px 0px" }}>
                          <h2
                            style={{
                              fontWeight: "600",
                              fontSize: "20px",
                              margin: "0px",
                              paddingBottom: "5px",
                            }}
                          >
                            49,398.397 USD
                          </h2>
                          <span>Paid Profit</span>
                        </div>
                        {/* try */}
                        <hr
                          style={{ color: "2px solid rgba(0, 0, 0, 0.15)" }}
                        />

                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "15px",
                            marginTop: "20px",
                          }}
                        >
                          Investment in this monts
                        </p>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "59% 39%",
                            columnGap: "8%",
                          }}
                        >
                          <div>
                            <h2
                              style={{
                                fontWeight: "600",
                                fontSize: "20px",
                                margin: "0px",
                                paddingBottom: "5px",
                              }}
                            >
                              49,398.397 USD
                            </h2>
                            <span> Amount</span>
                          </div>
                          <div style={{ paddingTop: "10px" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                fontWeight: "400",
                                margin: "0px",
                              }}
                            >
                              56{" "}
                              <span
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#F50000",
                                }}
                              >
                                <MdArrowDownward sx={{ fontSize: "15px" }} />{" "}
                                3.9 %
                              </span>
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "15px",
                                margin: "0px",
                              }}
                            >
                              Plans
                            </p>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel value={value} index={1}>
                        Item Two
                      </TabPanel>
                      <TabPanel value={value} index={2}>
                        Item Three
                      </TabPanel>
                    </Box>
                  </div>
                </div>
                <div
                  style={{
                    border: "2px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px 20px",
                    }}
                  >
                    <div>
                      <h2 style={{ fontWeight: "600", fontSize: "15px" }}>
                        Top Invested Plan
                      </h2>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "15px",
                          margin: "0px",
                        }}
                      >
                        In the last 30 days top invested plan
                      </p>
                    </div>

                    <MoreVertIcon />
                  </div>
                  <>
                    {values.map((item, i) => (
                      <Box key={i} sx={{ margin: "20px" }}>
                        <Typography
                          variant="subtitle1"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "0px 20px 10px 5px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "#000000",
                            }}
                          >
                            {item.name}
                          </div>
                          <div
                            style={{
                              fontWeight: "400",
                              fontSize: "15px",
                              color: "rgba(0, 0, 0, 0.44)",
                            }}
                          >
                            {" "}
                            {item.val}%
                          </div>
                        </Typography>
                        <LinearProgress
                          sx={{
                            backgroundColor: "#E0E4FF",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor: item.color,
                            },
                            height: 5,
                          }}
                          value={item.val}
                          variant="determinate"
                        />
                      </Box>
                    ))}
                  </>
                </div>
                <div
                  style={{
                    border: "2px solid rgba(0, 0, 0, 0.15)",
                    borderRadius: "2px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: "12px 0px 0px 20px",
                    }}
                  >
                    <h2 style={{ fontWeight: "600", fontSize: "15px" }}>
                      Recent Activities{" "}
                    </h2>
                  </div>

                  <div className={"scroolDem"} style={{ height: "500px" }}>
                    <>
                      {user.map((item, i) => (
                        <div
                          key={i}
                          style={{ border: "1px solid rgba(0, 0, 0, 0.13)" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                              columnGap: "20px",
                              margin: "14px",
                            }}
                          >
                            {item.image ? (
                              <img
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "100%",
                                  objectFit: "cover",
                                }}
                                src={item.image}
                                alt=""
                              ></img>
                            ) : (
                              <img src="https://static.vecteezy.com/system/resources/previews/008/442/086/original/illustration-of-human-icon-user-symbol-icon-modern-design-on-blank-background-free-vector.jpg" />
                            )}

                            <div>
                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "15px",
                                  margin: "0px",
                                }}
                              >
                                {item.name}
                              </p>
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  color: "rgba(0, 0, 0, 0.51)",
                                  margin: "0px",
                                }}
                              >
                                {item.activeStatus}{" "}
                              </span>
                            </div>
                          </div>
                          {/* <hr style={{ color: "rgba(0, 0, 0, 0.13)" }} /> */}
                        </div>
                      ))}
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            width: showDivs ? "42%" : "0%",
            border: "1px solid rgba(0, 0, 0, 0.22)",
            transition: "width 0.3s ease",
            // maxHeight: "100%",
            // overflowY: "scroll"
          }}
        >
          <Box
            sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: "background.paper" }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                columnGap: "33px",
                height: "49px",
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: { backgroundColor: "transparent", display: "none" },
                }}
                Sidevalue={Sidevalue}
                onChange={handleChangeDrawer}
                aria-label="scrollable force tabs example"
                // variant="scrollable"
                // scrollButtons
                // allowScrollButtonsMobile
              >
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor:
                      Sidevalue === 0 ? "#00A1E4" : "transparent",
                    color: Sidevalue === 0 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Overview")}
                  {...a11yPropsDrower(0)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor:
                      Sidevalue === 1 ? "#00A1E4" : "transparent",
                    color: Sidevalue === 1 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Invoices")}
                  {...a11yPropsDrower(1)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor:
                      Sidevalue === 2 ? "#00A1E4" : "transparent",
                    color: Sidevalue === 2 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Payments")}
                  {...a11yPropsDrower(2)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor:
                      Sidevalue === 3 ? "#00A1E4" : "transparent",
                    color: Sidevalue === 3 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Expenses")}
                  {...a11yPropsDrower(3)}
                />
              </Tabs>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <span style={{ display: "inline-block" }} onClick={handleClose}>
                  <img src="/assets/images/cross.svg" alt="Close" />
                </span>
              </div>
            </Box>

            {loadingOther ? (
              <CircularProgressWithLabel />
            ) : (
              <>
                <Box
                  style={{ paddingBottom: "60px" }}
                  className={"tab scroolDemDashboard"}
                >
                  <TabPanelDrower Sidevalue={Sidevalue} i={0}>
                    {drewerData.map((item, index) => (
                      <>
                        <div
                          key={index}
                          style={{
                            background: "#F5F5F5",
                            boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                            height: "48px",
                            margin: "10px 0px 30px 20px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "0px 10px 0px 15px",
                          }}
                        >
                          <p style={{ fontWeight: "600", fontSize: "14px" }}>
                            {item.title}
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                              columnGap: "20px",
                            }}
                          >
                            <img src="/assets/images/plssBg.svg" />
                            <img src="/assets/images/menu2.svg" />
                          </div>
                        </div>

                        <div
                          key={index}
                          style={{ margin: "5px 23px 20px 23px" }}
                        >
                          <Autocomplete
                            id="combo-box-demo"
                            options={item.OptionData}
                            sx={{ width: 300 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                label={item.name}
                              />
                            )}
                          />
                        </div>

                        <FormGroup style={{ margin: "5px 23px 20px 23px" }}>
                          {item.CheckBoxData.map((data, index) => (
                            <div
                              key={index}
                              style={{
                                paddingBottom: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderBottom: "1px solid rgba(0, 0, 0, 0.13)",
                              }}
                            >
                              <FormControlLabel
                                control={<Checkbox Checkbox />}
                                label={data.lableData}
                              />
                              <span
                                style={{
                                  fontWeight: "400",
                                  fontSize: "12px",
                                  paddingTop: "22px",
                                }}
                              >
                                {" "}
                                {data.date}
                              </span>
                            </div>
                          ))}
                        </FormGroup>
                      </>
                    ))}

                    {}
                  </TabPanelDrower>
                  <TabPanelDrower Sidevalue={Sidevalue} i={1}>
                    Item Two
                  </TabPanelDrower>
                  <TabPanelDrower Sidevalue={Sidevalue} i={2}>
                    Item Three
                  </TabPanelDrower>
                </Box>
              </>
            )}
          </Box>
        </div>
      </div>

      <div className="mobilVied">
        <DeshBoardMobile />
      </div>
    </>
  );
};

export default Dashboard;
