import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const sizes = [
  { label: "XS" },
  { label: "S" },
  { label: "M" },
  { label: "L" },
  { label: "XL" },
  { label: "XXL" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompanyDetailsTab({ handleDrawerClose,selectedTab }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };
  //  inputFild with cross button

  const [valueText, setValueText] = useState({
    fildOne: "",
    fildTwo: "",
    fildThree: "",
    fildFour: "",
    fildFive: "",
    fildSix: "",
    fildSeven: "",
    fildEight: "",
    fildNine: "",
    fildTen: "",
  });

  const handleClear = () => {
    const temp = { ...valueText };
    temp.fildOne = "";
    setValueText(temp);
  };
  const handleClearTwo = () => {
    const temp = { ...valueText };
    temp.fildTwo = "";
    setValueText(temp);
  };
  const handleClearThree = () => {
    const temp = { ...valueText };
    temp.fildThree = "";
    setValueText(temp);
  };
  const handleClearFour = () => {
    const temp = { ...valueText };
    temp.fildFour = "";
    setValueText(temp);
  };
  const handleClearFive = () => {
    const temp = { ...valueText };
    temp.fildFive = "";
    setValueText(temp);
  };

  const handleClearSix = () => {
    const temp = { ...valueText };
    temp.fildSix = "";
    setValueText(temp);
  };

  const handleClearSeven = () => {
    const temp = { ...valueText };
    temp.fildSeven = "";
    setValueText(temp);
  };
  const handleClearEight = () => {
    const temp = { ...valueText };
    temp.fildEight = "";
    setValueText(temp);
  };
  const handleClearNine = () => {
    const temp = { ...valueText };
    temp.fildNine = "";
    setValueText(temp);
  };
  const handleClearTen = () => {
    const temp = { ...valueText };
    temp.fildTen = "";
    setValueText(temp);
  };
  return (
    <Box sx={{ width: "100%", maxHeight: "88vh"}}>
      <Box className={"webview"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Details")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Logo")}
            {...a11yProps(1)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 2 ? "#00A1E4" : "white",
              color: value === 2 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Defaults")}
            {...a11yProps(2)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 3 ? "#00A1E4" : "white",
              color: value === 3 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Documents")}
            {...a11yProps(3)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

      
      {/* for mobile */}

      <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Details")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Logo")}
            {...a11yProps(1)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor:"#1975D2",
              color: "white" ,
              borderBottom: value === 2 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Defaults")}
            {...a11yProps(2)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor:"#1975D2",
              color: "white" ,
              borderBottom: value === 3 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Documents")}
            {...a11yProps(3)}
          />
        </Tabs>

      
      </Box>
<div className={"mobilVied"}>
<TabPanel
      
      className={"Othertab boxTwo"}
      value={value}
      index={0}
    >
      <div
        style={{
          overflow: "hidden",
          margin: "250px 10px 25px 15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          padding: "15px 10px",
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Name"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="ID Number"
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTwo}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="VAT Number"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearThree}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="website"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              classes: {
                input: "underline-input", // Custom CSS class for underline style
              },
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFour}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Email"
            type="email"
            variant="standard"
            value={valueText.fildFive}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFive = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              classes: {
                input: "underline-input", // Custom CSS class for underline style
              },
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFive}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Phone"
            variant="standard"
            value={valueText.fildSix}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSix = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSix}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>

      <div
        style={{
          overflow: "hidden",
          margin: "30px 10px 25px 15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          padding: "15px 10px",
        }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={sizes}
          sx={{ width: "100%", marginLeft:"8px" }}
          renderInput={(params) => (
            <TextField style={{marginRight:"30px"}} {...params} label="Size" variant="standard" />
          )}
        />

        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
          noValidate
          autoComplete="off"
        >
          <TextField label="Industry" variant="standard" />
        </Box>
      </div>

      <div
        style={{
          overflow: "hidden",
          margin: "25px 10px 25px 15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          padding: "15px 10px",
          marginBottom:'60px'
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Street"
            variant="standard"
            value={valueText.fildSeven}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSeven = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSeven}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Apt/Suite"
            variant="standard"
            value={valueText.fildEight}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildEight = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearEight}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="City"
            variant="standard"
            value={valueText.fildNine}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildNine = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearNine}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="State/Province"
            variant="standard"
            value={valueText.fildTen}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTen = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTen}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>
    </TabPanel>
    <TabPanel className={"Othertab"} value={value} index={1}>
      Item Two
    </TabPanel>
    <TabPanel className={"Othertab"} value={value} index={2}>
      Item Three
    </TabPanel>
    <TabPanel className={"Othertab"} value={value} index={3}>
      Item Four
    </TabPanel>
</div>
<div className={"webview"}>
<TabPanel
      
      className={"Othertab boxTwo"}
      value={value}
      index={0}
    >
      <div
        style={{
          overflow: "hidden",
          margin: "40px 10px 25px 15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          padding: "15px 10px",
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Name"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="ID Number"
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTwo}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="VAT Number"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearThree}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="website"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              classes: {
                input: "underline-input", // Custom CSS class for underline style
              },
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFour}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Email"
            type="email"
            variant="standard"
            value={valueText.fildFive}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFive = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              classes: {
                input: "underline-input", // Custom CSS class for underline style
              },
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFive}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Phone"
            variant="standard"
            value={valueText.fildSix}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSix = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSix}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>

      <div
        style={{
          overflow: "hidden",
          margin: "30px 10px 25px 15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          padding: "15px 10px",
        }}
      >
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={sizes}
          sx={{ width: "100%", marginLeft:"8px" }}
          renderInput={(params) => (
            <TextField style={{marginRight:"30px"}} {...params} label="Size" variant="standard" />
          )}
        />

        <Box
          component="form"
          sx={{ "& > :not(style)": { m: 1, width: "100%" } }}
          noValidate
          autoComplete="off"
        >
          <TextField label="Industry" variant="standard" />
        </Box>
      </div>

      <div
        style={{
          overflow: "hidden",
          margin: "25px 10px 25px 15px",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
          border: "1px solid rgba(0, 0, 0, 0.23)",
          padding: "15px 10px",
          marginBottom:'60px'
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "100%" },
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            label="Street"
            variant="standard"
            value={valueText.fildSeven}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSeven = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSeven}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="Apt/Suite"
            variant="standard"
            value={valueText.fildEight}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildEight = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearEight}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="City"
            variant="standard"
            value={valueText.fildNine}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildNine = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearNine}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <TextField
            label="State/Province"
            variant="standard"
            value={valueText.fildTen}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTen = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  style={{ margin: "0px 20px 5px 0px" }}
                  position="end"
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTen}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </div>
    </TabPanel>
    <TabPanel className={"Othertab"} value={value} index={1}>
      Item Two
    </TabPanel>
    <TabPanel className={"Othertab"} value={value} index={2}>
      Item Three
    </TabPanel>
    <TabPanel className={"Othertab"} value={value} index={3}>
      Item Four
    </TabPanel>
</div>
    
    </Box>
  );
}
