import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { AiOutlineSearch as SearchIcon } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const formatLabel = (label) => {
  const formattedLabel =
    label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
  return formattedLabel;
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
  { label: "The Godfather: Part II", year: 1974 },
  { label: "The Dark Knight", year: 2008 },
];

const city = [
  { label: "Wollongong" },
  { label: "Geelong" },
  { label: "Hobart" },
  { label: "Townsville" },
];

export default function AddCompany() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#1975D2",
          position: "fixed",
          top: "0px",
          left: "auto",
          right: "0px",
          width: "100%",
          zIndex: "1203",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "15px",
          }}
        >
          <button
            style={{
              color: "white",
              fontSize: "18px",
              fontWeight: "400",
              fontFamily: "system-ui",
              background: "none",
              border: "none",
              outline: "none",
            }}
            onClick={() => navigate("/create-invoice")}
          >
            Cancel
          </button>
          <h2
            style={{
              color: "#fff",
              fontSize: "18px",
              fontWeight: "600",
              fontFamily: "system-ui",
            }}
          >
            New Company
          </h2>
          <button
            style={{
              color: "white",
              fontSize: "18px",
              fontWeight: "400",
              background: "none",
              border: "none",
              outline: "none",
              fontFamily: "system-ui",
            }}
          >
            Save
          </button>
        </div>

        <Tabs
          value={value}
          style={{ borderBotom: "none" }}
          onChange={handleChange}
        >
          <Tab
            style={{
              padding: 0,
              fontWeight: "500",
              fontSize: "17px",
              color: "black",
              textTransform: "none",
              fontFamily: "system-ui",
              borderBottom: value === 0 ? "4px solid #fff" : "none",
              color: "#fff",
              marginLeft: "10px",
            }}
            label={formatLabel("Basic info")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              padding: 0,
              fontWeight: "500",
              fontSize: "17px",
              color: "black",
              textTransform: "none",
              fontFamily: "system-ui",
              marginLeft: "20px",
              borderBottom: value === 1 ? "4px solid #fff" : "none",
              color: "#fff",
            }}
            label={formatLabel("Contact Person")}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>

      <TabPanel
        className={"scroolDemMobile"}
        style={{ marginTop: "100px ", backgroundColor: "#F5F5F5" }}
        value={value}
        index={0}
      >
        <div
          style={{
            backgroundColor: "#fff",
            marginTop: "45px",
            padding: "20px 10px 20px 10px",
          }}
        >
          <input
            style={{
              width: "100%",
              padding: "15px 0px",
              border: "none",
              outline: "none",
              fontWeight: "400",
              fontSize: "15px",
              color: "#1975D2",
              paddingBottom: "5px",
            }}
            placeholder="Company Name"
            type="text"
            className={"custom-input"}
          ></input>

          <hr style={{ borderBottom: "1px solid #1975D2" }} />
          <div
            style={{
              borderBottom: "1px solid #000000",
              padding: "25px 0px",
              height: "60px",
              display: "grid",
              gridTemplateColumns: "80% 10%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <input
              style={{
                color: "black",
                fontSize: "15px",
                fontWeight: "400",
                width: "100%",
                border: "none",
                outline: "none",
              }}
              placeholder="Email"
              className={"custom-input"}
            ></input>
            <SearchIcon
              style={{
                margin: "0px 10px",
                alignItems: "center",
                fontSize: "25px",
              }}
            />
          </div>
          <Autocomplete
            options={top100Films}
            sx={{
              width: "100%",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderBottom: "1px solid #000000",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  style: { color: "black" }, // Specify the color here
                }}
                variant="standard"
                label="Address"
                style={{ fontSize: "15px", fontFamily: "400" }}
              />
            )}
          />

          <Autocomplete
            options={city}
            sx={{
              width: "100%",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderBottom: "1px solid #000000",
            }}
            renderInput={(paramsTwo) => (
              <TextField
                {...paramsTwo}
                InputProps={{
                  ...paramsTwo.InputProps,
                  disableUnderline: true,
                }}
                InputLabelProps={{
                  style: { color: "black" }, // Specify the color here
                }}
                variant="standard"
                label="City"
                style={{ fontSize: "15px", fontFamily: "400" }}
              />
            )}
          />
          <div style={{ borderBottom: "1px solid #000000" }}>
            <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Postal Code"
              type="number"
              className={"custom-input"}
            ></input>
            {/* <p style={{fontSize:'14px',fontWeight:'400',color:'black',margin:'25px 0px 20px 0px'}}>Postal Code</p> */}
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>


          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="State"
              type="text"
              className={"custom-input"}
            ></input>
       
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>
          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Country"
              type="text"
              className={"custom-input"}
            ></input>
            
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>

          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder=" Private Notes"
              type="text"
              className={"custom-input"}
            ></input>
           
          </div>
        </div>

        <div
          style={{
            marginBottom: "100px",
            backgroundColor: "#fff",
            marginTop: "45px",
            padding: "20px 10px 20px 10px",
          }}
        >
          <div style={{ borderBottom: "1px solid #000000" }}>

          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Industry"
              type="text"
              className={"custom-input"}
            ></input>
          
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>

          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Sector"
              type="text"
              className={"custom-input"}
            ></input>
          
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>

          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Fax No"
              type="text"
              className={"custom-input"}
            ></input>
          
          </div>
        </div>
      </TabPanel>
      <TabPanel className={"scroolDemMobile"} value={value} index={1}>
        <div
          style={{
            marginBottom: "100px",
            backgroundColor: "#fff",
            marginTop: "45px",
            padding: "20px 10px 20px 10px",
          }}
        >
          <div style={{ borderBottom: "1px solid #000000" }}>

          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="First Name"
              type="text"
              className={"custom-input"}
            ></input>
           
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>

            
          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Last Name"
              type="text"
              className={"custom-input"}
            ></input>
           
          </div>

          <div style={{ borderBottom: "1px solid #000000" }}>
          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Phone"
              type='number'
              className={"custom-input"}
            ></input>
          
          </div>
          <div style={{ borderBottom: "1px solid #000000" }}>

          <input
              style={{
                width: "100%",
                // padding: "15px 0px",
                border: "none",
                outline: "none",
                fontWeight: "400",
                fontSize: "15px",
                color: "black",
                paddingBottom: "5px",
                margin:'25px 0px 20px 0px'
              }}
              placeholder="Email"
              type='email'
              className={"custom-input"}
            ></input>
       
          </div>
        </div>
      </TabPanel>
    </Box>
  );
}
