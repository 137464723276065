import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const sizes = [
  { label: "XS" },
  { label: "S" },
  { label: "M" },
  { label: "L" },
  { label: "XL" },
  { label: "XXL" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function UserDetailsTab({ handleDrawerClose,selectedTab }) {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };
  //  inputFild with cross button

  const [valueText, setValueText] = useState({
    fildOne: "",
    fildTwo: "",
    fildThree: "",
    fildFour: "",
    fildFive: "",
    fildSix: "#2F7DC3",
  });

  const handleClear = () => {
    const temp = { ...valueText };
    temp.fildOne = "";
    setValueText(temp);
  };
  const handleClearTwo = () => {
    const temp = { ...valueText };
    temp.fildTwo = "";
    setValueText(temp);
  };
  const handleClearThree = () => {
    const temp = { ...valueText };
    temp.fildThree = "";
    setValueText(temp);
  };
  const handleClearFour = () => {
    const temp = { ...valueText };
    temp.fildFour = "";
    setValueText(temp);
  };
  const handleClearFive = () => {
    const temp = { ...valueText };
    temp.fildFive = "";
    setValueText(temp);
  };
  const handleClearSix = () => {
    const temp = { ...valueText };
    temp.fildSix = "";
    setValueText(temp);
  };

  return (
    <>
      <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Details")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Notification")}
            {...a11yProps(1)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

      {/* for mobile */}
      <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Details")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Notification")}
            {...a11yProps(1)}
          />
          
          
        </Tabs>

      
      </Box>


<div className={"mobilVied"}>

<TabPanel
        style={{paddingTop:"215px",paddingBottom:'40px'}}
        className={"Othertab "}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="First Name"
              variant="standard"
              value={valueText.fildOne}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildOne = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Last Name"
              variant="standard"
              value={valueText.fildTwo}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildTwo = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearTwo}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Email"
              variant="standard"
              value={valueText.fildThree}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildThree = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                classes: {
                  input: "underline-input", // Custom CSS class for underline style
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearThree}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="website"
              variant="standard"
              value={valueText.fildFour}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildFour = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                classes: {
                  input: "underline-input", // Custom CSS class for underline style
                },
                endAdornment: (
                  <InputAdornment
                    style={{ margin: "0px 20px 5px 0px" }}
                    position="end"
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearFour}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Phone"
              variant="standard"
              value={valueText.fildFive}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildFive = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ margin: "0px 20px 5px 0px" }}
                    position="end"
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearFive}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {/* pass */}

            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment
                    style={{ margin: "0px 12px 5px 0px" }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "30px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <TextField
            sx={{ width: "100%" }}
            label="Accent Color"
            variant="standard"
            value={valueText.fildSix}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSix = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  <InputAdornment position="end" type="color">
                    <input
                      type="color"
                      value={valueText.fildSix}
                      onChange={(e) => {
                        const temp = { ...valueText };
                        temp.fildSix = e.target.value;
                        setValueText(temp);
                      }}
                      style={{
                        marginRight: "30px",
                        width: "100px",
                        height: "36px",
                        marginBottom: "15px",
                      }}
                    />
                  </InputAdornment>
                  <IconButton edge="end" onClick={handleClearSix}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginBottom: "200px",
            marginTop: "50px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "80%",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              
            }}
          >
            ENABLE TWO FACTOR
          </button>
        </div>
      </TabPanel>
      <TabPanel  value={value} index={1}>
        Item Two
      </TabPanel>
</div>
<div className={"webview"}>
<TabPanel
        style={{}}
        className={"Othertab "}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              label="First Name"
              variant="standard"
              value={valueText.fildOne}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildOne = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Last Name"
              variant="standard"
              value={valueText.fildTwo}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildTwo = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearTwo}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Email"
              variant="standard"
              value={valueText.fildThree}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildThree = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                classes: {
                  input: "underline-input", // Custom CSS class for underline style
                },
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearThree}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="website"
              variant="standard"
              value={valueText.fildFour}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildFour = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                classes: {
                  input: "underline-input", // Custom CSS class for underline style
                },
                endAdornment: (
                  <InputAdornment
                    style={{ margin: "0px 20px 5px 0px" }}
                    position="end"
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearFour}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              label="Phone"
              variant="standard"
              value={valueText.fildFive}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildFive = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    style={{ margin: "0px 20px 5px 0px" }}
                    position="end"
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClearFive}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
            {/* pass */}

            <FormControl sx={{ m: 1, width: "25ch" }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment
                    style={{ margin: "0px 12px 5px 0px" }}
                    position="end"
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "30px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <TextField
            sx={{ width: "100%" }}
            label="Accent Color"
            variant="standard"
            value={valueText.fildSix}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSix = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  <InputAdornment position="end" type="color">
                    <input
                      type="color"
                      value={valueText.fildSix}
                      onChange={(e) => {
                        const temp = { ...valueText };
                        temp.fildSix = e.target.value;
                        setValueText(temp);
                      }}
                      style={{
                        marginRight: "30px",
                        width: "100px",
                        height: "36px",
                        marginBottom: "15px",
                      }}
                    />
                  </InputAdornment>
                  <IconButton edge="end" onClick={handleClearSix}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginBottom: "200px",
            marginTop: "50px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "644px",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              cursor:'pointer'
            }}
          >
            ENABLE TWO FACTOR
          </button>
        </div>
      </TabPanel>
      <TabPanel  value={value} index={1}>
        Item Two
      </TabPanel>
</div>
      
      
    </Box>
    </>
  
  );
}
