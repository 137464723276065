const departments = [

  {
    name: "John Smith",
    number: "Sales Manager",
    name2: "Jane Doe",
    number2: "Marketing Coordinator",
    Amount: 200,
    status: "Complete",
  },
  {
    name: "Michael Johnson",
    number: "Product Manager",
    name2: "Emily Brown",
    number2: "Human Resources Director",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "David Wilson",
    number: "Operations Supervisor",
    name2: "Sarah Thompson",
    number2: "Finance Analyst",
    Amount: 200,
    status: "Complete",
  },
  {
    name: "Jennifer Davis",
    number: "Customer Service Representative",
    name2: "Daniel Lee",
    number2: "IT Specialist",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Laura Martinez",
    number: "Graphic Designer",
    name2: "Brian Adams",
    number2: "Research Analyst",
    Amount: 100,
    status: "Complete",
  },

  {
    name: "Sophia Rodriguez",
    number: "Project Manager",
    name2: "Oliver Harris",
    number2: "Software Engineer",
    Amount: 100,
    status: "Open",
  },

  {
    name: "Emma Clark",
    number: "Marketing Manager",
    name2: "Alexander Green",
    number2: "Sales Representative",
    Amount: 200,
    status: "Complete",
  },
  {
    name: "Ava Walker",
    number: "Financial Advisor",
    name2: "Henry Turner",
    number2: "Business Development Manager",
    Amount: 150,
    status: "Working on",
  },
  {
    name: "Isabella White",
    number: "Operations Manager",
    name2: "Liam Hall",
    number2: "Customer Success Manager",
    Amount: 100,
    status: "Backlog",
  },
  {
    name: "Stephanie Nguyen",
    number: "Marketing Coordinator",
    name2: "Matthew Roberts",
    number2: "Sales Associate",
    Amount: 100,
    status: "Open",
  },
  {
    name: "Andrew Thompson",
    number: "Product Development Manager",
    name2: "Emily Wilson",
    number2: "Operations Analyst",
    Amount: 150,
    status: "Complete",
  },
  {
    name: "Benjamin Mitchell",
    number: "Finance Director",
    name2: "Olivia Davis",
    number2: "Human Resources Manager",
    Amount: 200,
    status: "Open",
  },
  {
    name: "Grace Turner",
    number: "Customer Support Specialist",
    name2: "Samuel Harris",
    number2: "Software Developer",
    Amount: 200,
    status: "Working on",
  },

  {
    name: "Qngelica Ramos",
    number: "Qhief Executive Officer (CEO)",
    name2: "Qngelica Ramos",
    number2: "Qhief Executive Officer (CEO)",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Rngelica Ramos",
    number: "Rhief Executive Officer (CEO)",
    name2: "Rngelica Ramos",
    number2: "Rhief Executive Officer (CEO)",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Sngelica Ramos",
    number: "Shief Executive Officer (CEO)",
    name2: "Sngelica Ramos",
    number2: "Shief Executive Officer (CEO)",
    Amount: 100,
    status: "Complete",
  },

  {
    name: "Victoria Martin",
    number: "Public Relations Manager",
    name2: "Daniel Clark",
    number2: "Business Analyst",

    Amount: 100,
    status: "Open",
  },

  {
    name: "Sophie Adams",
    number: "Graphic Designer",
    name2: "Nathan Walker",
    number2: "IT Manager",
    Amount: "100",
    status: "Open",
  },

  {
    name: "Oliver Smith",
    number: "Operations Supervisor",
    name2: "Emma Johnson",
    number2: "Sales Representative",
    Amount: 100,
    status: "Complete",
  },

  {
    name: "William Martinez",
number: "Marketing Specialist",
name2: "Mia Turner",
number2: "Research Assistant",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Charlotte Rodriguez",
    number: "Project Coordinator",
    name2: "James Thompson",
    number2: "Software Engineer",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Harper Davis",
    number: "Business Development Coordinator",
    name2: "Alexander Anderson",
    number2: "Customer Service Manager",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Lily Wilson",
    number: "Marketing Assistant",
    name2: "Ethan Turner",
    number2: "Sales Executive",
    Amount: 100,
    status: "Complete",
  },

  {
    name: "Christopher Harris",
    number: "Operations Coordinator",
    name2: "Abigail Roberts",
    number2: "Human Resources Assistant",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Sofia Thompson",
    number: "Customer Success Specialist",
    name2: "Henry Davis",
    number2: "IT Technician",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Mila Anderson",
    number: "Product Analyst",
    name2: "Jack Mitchell",
    number2: "Finance Manager",
    Amount: 100,
    status: "Complete",
  },

  {
    name: "Evelyn Turner",
    number: "Public Relations Coordinator",
    name2: "Thomas Adams",
    number2: "Business Development Specialist",
    Amount: 100,
    status: "Complete",
  },
  {
    name: "Lucas Martinez",
    number: "Quality Assurance Analyst",
    name2: "Sophia Johnson",
    number2: "Operations Manager",
    Amount: 100,
    status: "Complete",
  },

];

export default departments;