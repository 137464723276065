import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
 import { AiOutlineSearch as SearchIcon } from 'react-icons/ai';
 import IndeterminateCheckbox from "./checkBox";



export default function SimpleListMenu({anchor, setAnchor}) {


  const open = Boolean(anchor);




  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <div>
     
      <Menu
        id="lock-menu"
        anchorEl={anchor}
        open={open}
        // onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: '24px', // Adjust the value as needed
          },
        }}
        anchorPosition={{ top: 50, left: 0 }}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'right',
        // }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

       
      >
      
      <div style={{  borderBottom: '1px solid #f0f0f0'}}>
       <div style={{padding:'7px ',borderRadius: '6px',display:'flex',margin:'8px',border:'1px solid #d9d9d9',justifyContent:'start',alignItems:'center',columnGap:'0px'}}>
      <SearchIcon style={{color:"rgba(0, 0, 0, 0.25)" ,margin: ' 0px 10px',alignItems:"center",fontSize:'20px'}} />
       <input className={'custom-input-diolog'} style={{border:'none',fontSize:'16px',outline:'none',width:"160px"}} type='text'placeholder='Search in filters'></input>
       </div>

      </div>
       <div style={{margin:'8px'}}>
       <IndeterminateCheckbox handleClose={handleClose} />
       </div>
  
     
      </Menu>
    </div>
  );
}




export  function SimpleListMenu2({anchor, setAnchor}) {
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const [childDisabledStatus, setChildDisabledStatus] = useState(true);

  const handleDisabledStatusChange = (disabled) => {
    setChildDisabledStatus(disabled);
  };
  const open = Boolean(anchor);




  const handleClose = () => {
    setAnchor(null);
  };

  return (
    <div>
      {/* <button onClick={handleClickListItem}> Click</button> */}
      <Menu
        id="lock-menu"
        anchorEl1={anchor}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            marginTop: '24px', // Adjust the value as needed
          },
        }}
        anchorPosition={{ top: 50, left: 0 }}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'right',
        // }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}

       
      >
      
      <div style={{  borderBottom: '1px solid #f0f0f0'}}>
       <div style={{padding:'7px ',borderRadius: '6px',display:'flex',margin:'8px',border:'1px solid #d9d9d9',justifyContent:'start',alignItems:'center',columnGap:'0px'}}>
      <SearchIcon style={{color:"rgba(0, 0, 0, 0.25)" ,margin: ' 0px 10px',alignItems:"center",fontSize:'20px'}} />
       <input className={'custom-input-diolog'} style={{border:'none',fontSize:'16px',outline:'none',width:"160px"}} type='text'placeholder='Search in filters'></input>
       </div>
        
      </div>
       <div style={{margin:'8px'}}>
       <IndeterminateCheckbox handleClose={handleClose} />
       </div>
    
  
     
      </Menu>


      
    </div>
  );
}

