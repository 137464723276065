import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
  return (
   <>
    <div className={"webview"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
   
      }}
    >
      <Box>
        <h2
          style={{
            fontSize: "30px",
            fontWeight: "600",
            color: "#1976D2",
            fontFamily: "Inter",
          }}
        >
          Loading
        </h2>

        <Box sx={{marginLeft:'23px', position: "relative", display: "inline-flex" }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>




    <div className={"mobilVied"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position:'fixed',
        margin:'auto',
        top:'46%',
        right:'36%'

   
      }}
    >
      <Box>
        <h2
          style={{
            fontSize: "30px",
            fontWeight: "600",
            color: "#1976D2",
            fontFamily: "Inter",
          }}
        >
          Loading
        </h2>

        <Box sx={{marginLeft:'23px', position: "relative", display: "inline-flex" }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
   </>
   
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 200);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
