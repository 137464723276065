
import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function CompanyDetails() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
    const data=[
      {
        title:'Net revenue',
        name:'Debt excluded net sales total',
        price:'2,0000',
        desc:'These figures here are based on net sales total',
        descShort:'excluded debt'

      },
      {
        title:'Net revenue',
        name:'Debt excluded net sales total',
        price:'2,0000',
        desc:'These figures here are based on net sales total',
        descShort:'excluded debt'

      },
    

    ]
    const Contact=[
      {
        title:'Contact Info ',
        CompanyName :"Let’s do business international BV",
        Address :"Let’s do business international BV",
        VATNo:"VAT No",
        CustomerId:"Customer Id",
        PhoneNo:'Phone No'

      } ,
        {
        title:'Details ',
        CompanyName :"Let’s do business international BV",
        Address :"Let’s do business international BV",
        Website :"Website ",
        Sector:'Sector',
        DumyData :'Dumy Data :'
        
      }
    ]
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{ width: "100%",backgroundColor:'#F5F5F5'}}>
      <Box
        sx={{
          backgroundColor:'#1975D2',
          position: "fixed",
          top: "13%",
          left: "auto",
          right: "0px",
          width: "100%",
          zIndex: "1203",
          // paddingTop:'0px'
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <Tab
            label={<span style={{color: "white"}}>Summary</span>}
            {...a11yProps(0)}
            sx={{
              color: value === 0 ? "white" : "white",
            }}
          />
          <Tab
            label={<span style={{color:'white'}}>Invoice</span>}
            {...a11yProps(1)}
            sx={{
              color: value === 1 ? "white" : "white",
            }}
          />
          <Tab
            label= {<span style={{color:'white'}}>Proposal</span>}
            {...a11yProps(2)}
            sx={{
              color: value === 2 ? "white" : "white",
            }}
          />
          <Tab
            label= {<span style={{color:'white'}}>Project</span>}
            {...a11yProps(2)}
            sx={{
              color: value === 2 ? "white" : "white",
            }}
          />  
          <Tab
            label= {<span style={{color:'white'}}>Tickets</span>}
            {...a11yProps(2)}
            sx={{
              color: value === 2 ? "white" : "white",
            }}
          />
        </Tabs>
      </Box>
      <TabPanel className={'scroolDemMobile'} style={{margin: "160px 0px",backgroundColor:'#F5F5F5' }}  value={value} index={0}>
       
          <div style={{columnGap:'10%',height:'67px',boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',margin:'10px 0px 25px 0px ',display:'flex',justifyContent:'start',alignItems:'center',padding:'15px 30px 27px 30px',backgroundColor:'white'}}>
            <img src="/assets/images/left.svg"></img>
            <h2 style={{fontWeight:'700',fontSize:'15px'}}>Lets do international Business BV</h2>
         </div>
         {data.map((item,index)=>(
          <div style={{backgroundColor:'#ffff',marginTop:'10px' ,marginBottom:'10px',padding:'10px 30px',textAlign:'left'}} key={index}>
          <p className={"dataStyle"}>{item.name}</p>
          <p className={"dataStyle"}>{item.title}</p>
          <h2 style={{fontWeight:'700',fontSize:'24px',margin:'0px'}}>{item.price}$</h2>
          <p className={"dataStyle"}>{item.desc} </p>
          <span className={"dataStyle"}>{item.descShort}</span>
        </div>
      
          ))}
          {Contact.map((item,index)=>(
            <div key={index} style={{backgroundColor:'#ffff',marginTop:'16px' ,marginBottom:'10px',padding:'10px 30px',textAlign:'left'}}>
            <h2 style={{fontWeight:'700',fontSize:'16px',margin:'0px'}}>{item.title}</h2>
            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
              <p className={"dataStyle"}>Company name : </p>
              <span className={"dataStyle"}> {item.CompanyName}</span>
            </div>

            <div style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            <p className={"dataStyle"}>Address: </p>
            <span className={"dataStyle"}>{item.Address}</span>
            </div>
        
         
            <p className={"dataStyle"}>{item.VATNo?(item.VATNo):(item.Website)}</p>
            <p className={"dataStyle"}>{item.CustomerId?(item.CustomerId):(item.Sector)}</p>
            <p className={"dataStyle"}>{item.PhoneNo?(item.PhoneNo):(item.DumyData)}</p>
            </div>
          ))}
           
       
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div style={{ margin: "20px 0px",textAlign: 'center' }}>Item Two</div>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <div style={{ margin: "20px 0px",textAlign: 'center' }}>Item Three</div>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div style={{ margin: "20px 0px",textAlign: 'center' }}>Item Four</div>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <div style={{ margin: "20px 0px",textAlign: 'center' }}>Item Five</div>
      </TabPanel>

    </Box>
  );
}
