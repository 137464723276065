import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";

import { MdOutlineCloudUpload } from "react-icons/md";
import {
  Box,
  Button,
} from "@mui/material";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

const templates = [
    {
      img: '/assets/images/tempImg.png',
      title: 'Breakfast',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Burger',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Camera',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Coffee',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Hats',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Honey',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Basketball',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Fern',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Mushrooms',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Tomato basil',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Sea star',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Bike',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Bike',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Bike',
    },
    {
      img: '/assets/images/tempImg.png',
      title: 'Bike',
    },
  ];
  
  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  

export default function TemplatesReminderTab({ handleDrawerClose,selectedTab }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 



  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };


 

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Paypal")}
            {...a11yProps(0)}
          />
        
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

        {/* for mobile */}

        <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: "4px solid white" ,
            }}
            label={formatLabel("Paypal")}
            {...a11yProps(0)}
          />
       
          
        </Tabs>

      
      </Box>

<div className={"mobilVied"}>
<p style={{paddingTop:"240px",marginBottom:"0px",fontSize:'16px',fontWeight:"600",paddingLeft:"28px"}}>Template</p>
   

   <div className={"Othertab boxThree"}  >
   <div style={{marginTop:"15px",marginBottom:"80px",display:"block",alignItems:'center'}} >
    {templates.map((item) => (
      <ImageListItem key={item.img}>
        <img  style={{marginBottom:"20px"}}
          src={item.img} alt=""

        />
      </ImageListItem>
    ))}
      

    </div>
   </div>
</div>
<div className={"webview"}>
<p style={{marginTop:"30px",marginBottom:"0px",fontSize:'16px',fontWeight:"600",paddingLeft:"28px"}}>Template</p>
   

   <div className={"Othertab boxThree"}  >
   <div style={{marginTop:"15px",marginBottom:"80px",display:"grid",gridTemplateColumns:"19% 19% 19% 19% 19%",justifyContent:'start',alignItems:'center',columnGap:'10px'}} >
    {templates.map((item) => (
      <ImageListItem key={item.img}>
        <img  style={{marginBottom:"20px"}}
          src={item.img} alt=""

        />
      </ImageListItem>
    ))}
      

    </div>
   </div>
</div>
    
     
     
      
     

      
   
    </Box>
  );
}
