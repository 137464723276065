import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import {
  Box,
  Button,
  TextField,

} from "@mui/material";

import FormControl from "@mui/material/FormControl";

import FormControlLabel from '@mui/material/FormControlLabel';

import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';


const invoiceTexRates = [
    { label: "Off" },
    { label: "On" },
  
  ];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function WorkflowSettingTab({ handleDrawerClose ,selectedTab }) {

  
  const [state, setState] = React.useState({

    jason: false,
    antoine: true,
    
    AutoArchivePaid: false,
    AutoArchiveCancelled: false,
  });

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };



  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Setting")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Custom Labels")}
            {...a11yProps(1)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

          {/* for mobile */}

          <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Setting")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Custom labels")}
            {...a11yProps(1)}
          />
          
        </Tabs>

      
      </Box>

    <div className={"mobilVied"}>
    <TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "245px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
          
            <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/emailLOgo.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Auto Email
         </Typography>
       </div>
     }
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Automatically email recurring invoices when they are created</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label={
         <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
           <img src="/assets/images/sss.svg" alt="Images" />
           <Typography variant="body1" component="span">
           Stop on unpaid
           </Typography>
         </div>
       }
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Stop creating recurring invoices if the last invoice is unpaid.</FormHelperText>
  

            </FormControl>
         
            
          </Box>
        </div>




        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
          
            <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/ddd.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Auto Archive Paid
         </Typography>
       </div>
     }
       control={<Switch checked={state.AutoArchivePaid} onChange={handleChangebutton} name="AutoArchivePaid" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Auto Archive Paid</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label={
         <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
           <img src="/assets/images/ddd.svg" alt="Images" />
           <Typography variant="body1" component="span">
           Auto Archive Cancelled
           </Typography>
         </div>
       }
       control={<Switch checked={state.AutoArchiveCancelled} onChange={handleChangebutton} name="AutoArchiveCancelled" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Automatically archive invoices when they are cancelled</FormHelperText>
  

            </FormControl>
         
            
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "30px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom: "300px",
          }}
        >   
           <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={invoiceTexRates}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Look invoices" variant="standard" />
            )}
          />

        </div>

      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={1}>
        Item Two
      </TabPanel>
    </div>




    <div className={"webview"}>
    <TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
          
            <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/emailLOgo.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Auto Email
         </Typography>
       </div>
     }
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Automatically email recurring invoices when they are created</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label={
         <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
           <img src="/assets/images/sss.svg" alt="Images" />
           <Typography variant="body1" component="span">
           Stop on unpaid
           </Typography>
         </div>
       }
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Stop creating recurring invoices if the last invoice is unpaid.</FormHelperText>
  

            </FormControl>
         
            
          </Box>
        </div>




        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
          
            <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ={
       <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
         <img src="/assets/images/ddd.svg" alt="Imagee" />
         <Typography variant="body1" component="span">
         Auto Archive Paid
         </Typography>
       </div>
     }
       control={<Switch checked={state.AutoArchivePaid} onChange={handleChangebutton} name="AutoArchivePaid" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Auto Archive Paid</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label={
         <div style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'15px'}}>
           <img src="/assets/images/ddd.svg" alt="Images" />
           <Typography variant="body1" component="span">
           Auto Archive Cancelled
           </Typography>
         </div>
       }
       control={<Switch checked={state.AutoArchiveCancelled} onChange={handleChangebutton} name="AutoArchiveCancelled" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"32px",margin:'-5px'}}>Automatically archive invoices when they are cancelled</FormHelperText>
  

            </FormControl>
         
            
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "30px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom: "300px",
          }}
        >   
           <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={invoiceTexRates}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Look invoices" variant="standard" />
            )}
          />

        </div>

      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={1}>
        Item Two
      </TabPanel>
    </div>

     
    </Box>
  );
}
