import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import { Box, Button, TextField } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

const invoiceTexRates = [
  { label: "Free" },
  { label: "Paid" },
  { label: "Extraordinary" },
  { label: "Irregular" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DeviceSettingTab({ handleDrawerClose,selectedTab }) {
  const [state, setState] = React.useState({
    ShowPDFPreview: true,
    EnablePDFMarkdown: false,
    EnableEmailMarkdown: true,

    IncludeDrafts: false,
    IncludeDeleted: false,
  });

  // const [valueRedio, setvalueRedio] = React.useState('Bottom');

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs 
           value={value}
           onChange={handleChange}
           variant="scrollable"
           scrollButtons="auto"
           aria-label="scrollable auto tabs example"
         
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Overview")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Enabled Modules")}
            {...a11yProps(1)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 2 ? "#00A1E4" : "white",
              color: value === 2 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Integrations")}
            {...a11yProps(2)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 3 ? "#00A1E4" : "white",
              color: value === 3 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Security Settings")}
            {...a11yProps(3)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

        {/* for mobile */}

  <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs

          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Overview")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Enabled modules")}
            {...a11yProps(1)}
          />

<Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 2 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Integrations")}
            {...a11yProps(2)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 3 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Security settings")}
            {...a11yProps(3)}
          />




        </Tabs>

      
      </Box>
      <div className={"mobilVied"}>
      <TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "245px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Plan" variant="standard" />
              )}
            />
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Activate Company"
                control={
                  <Switch
                    checked={state.ShowPDFPreview}
                    onChange={handleChangebutton}
                    name="ShowPDFPreview"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Enable emails, recurring invoices, webhooks and notifications
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Enable PDF Markdown"
                control={
                  <Switch
                    checked={state.EnablePDFMarkdown}
                    onChange={handleChangebutton}
                    name="EnablePDFMarkdown"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Convert markdown to HTML on the PDF
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Enable Email Markdown"
                control={
                  <Switch
                    checked={state.EnableEmailMarkdown}
                    onChange={handleChangebutton}
                    name="EnableEmailMarkdown"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Use visual WYSIWYG markdown editor for emails{" "}
              </FormHelperText>
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Include Drafts "
                control={
                  <Switch
                    checked={state.IncludeDrafts}
                    onChange={handleChangebutton}
                    name="IncludeDrafts"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Include draft records in reports
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Include Deleted"
                control={
                  <Switch
                    checked={state.IncludeDeleted}
                    onChange={handleChangebutton}
                    name="IncludeDeleted"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Include deleted records in reports
              </FormHelperText>
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "0px 10px 25px 15px",

            padding: "15px 10px",
            marginBottom: "100px",
            display: "grid",
            gridTemplateColumns: "45% 45%",
            columnGap: "10%",
          }}
        >
          <div style={{ width: "100%" }}>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/down.svg" alt=""></img>PURCHASE LICENSE
              </span>
            </button>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/key.svg" alt=""></img>API TOKENS
              </span>
            </button>

            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/API.svg" alt=""></img>API DOCS
              </span>
            </button>
          </div>
          <div style={{ width: "100%" }}>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/down.svg" alt=""></img>PURCHASE LICENSE
              </span>
            </button>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/key.svg" alt=""></img>API TOKENS
              </span>
            </button>

            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/API.svg" alt=""></img>API DOCS
              </span>
            </button>
          </div>
        </div>
      </TabPanel>
      <TabPanel className={"Othertab "} value={value} index={1}>
        Item Two
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
      </div>
      <div className={"webview"}>
      <TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField {...params} label="Plan" variant="standard" />
              )}
            />
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Activate Company"
                control={
                  <Switch
                    checked={state.ShowPDFPreview}
                    onChange={handleChangebutton}
                    name="ShowPDFPreview"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Enable emails, recurring invoices, webhooks and notifications
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Enable PDF Markdown"
                control={
                  <Switch
                    checked={state.EnablePDFMarkdown}
                    onChange={handleChangebutton}
                    name="EnablePDFMarkdown"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Convert markdown to HTML on the PDF
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Enable Email Markdown"
                control={
                  <Switch
                    checked={state.EnableEmailMarkdown}
                    onChange={handleChangebutton}
                    name="EnableEmailMarkdown"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Use visual WYSIWYG markdown editor for emails{" "}
              </FormHelperText>
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl component="fieldset" variant="standard">
              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Include Drafts "
                control={
                  <Switch
                    checked={state.IncludeDrafts}
                    onChange={handleChangebutton}
                    name="IncludeDrafts"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Include draft records in reports
              </FormHelperText>

              <FormControlLabel
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingRight: "10px",
                  margin: "0px",
                }}
                sx={{ m: 1, width: "100%" }}
                label="Include Deleted"
                control={
                  <Switch
                    checked={state.IncludeDeleted}
                    onChange={handleChangebutton}
                    name="IncludeDeleted"
                  />
                }
                labelPlacement="start"
              />
              <FormHelperText
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  paddingLeft: "5px",
                  margin: "-5px",
                }}
              >
                Include deleted records in reports
              </FormHelperText>
            </FormControl>
          </Box>
        </div>

        <div
          style={{
            overflow: "hidden",
            margin: "0px 10px 25px 15px",

            padding: "15px 10px",
            marginBottom: "100px",
            display: "grid",
            gridTemplateColumns: "45% 45%",
            columnGap: "10%",
          }}
        >
          <div style={{ width: "100%" }}>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/down.svg" alt=""></img>PURCHASE LICENSE
              </span>
            </button>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/key.svg" alt=""></img>API TOKENS
              </span>
            </button>

            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/API.svg" alt=""></img>API DOCS
              </span>
            </button>
          </div>
          <div style={{ width: "100%" }}>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/down.svg" alt=""></img>PURCHASE LICENSE
              </span>
            </button>
            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/key.svg" alt=""></img>API TOKENS
              </span>
            </button>

            <button
              style={{
                margin: "24px 0px",
                border: "none",
                height: "43px",
                backgroundColor: "#00A1E4",
                color: "white",
                width: "100%",
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  alignItems: "center",
                  display: "flex",
                  columnGap: "10px",
                }}
              >
                <img src="/assets/images/API.svg" alt=""></img>API DOCS
              </span>
            </button>
          </div>
        </div>
      </TabPanel>
      <TabPanel className={"Othertab "} value={value} index={1}>
        Item Two
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
      </div>
     
    </Box>
  );
}
