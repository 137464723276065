import React, { useState } from "react";
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';


import { MdOutlineCancel } from "react-icons/md";

import { MdOutlineCloudUpload } from "react-icons/md";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import {
  Box,
  Button,
 
} from "@mui/material";










export default function ProductSettingTab({ handleDrawerClose ,selectedTab}) {
    const [valueRedio, setvalueRedio] = React.useState('Client');
    const handleChangeRadio = (event) => {
      setvalueRedio(event.target.value);
    };
    const [state, setState] = React.useState({
       
        trackInventory:false,
        stockNotifications:true,
        jason: false,
        antoine: false,
         overPayment:true, 
        underPayment :true,



        onlinePaymentEmail:true,
        manualPaymentEmail:true,
        markPaidPaymentEmail:false ,
        convertProducts:false 

      });
    
      const handleChangebutton = (event) => {
        setState({
          ...state,
          [event.target.name]: event.target.checked,
        });
      };

  //  inputFild with cross button

 



  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box  className={"webview"}
        style={{ 
          display: 'flex',
          justifyContent:'end',
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
     

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>
 {/* for mobile */}

 <Box
        className={"mobilVied"}
        style={{
          backgroundColor: "#1975D2",

          height: "56px",
          paddingBottom: "47px",
          paddingTop: "119px",
          position: "fixed",
          width: "100%",
          zIndex: "2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 20px 0px 20px",
          }}
        >
          <Button onClick={handleDrawerClose}>
            <img
              style={{ width: "14.98px", height: "14.66px" }}
              src="/assets/images/leftl.png"
              alt=""
            />

            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              {selectedTab}
            </span>
          </Button>
          <Button>
            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              Save
            </span>
          </Button>
        </div>
      </Box>


      <div
        style={{}}
        className={"Othertab boxTwo mobilVied"}
     
      >
      <div
          style={{
            overflow: "hidden",
            margin: "195px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Track Inventory"
       control={<Switch checked={state.trackInventory} onChange={handleChangebutton} name="trackInventory" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a product stock field and update when inovices are sent</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Stock Notifications"
       control={<Switch checked={state.stockNotifications} onChange={handleChangebutton} name="stockNotifications" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when the stock reaches the threshold </FormHelperText>
  
 </FormControl>

           

          
            
          </Box>
        </div>
       
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Show Product Discount"
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a line item discount field</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Show Product Cost"
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a product cost field to track the markup/profit </FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Show Product Quantity"
       control={<Switch checked={state.overPayment} onChange={handleChangebutton} name="overPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a product quantity field, otherwise default</FormHelperText>
  



      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Default Quantity"
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Automatically set the line quantity to one</FormHelperText>
  


 </FormControl>

           

          
            
          </Box>
        </div>


        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"200px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Show Product Description"
       control={<Switch checked={state.onlinePaymentEmail} onChange={handleChangebutton} name="onlinePaymentEmail" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Include the description in the product dropdown </FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Fill Products"
       control={<Switch checked={state.manualPaymentEmail} onChange={handleChangebutton} name="manualPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Selecting a product will automatically fill in the description and cost</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Update Products"
       control={<Switch checked={state.markPaidPaymentEmail} onChange={handleChangebutton} name="markPaidPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Updating an invoice will automatically update the product library</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Convert Products"
       control={<Switch checked={state.convertProducts} onChange={handleChangebutton} name="convertProducts" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Automatically convert product prics using current exchange rate</FormHelperText>
  

     


 </FormControl>



 <div>

<FormControl>
<FormLabel id="demo-controlled-radio-buttons-group" style={{fontSize:'12px',fontWeight:"400"}}>Convert To</FormLabel>
<RadioGroup 
 aria-labelledby="demo-controlled-radio-buttons-group"
 name="controlled-radio-buttons-group"
 value={valueRedio}
 onChange={handleChangeRadio}
>
  <span style={{display:'block',justifyContent:'start'}}>
  <FormControlLabel value="Company" control={<Radio />} label="Company Currency" />
 <FormControlLabel value="Client" control={<Radio />} label="Client Currency" />
  </span>
 
</RadioGroup>
</FormControl>

</div>

           

          
            
          </Box>
        </div>

        
      </div>


      <div
        style={{}}
        className={"Othertab boxTwo webview"}
     
      >
      <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Track Inventory"
       control={<Switch checked={state.trackInventory} onChange={handleChangebutton} name="trackInventory" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a product stock field and update when inovices are sent</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Stock Notifications"
       control={<Switch checked={state.stockNotifications} onChange={handleChangebutton} name="stockNotifications" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when the stock reaches the threshold </FormHelperText>
  
 </FormControl>

           

          
            
          </Box>
        </div>
       
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Show Product Discount"
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a line item discount field</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Show Product Cost"
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a product cost field to track the markup/profit </FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Show Product Quantity"
       control={<Switch checked={state.overPayment} onChange={handleChangebutton} name="overPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Display a product quantity field, otherwise default</FormHelperText>
  



      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Default Quantity"
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Automatically set the line quantity to one</FormHelperText>
  


 </FormControl>

           

          
            
          </Box>
        </div>


        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"200px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Show Product Description"
       control={<Switch checked={state.onlinePaymentEmail} onChange={handleChangebutton} name="onlinePaymentEmail" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Include the description in the product dropdown </FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Fill Products"
       control={<Switch checked={state.manualPaymentEmail} onChange={handleChangebutton} name="manualPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Selecting a product will automatically fill in the description and cost</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Update Products"
       control={<Switch checked={state.markPaidPaymentEmail} onChange={handleChangebutton} name="markPaidPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Updating an invoice will automatically update the product library</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Convert Products"
       control={<Switch checked={state.convertProducts} onChange={handleChangebutton} name="convertProducts" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Automatically convert product prics using current exchange rate</FormHelperText>
  

     


 </FormControl>



 <div>

<FormControl>
<FormLabel id="demo-controlled-radio-buttons-group" style={{fontSize:'12px',fontWeight:"400"}}>Convert To</FormLabel>
<RadioGroup 
 aria-labelledby="demo-controlled-radio-buttons-group"
 name="controlled-radio-buttons-group"
 value={valueRedio}
 onChange={handleChangeRadio}
>
  <span style={{display:'flex',justifyContent:'start',alignItems:'center',columnGap:'20px'}}>
  <FormControlLabel value="Company" control={<Radio />} label="Company Currency" />
 <FormControlLabel value="Client" control={<Radio />} label="Client Currency" />
  </span>
 
</RadioGroup>
</FormControl>

</div>

           

          
            
          </Box>
        </div>

        
      </div>
      
    </Box>
  );
}
