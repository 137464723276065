import React from "react";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { MenuItem, Table, IconButton, Menu } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import InvoiceInfo from "../../src/_mockData/InvoiceInfo";
import SimpleListMenu from "./create-invoice-dialog";
import {SimpleListMenu2} from "./create-invoice-dialog";
import { BsThreeDotsVertical } from "react-icons/bs";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { makeStyles } from "@material-ui/core/styles";

export default function InvoicesComponent() {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    selected: {
      color: "red", // Change the color to your desired color
      border: "1px solid red", // Add the desired border styles
    },
  }));

  // dte formate

 
  const formatDate = (InvoiceInfo) => {
    const dateParts = InvoiceInfo.Date.split("/");
    const day = dateParts[0];
    const month = dateParts[1];
    const year = dateParts[2];
    const formattedDate = new Date(` ${day},${month}, ${year}`);
    return formattedDate.toDateString(); // You can format the date however you prefer
  };
 







  const [anchorEl4, setAnchorEl4] = React.useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const openIcon = Boolean(anchorEl);
  // tooltip
  const [Tooltipvalues, setTooltipValuee] = useState("Click to sort ascending");

  const [Tooltipvalues1, setTooltipValuee1] = useState(
    "Click to sort ascending"
  );
  const [Tooltipvalues5, setTooltipValuee5] = useState(
    "Click to sort ascending"
  );
  const [orderBy, setOrderBy] = useState("");

  const [Tooltipvalues2, setTooltipValuee2] = useState(
    "Click to sort ascending"
  );
  const [orderBy2, setOrderBy2] = useState("");
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [orderBy1, setOrderBy1] = useState("");
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [orderBy5, setOrderBy5] = useState("");
  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [ColorIcon, setColorIcon] = useState("#A49E9E");
  const [downColorIcon, downsetColorIcon] = useState("#A49E9E");
  const [bgColorCellTD, setBgColorCellTD] = useState("white");
  const [bgClass, setBgClass] = useState("white");

  const [downColorIcon2, downsetColorIcon2] = useState("#A49E9E");
  const [ColorIcon2, setColorIcon2] = useState("#A49E9E");
  const [bgColorCellTD2, setBgColorCellTD2] = useState("white");
  const [bgClass2, setBgClass2] = useState("white");

  const [downColorIcon1, downsetColorIcon1] = useState("#A49E9E");
  const [ColorIcon1, setColorIcon1] = useState("#A49E9E");
  const [bgColorCellTD1, setBgColorCellTD1] = useState("white");
  const [bgClass1, setBgClass1] = useState("white");

  const [downColorIcon5, downsetColorIcon5] = useState("#A49E9E");
  const [ColorIcon5, setColorIcon5] = useState("#A49E9E");
  const [bgColorCellTD5, setBgColorCellTD5] = useState("white");
  const [bgClass5, setBgClass5] = useState("white");
  // pagination
  const classesPagination = useStyles();

  const [InvoiceInfoData, setData] = useState(InvoiceInfo);
  const toggleButtons1 = () => {
    if (orderBy1 === "") {
      setBgClass1("testClass1");
      setOrderBy1("asc");
      setTooltipValuee1("Click to sort descending");
      const sortedArr = [...InvoiceInfoData].sort((a, b) =>
        a.Name.localeCompare(b.Name)
      );
      setData(sortedArr);

      setColorIcon1("#00A1E4");
      downsetColorIcon1("#A49E9E");
      setBgColorCellTD1("snow");

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");

      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy2("");

      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");

      setBgColorCellTD5("white");
    } else if (orderBy1 === "asc") {
      setBgClass1("testClass1");
      setOrderBy1("dsc");

      setColorIcon1("#A49E9E");
      downsetColorIcon1("#00A1E4");
      setBgColorCellTD1("snow");
      setTooltipValuee1("Click to cancel sorting");
      const sortedArr = [...InvoiceInfoData].sort((a, b) =>
        b.Name.localeCompare(a.Name)
      );
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");

      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy2("");

      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");

      setBgColorCellTD5("white");
    } else if (orderBy1 === "dsc") {
      setBgClass1("testClass2");
      setOrderBy1("");

      setColorIcon1("#A49E9E");
      downsetColorIcon1("#A49E9E");
      setBgColorCellTD1("white");
      setTooltipValuee1("Click to sort ascending");

      setData([...InvoiceInfo]);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");

      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy2("");

      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");

      setBgColorCellTD5("white");
    }
  };

  const toggleButtonsanouther = () => {
    if (orderBy2 === "") {
      setBgClass2("testClass1");
      setOrderBy2("asc");
      setTooltipValuee2("Click to sort descending");

      setColorIcon2("#00A1E4");
      downsetColorIcon2("#A49E9E");
      setBgColorCellTD2("snow");

   

      const sortedArr = [...InvoiceInfoData].sort((a, b) =>{
        const dateA = new Date(formatDate(a));
        const dateB = new Date(formatDate(b));
        return dateA - dateB;
      }
     
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");

      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");

      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");

      setBgColorCellTD5("white");
    } else if (orderBy2 === "asc") {
      setBgClass2("testClass1");
      setOrderBy2("dsc");

      setColorIcon2("#A49E9E");
      downsetColorIcon2("#00A1E4");
      setBgColorCellTD2("snow");
      setTooltipValuee2("Click to cancel sorting");


    

      const sortedArr = [...InvoiceInfoData].sort((a, b) =>{
      const dateA = new Date(formatDate(a));
      const dateB = new Date(formatDate(b));
      return dateB - dateA;
    });
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");

      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");

      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");

      setBgColorCellTD5("white");
    } else if (orderBy2 === "dsc") {
      setBgClass2("testClass2");
      setOrderBy2("");
      setColorIcon2("#A49E9E");
      downsetColorIcon2("#A49E9E");
      setBgColorCellTD2("white");
      setTooltipValuee2("Click to sort ascending");
      setData([...InvoiceInfoData]);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");

      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");

      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");

      setBgColorCellTD5("white");
    }
  };

  const toggleButtons = () => {
    if (orderBy === "") {
      setBgClass("testClass1");
      setOrderBy("asc");
      setTooltipValuee("Click to sort descending");

      setColorIcon("#00A1E4");
      downsetColorIcon("#A49E9E");
      setBgColorCellTD("snow");

      const sortedArr = [...InvoiceInfoData].sort((a, b) =>a.id- b.id);
      setData(sortedArr);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");

      setBgClass5("testClass2");

      setOrderBy1("");
      setOrderBy2("");

      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");

      setBgColorCellTD5("white");
    } else if (orderBy === "asc") {
      setBgClass("testClass1");
      setOrderBy("dsc");

      setColorIcon("#A49E9E");
      downsetColorIcon("#00A1E4");
      setBgColorCellTD("snow");
      setTooltipValuee("Click to cancel sorting");
      const sortedArr = [...InvoiceInfoData].sort((a, b) =>
      b.id- a.id
      );
      setData(sortedArr);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");

      setBgClass5("testClass2");

      setOrderBy1("");
      setOrderBy2("");

      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");

      setBgColorCellTD5("white");
    } else if (orderBy === "dsc") {
      setBgClass("testClass2");
      setOrderBy("");

      setColorIcon("#A49E9E");
      downsetColorIcon("#A49E9E");
      setBgColorCellTD("white");
      setTooltipValuee("Click to sort ascending");
      setData([...InvoiceInfo]);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");

      setBgClass5("testClass2");

      setOrderBy1("");
      setOrderBy2("");

      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon5("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon5("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");

      setBgColorCellTD5("white");
    }
  };

  const toggleButtons5 = () => {
    if (orderBy5 === "") {
      setBgClass5("testClass1");
      setOrderBy5("asc");
      setTooltipValuee5("Click to sort descending");

      setColorIcon5("#00A1E4");
      downsetColorIcon5("#A49E9E");
      setBgColorCellTD5("snow");

      const sortedArr = [...InvoiceInfoData].sort(
        (a, b) => a.Amount - b.Amount
      );

      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee1("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");

      setBgClass1("testClass2");

      setOrderBy("");
      setOrderBy2("");

      setOrderBy1("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon1("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon1("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");

      setBgColorCellTD1("white");
    } else if (orderBy5 === "asc") {
      setBgClass5("testClass1");
      setOrderBy5("dsc");

      setColorIcon5("#A49E9E");
      downsetColorIcon5("#00A1E4");
      setBgColorCellTD5("snow");
      setTooltipValuee5("Click to cancel sorting");

      const sortedArr = [...InvoiceInfoData].sort(
        (a, b) => b.Amount - a.Amount
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee1("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");

      setBgClass1("testClass2");

      setOrderBy("");
      setOrderBy2("");

      setOrderBy1("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon1("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon1("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");

      setBgColorCellTD1("white");
    } else if (orderBy5 === "dsc") {
      setBgClass5("testClass2");
      setOrderBy5("");

      setColorIcon5("#A49E9E");
      downsetColorIcon5("#A49E9E");
      setBgColorCellTD5("white");
      setTooltipValuee5("Click to sort ascending");
      setData([...InvoiceInfo]);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");

      setTooltipValuee1("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");

      setBgClass1("testClass2");

      setOrderBy("");
      setOrderBy2("");

      setOrderBy1("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");

      setColorIcon1("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");

      downsetColorIcon1("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");

      setBgColorCellTD1("white");
    }
  };

  const handleClick = (event) => {
    // work

    setSelectedRow(null);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <TableContainer
        component={Paper}
        // sx={{
        //   my: 1,
        //   scrollbarWidth: "thin",
        //   "&::-webkit-scrollbar": {
        //     width: "0.4em",
        //     display: "block",
        //   },
        //   "&::-webkit-scrollbar-track": {
        //     background: "#f1f1f1",
        //   },
        //   "&::-webkit-scrollbar-thumb": {
        //     backgroundColor: "#888",
        //   },
        //   "&::-webkit-scrollbar-thumb:hover": {
        //     background: "#555",
        //   },
        // }}
        style={{
          display: "flex",
          justifyContent: "center",
          overflowX:'hidden'
       

        }}
      >
        <Table aria-label="simple table">
          <TableHead style={{ borderTop: "1px solid #f0f0f0", }}>
            <TableRow className={"TR"}>
              <TableCell
                style={{ color: "#9F9999" ,padding:'0px',height:'27px'}}
                width={10}
                // height={60}
                align="left"
              ></TableCell>

             <Tooltip title={Tooltipvalues} arrow placement="top">
                <TableCell
                  className={`custom-button ${bgClass}`}
                  style={{
                    padding: "0px",
                    height: "100%",
                  }}
                  width={70}
                  align="left"
                >
                  <div
                    style={{
                      paddingLeft: "10px",
                      borderLeft: "1px solid #e5e7eb",
                      borderRight: "1px solid #e5e7eb",
                      margin: "5px 0px 5px 0px",
                      display: "flex",
                    
                      cursor: "pointer",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      onClick={toggleButtons}
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                     Number
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <span
                        onClick={toggleButtons}
                        style={{
                          paddingRight: "3px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          
                        }}
                      >
                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: ColorIcon }}
                            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                          ></path>
                        </svg>

                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: downColorIcon }}
                            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                          ></path>
                        </svg>
                      </span>
                      <svg
                        onClick={(event) => setAnchorEl4(event.currentTarget)}
                        width="100"
                        height="100"
                        viewBox="0 0 1000 1000"
                        style={{
                          height: "15px",
                          width: "15px",
                          alignItems: "end",
                          marginRight: "10px",
                        }}
                      >
                        <path
                          style={{ fill: "#7C7C7C" }}
                          d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <SimpleListMenu
                    anchor={anchorEl4}
                    setAnchor={setAnchorEl4}
                  />
                </TableCell>
              </Tooltip>

              <Tooltip title={Tooltipvalues1} arrow placement="top">
                <TableCell
                  className={`custom-button ${bgClass1}`}
                  style={{
                    padding: "0px",
                    height: "100%",
                  }}
                  width={90}
                  align="left"
                >
                  <div
                    style={{
                      paddingLeft: "10px",
                      borderLeft: "1px solid #e5e7eb",
                      borderRight: "1px solid #e5e7eb",
                      margin: "5px 0px 5px 0px",
                      display: "flex",
                    
                      cursor: "pointer",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      onClick={toggleButtons1}
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      Name
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <span
                        onClick={toggleButtons1}
                        style={{
                          paddingRight: "3px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                         
                        }}
                      >
                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: ColorIcon1 }}
                            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                          ></path>
                        </svg>

                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: downColorIcon1 }}
                            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                          ></path>
                        </svg>
                      </span>
                      <svg
                        onClick={(event) => setAnchorEl1(event.currentTarget)}
                        width="100"
                        height="100"
                        viewBox="0 0 1000 1000"
                        style={{
                          height: "15px",
                          width: "15px",
                          alignItems: "end",
                          marginRight: "10px",
                        }}
                      >
                        <path
                          style={{ fill: "#7C7C7C" }}
                          d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <SimpleListMenu
                    anchor={anchorEl1}
                    setAnchor={setAnchorEl1}
                  />
                </TableCell>
              </Tooltip>


              <Tooltip title={Tooltipvalues2} arrow placement="top">
                <TableCell
                  className={`custom-button ${bgClass2}`}
                  style={{
                    padding: "0px",
                    height: "100%",
                  }}
                  width={100}
                  align="left"
                >
                  <div
                    style={{
                      paddingLeft: "10px",
                      borderLeft: "1px solid #e5e7eb",
                      borderRight: "1px solid #e5e7eb",
                      margin: "5px 0px 5px 0px",
                      display: "flex",
                 
                      cursor: "pointer",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      onClick={toggleButtonsanouther}
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      Date
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <span
                        onClick={toggleButtonsanouther}
                        style={{
                          paddingRight: "3px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                         
                        }}
                      >
                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: ColorIcon2 }}
                            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                          ></path>
                        </svg>

                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: downColorIcon2 }}
                            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                          ></path>
                        </svg>
                      </span>
                      <svg
                        onClick={(event) => setAnchorEl2(event.currentTarget)}
                        width="100"
                        height="100"
                        viewBox="0 0 1000 1000"
                        style={{
                          height: "15px",
                          width: "15px",
                          alignItems: "end",
                          marginRight: "10px",
                        }}
                      >
                        <path
                          style={{ fill: "#7C7C7C" }}
                          d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <SimpleListMenu anchor={anchorEl2} setAnchor={setAnchorEl2} />
                </TableCell>
              </Tooltip>

              <Tooltip title={Tooltipvalues5} arrow placement="top">
                <TableCell
                  className={`custom-button ${bgClass5}`}
                  style={{
                    padding: "0px",
                    height: "100%",

                  }}
                  width={100}
                  align="left"
                >
                  <div
                    style={{
                      paddingLeft: "10px",
                      borderLeft: "1px solid #e5e7eb",
                      borderRight: "1px solid #e5e7eb",
                      margin: "5px 0px 5px 0px",
                      display: "flex",
                      cursor: "pointer",
                     
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <span
                      onClick={toggleButtons5}
                      style={{ fontSize: "13px", fontWeight: "600" }}
                    >
                      Amount
                    </span>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "50% 50%",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <span
                        onClick={toggleButtons5}
                        style={{
                          paddingRight: "10px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "3px",
                        }}
                      >
                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: ColorIcon5 }}
                            d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                          ></path>
                        </svg>

                        <svg
                          width="100"
                          height="100"
                          viewBox="0 0 1000 1000"
                          style={{ height: "10px", width: "10px" }}
                        >
                          <path
                            style={{ fill: downColorIcon5 }}
                            d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                          ></path>
                        </svg>
                      </span>
                      <svg
                        onClick={(event) => setAnchorEl5(event.currentTarget)}
                        width="100"
                        height="100"
                        viewBox="0 0 1000 1000"
                        style={{
                          height: "15px",
                          width: "15px",
                          alignItems: "end",
                          marginRight: "10px",
                        }}
                      >
                        <path
                          style={{ fill: "#7C7C7C" }}
                          d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                        ></path>
                      </svg>
                    </div>
                  </div>

                  <SimpleListMenu anchor={anchorEl5} setAnchor={setAnchorEl5} />
                </TableCell>
              </Tooltip>

              <TableCell
                style={{
                  color: "black",
                  paddingRight: "65px",
                  fontSize: "13px",
                  fontWeight: "600",
                  paddingLeft: "10px",
                }}
                width={100}
                align="left"
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody
            style={{
              overflowY: "hidden",
              overflowX:'hidden'
              
            }}
          >
            {InvoiceInfoData.map((item, index) => (
              <TableRow className={"TDRow"}
                key={index}
                style={{
                  backgroundColor:
                    selectedRow === index ? "Gainsboro" : "white",
                }}
                hover
            
              >
                <TableCell align="left" style={{ padding: "5px" }}>
                  <IconButton
                    style={{ fontSize: "15px", fontWeight: "2000" }}
                    id="basic-button"
                    aria-controls={openIcon ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openIcon ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <BsThreeDotsVertical  />
                  </IconButton>
                </TableCell>

                <TableCell
                  style={{
                    alignSelf: "left",
                    padding: "0px",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "10px",
                    backgroundColor:
                      selectedRow === index
                        ? "Gainsboro"
                        : bgColorCellTD === "snow"
                        ? bgColorCellTD
                        : "white",
                  }}
                  width={70}
                  align="left"
                  key={index}
                >
                  {item?.id}
                </TableCell>

                <TableCell
                  style={{
                    alignSelf: "left",
                    padding: "0px",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "10px",
                    backgroundColor:
                      selectedRow === index
                        ? "Gainsboro"
                        : bgColorCellTD1 === "snow"
                        ? bgColorCellTD1
                        : "white",
                  }}
                  width={90}
                  align="left"
                  key={index}
                >
                  {item?.Name}
                </TableCell>


                <TableCell
                  style={{
                    alignSelf: "left",
                    padding: "0px",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "10px",
                    backgroundColor:
                      selectedRow === index
                        ? "Gainsboro"
                        : bgColorCellTD2 === "snow"
                        ? bgColorCellTD2
                        : "white",
                  }}
                  width={100}
                  align="left"
                  key={index}
                >
                  {item?.Date}
                </TableCell>
              
                <TableCell
                  width={100}
                  align="left"
                  style={{
                    padding: "0px",
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "10px",
                    backgroundColor:
                      selectedRow === index
                        ? "Gainsboro"
                        : bgColorCellTD5 === "snow"
                        ? bgColorCellTD5
                        : "white",
                  }}
                >
                  {item?.Amount} USD
                </TableCell>

                <TableCell
                  style={{ paddingLeft: "10px" }}
                  width={100}
                  align="left"
                >
                  {/* {row.protein} */}
                  {item.Amount === "20.00" ? (
                    <Chip
                      label="Sent"
                      style={{
                        background: "#00A1E4",
                        width: "85px",
                        height: "26px",
                        borderRadius: "5px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                        // marginLeft: "6px",
                      }}
                    />
                  ) : item.Amount === "15.00" ? (
                    <Chip
                      label="Paid"
                      style={{
                        background: "#4D9A19",
                        width: "85px",
                        height: "26px",
                        borderRadius: "5px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                        // marginLeft: "8px",
                      }}
                    />
                  ) : item.Amount === "10.00" ? (
                    <Chip
                      label="Draft"
                      style={{
                        background: "#888888",
                        width: "85px",
                        height: "26px",
                        borderRadius: "5px",
                        color: "white",
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openIcon}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/pdf_icon.svg"
            alt="Close"
          />{" "}
          View Statement
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/client.svg"
            alt="Close"
          />
          Client Portal
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
            borderBottom: "1 solid #e5e7eb!important",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/sett.svg"
            alt="Close"
          />
          Settings
        </MenuItem>
        <hr color="#e5e7eb" width="80%" />

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/plu.svg"
            alt="Close"
          />
          New Invoice
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/plu.svg"
            alt="Close"
          />
          New Quote
        </MenuItem>
        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/plu.svg"
            alt="Close"
          />
          Enter Payment
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/plu.svg"
            alt="Close"
          />
          New Task
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/plu.svg"
            alt="Close"
          />
          New Expense
        </MenuItem>
        <hr color="#e5e7eb" width="80%" />

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/share.svg"
            alt="Close"
          />
          Merge
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/aa.svg"
            alt="Close"
          />
          Archive
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/delete.svg"
            alt="Close"
          />{" "}
          Delete
        </MenuItem>

        <MenuItem
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "black",
            cursor: "pointer",
            fontFamily: "Inter",
          }}
          onClick={handleClose}
        >
          <img
            style={{ marginRight: "5px" }}
            src="/assets/images/delete.svg"
            alt="Close"
          />
          Purge
        </MenuItem>
      </Menu>

    
      <div
        style={{
          fontSize: "20px",
          fontWeight: "400",
          color: "#000000",

          display: "flex",
          justifyContent: "end",
        }}
      >
        <Stack style={{ marginRight: "10px",marginTop:'10px', height: "50px" }}>
          <Pagination
            count={10}
            shape="rounded"
            classes={{ selected: classesPagination.selected }}
          />
        </Stack>
      </div>
    </>
  );
}
