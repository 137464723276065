import * as React from "react";
// import Table from '@mui/material/Table';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { MdOutlineCancel } from "react-icons/md";
import { MdHomeFilled } from "react-icons/md";
import "@fortawesome/fontawesome-free/css/all.css";

import departments from "../../src/_mockData/departments";
import { useState, useEffect } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { MdOutlineCloudUpload } from "react-icons/md";

import Tooltip from "@mui/material/Tooltip";

import InvoicesComponent from "./invoices";
import {
  Box,
  Button,

  IconButton,
  Menu,
  MenuItem,
 
  Table,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import CircularProgressWithLabel from "../Components/spinner";
// import CircularProgressWithLabel from "./Components/spinner";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { BottomNavigation } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Checkbox from "@mui/material/Checkbox";

import FilterIcon from "@mui/icons-material/FilterList";

import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import SimpleListMenu from "./create-invoice-dialog";
import SimpleListMenu2 from "./create-invoice-dialog";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  selected: {
    color: "red", // Change the color to your desired color
    border: "1px solid red", // Add the desired border styles
  },
}));

function TabPanel(props) {
  const { children, valueOther, i, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={valueOther !== i}
      id={`simple-tabpanel-two-${i}`}
      aria-labelledby={`simple-tab-two-${i}`}
      {...other}
    >
      {valueOther === i && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TabPanelComponent(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`custom-tabpanell-${index}`}
      aria-labelledby={`custom-tabb-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanelComponent.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yPropss(index) {
  return {
    id: `custom-tabb-${index}`,
    "aria-controlss": `custom-tabpanell-${index}`,
  };
}

TabPanel.propTypes = {
  children: PropTypes.node,
  i: PropTypes.number.isRequired,
  valueOther: PropTypes.number.isRequired,
};

// ufghweifgwefg
function a11yProps(index) {
  return {
    id: `simple-tab-two-${index}`,
    "aria-controls": `simple-tabpanel-two-${index}`,
  };
}

export default function CreateInvoice() {
  const classesPagination = useStyles();
  // tooltip
  const [Tooltipvalues, setTooltipValuee] = useState("Click to sort ascending");
  const [Tooltipvalues3, setTooltipValuee3] = useState(
    "Click to sort ascending"
  );
  const [Tooltipvalues1, setTooltipValuee1] = useState(
    "Click to sort ascending"
  );
  const [Tooltipvalues5, setTooltipValuee5] = useState(
    "Click to sort ascending"
  );
  const [orderBy, setOrderBy] = useState("");

  const [Tooltipvalues2, setTooltipValuee2] = useState(
    "Click to sort ascending"
  );
  const [orderBy2, setOrderBy2] = useState("");
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const [orderBy3, setOrderBy3] = useState("");
  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const [orderBy1, setOrderBy1] = useState("");
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [orderBy5, setOrderBy5] = useState("");
  const [anchorEl5, setAnchorEl5] = React.useState(null);

  const navigate = useNavigate();
  const listItem = {
    height: "85px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
    paddingLeft: "20px",
    color: "rgba(0, 0, 0, 0.76)",
    fontSize: "14px",
    fontWeight: "400",
    // fontFamily: 'Inter'
  };
  const listItemTwo = {
    height: "93px",
    borderBottom: "1px solid rgba(0, 0, 0, 0.17)",
    paddingLeft: "20px",
    color: "rgba(0, 0, 0, 0.76)",
    fontSize: "14px",
    fontWeight: "400",
    // fontFamily: 'Inter'
  };


  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

  const Details = [
    {
      title: "Details",
      "Company name": "Let dop business",

      Address: "Myhsteret 16",
      "Postal Code": "8535 OK",
      City: "Amsterdam",
      "Province / State": "Zuid holland",
      Country: "Netherlands",
    },
    {
      title: "Contact Info",
      Email: "test@google.nl",
      Website: "www.google.nl",
      Phone: "+31 020-32647874",
      "Fax machine": "+31 020-5741126",
      Coc: "123345356",
      "Vat number": "142342435356",
      Industry: "Media",
      Sector: "Marketing",
      Size: "25",
    },
  ];
  const Summary = [
    {
      point: 10,
      des: "Deals",
    },
    {
      point: "$25k",
      des: "Purchases",
    },
    {
      point: "$85k",
      des: "Life Time Value (LTV)",
    },
    {
      point: "25 Days",
      des: "Last Contact",
    },
    { point: "90 Days", des: "Last Order" },
    {
      point: "111",
      des: "RFM Score",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);
  const [ColorIcon, setColorIcon] = useState("#A49E9E");
  const [downColorIcon, downsetColorIcon] = useState("#A49E9E");
  const [bgColorCellTD, setBgColorCellTD] = useState("white");
  const [bgClass, setBgClass] = useState("white");

  const [downColorIcon2, downsetColorIcon2] = useState("#A49E9E");
  const [ColorIcon2, setColorIcon2] = useState("#A49E9E");
  const [bgColorCellTD2, setBgColorCellTD2] = useState("white");
  const [bgClass2, setBgClass2] = useState("white");

  const [downColorIcon3, downsetColorIcon3] = useState("#A49E9E");
  const [ColorIcon3, setColorIcon3] = useState("#A49E9E");
  const [bgColorCellTD3, setBgColorCellTD3] = useState("white");
  const [bgClass3, setBgClass3] = useState("white");

  const [downColorIcon1, downsetColorIcon1] = useState("#A49E9E");
  const [ColorIcon1, setColorIcon1] = useState("#A49E9E");
  const [bgColorCellTD1, setBgColorCellTD1] = useState("white");
  const [bgClass1, setBgClass1] = useState("white");

  const [downColorIcon5, downsetColorIcon5] = useState("#A49E9E");
  const [ColorIcon5, setColorIcon5] = useState("#A49E9E");
  const [bgColorCellTD5, setBgColorCellTD5] = useState("white");
  const [bgClass5, setBgClass5] = useState("white");

  // const [departmentsArr, setDepartmentsArr] = useState([departments]);
  const [departmentsData, setData] = useState([]);
  const toggleButtons1 = () => {
    if (orderBy1 === "") {
      setBgClass1("testClass1");
      setOrderBy1("asc");
      setTooltipValuee1("Click to sort descending");
      const sortedArr = [...departmentsData].sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setData(sortedArr);

      setColorIcon1("#00A1E4");
      downsetColorIcon1("#A49E9E");
      setBgColorCellTD1("snow");

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    } else if (orderBy1 === "asc") {
      setBgClass1("testClass1");
      setOrderBy1("dsc");

      setColorIcon1("#A49E9E");
      downsetColorIcon1("#00A1E4");
      setBgColorCellTD1("snow");
      setTooltipValuee1("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.name.localeCompare(a.name)
      );
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    } else if (orderBy1 === "dsc") {
      setBgClass1("testClass2");
      setOrderBy1("");

      setColorIcon1("#A49E9E");
      downsetColorIcon1("#A49E9E");
      setBgColorCellTD1("white");
      setTooltipValuee1("Click to sort ascending");

      setData([...departments]);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    }
  };
  const toggleButtonsanouther = () => {
    if (orderBy2 === "") {
      setBgClass2("testClass1");
      setOrderBy2("asc");
      setTooltipValuee2("Click to sort descending");

      setColorIcon2("#00A1E4");
      downsetColorIcon2("#A49E9E");
      setBgColorCellTD2("snow");
      const sortedArr = [...departmentsData].sort((a, b) =>
        a.number.localeCompare(b.number)
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    } else if (orderBy2 === "asc") {
      setBgClass2("testClass1");
      setOrderBy2("dsc");

      setColorIcon2("#A49E9E");
      downsetColorIcon2("#00A1E4");
      setBgColorCellTD2("snow");
      setTooltipValuee2("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.number.localeCompare(a.number)
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    } else if (orderBy2 === "dsc") {
      setBgClass2("testClass2");
      setOrderBy2("");
      setColorIcon2("#A49E9E");
      downsetColorIcon2("#A49E9E");
      setBgColorCellTD2("white");
      setTooltipValuee2("Click to sort ascending");
      setData([...departments]);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    }
  };

  const toggleButtons3 = () => {
    if (orderBy3 === "") {
      setBgClass3("testClass1");
      setOrderBy3("asc");
      setTooltipValuee3("Click to sort descending");

      setColorIcon3("#00A1E4");
      downsetColorIcon3("#A49E9E");
      setBgColorCellTD3("snow");
      const sortedArr = [...departmentsData].sort((a, b) =>
        a.name2.localeCompare(b.name2)
      );
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy2("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD5("white");
    } else if (orderBy3 === "asc") {
      setBgClass3("testClass1");
      setOrderBy3("dsc");

      setColorIcon3("#A49E9E");
      downsetColorIcon3("#00A1E4");
      setBgColorCellTD3("snow");
      setTooltipValuee3("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.name2.localeCompare(a.name2)
      );
      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy2("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD5("white");
    } else if (orderBy3 === "dsc") {
      setBgClass3("testClass2");
      setOrderBy3("");

      setColorIcon3("#A49E9E");
      downsetColorIcon3("#A49E9E");
      setBgColorCellTD3("white");
      setTooltipValuee3("Click to sort ascending");
      setData([...departments]);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass5("testClass2");

      setOrderBy("");
      setOrderBy1("");
      setOrderBy2("");
      setOrderBy5("");

      setColorIcon("#A49E9E");
      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD5("white");
    }
  };

  const toggleButtons = () => {
    if (orderBy === "") {
      setBgClass("testClass1");
      setOrderBy("asc");
      setTooltipValuee("Click to sort descending");

      setColorIcon("#00A1E4");
      downsetColorIcon("#A49E9E");
      setBgColorCellTD("snow");

      const sortedArr = [...departmentsData].sort((a, b) =>
        a.number2.localeCompare(b.number2)
      );
      setData(sortedArr);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy1("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    } else if (orderBy === "asc") {
      setBgClass("testClass1");
      setOrderBy("dsc");

      setColorIcon("#A49E9E");
      downsetColorIcon("#00A1E4");
      setBgColorCellTD("snow");
      setTooltipValuee("Click to cancel sorting");
      const sortedArr = [...departmentsData].sort((a, b) =>
        b.number2.localeCompare(a.number2)
      );
      setData(sortedArr);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy1("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    } else if (orderBy === "dsc") {
      setBgClass("testClass2");
      setOrderBy("");

      setColorIcon("#A49E9E");
      downsetColorIcon("#A49E9E");
      setBgColorCellTD("white");
      setTooltipValuee("Click to sort ascending");
      setData([...departments]);

      setTooltipValuee1("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee5("Click to sort ascending");

      setBgClass1("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass5("testClass2");

      setOrderBy1("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy5("");

      setColorIcon1("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon5("#A49E9E");

      downsetColorIcon1("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon5("#A49E9E");

      setBgColorCellTD1("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD5("white");
    }
  };

  const toggleButtons5 = () => {
    if (orderBy5 === "") {
      setBgClass5("testClass1");
      setOrderBy5("asc");
      setTooltipValuee5("Click to sort descending");

      setColorIcon5("#00A1E4");
      downsetColorIcon5("#A49E9E");
      setBgColorCellTD5("snow");

      const sortedArr = [...departmentsData].sort(
        (a, b) => a.Amount - b.Amount
      );

      setData(sortedArr);

      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass1("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy1("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon1("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon1("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD1("white");
    } else if (orderBy5 === "asc") {
      setBgClass5("testClass1");
      setOrderBy5("dsc");

      setColorIcon5("#A49E9E");
      downsetColorIcon5("#00A1E4");
      setBgColorCellTD5("snow");
      setTooltipValuee5("Click to cancel sorting");

      const sortedArr = [...departmentsData].sort(
        (a, b) => b.Amount - a.Amount
      );
      setData(sortedArr);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass1("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy1("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon1("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon1("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD1("white");
    } else if (orderBy5 === "dsc") {
      setBgClass5("testClass2");
      setOrderBy5("");

      setColorIcon5("#A49E9E");
      downsetColorIcon5("#A49E9E");
      setBgColorCellTD5("white");
      setTooltipValuee5("Click to sort ascending");
      setData([...departments]);
      setTooltipValuee("Click to sort ascending");
      setTooltipValuee2("Click to sort ascending");
      setTooltipValuee3("Click to sort ascending");
      setTooltipValuee1("Click to sort ascending");

      setBgClass("testClass2");
      setBgClass2("testClass2");
      setBgClass3("testClass2");
      setBgClass1("testClass2");

      setOrderBy("");
      setOrderBy2("");
      setOrderBy3("");
      setOrderBy1("");

      setColorIcon("#A49E9E");
      setColorIcon2("#A49E9E");
      setColorIcon3("#A49E9E");
      setColorIcon1("#A49E9E");

      downsetColorIcon("#A49E9E");
      downsetColorIcon2("#A49E9E");
      downsetColorIcon3("#A49E9E");
      downsetColorIcon1("#A49E9E");

      setBgColorCellTD("white");
      setBgColorCellTD2("white");
      setBgColorCellTD3("white");
      setBgColorCellTD1("white");
    }
  };

  const [valueTwo, setValueTwo] = React.useState(0);
  const handleChange = (event, newValuee) => {
    setValueTwo(newValuee);
  };
  const [values, setValuee] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValuee(newValue);
  };

  const classes = useStyles();
  const [showDivs, setShowDivs] = useState(false);
  const [showDivsTwo, setShowDivsTwo] = useState(false);

  const handleClickdiv = async () => {
    AnouthersetLoading(true);
    setShowDivs(true);
    setShowDivsTwo(false);
    setSelectedRow(null);
    setTimeout(() => {
      AnouthersetLoading(false); // Set loading state to false after 1 seconds
    }, 2000);
  };

  const handleClosediv = () => {
    setShowDivs(false);
  };
  const handleClickdivTwo = () => {
    setLoadingOther(true);
    setShowDivsTwo(true);
    setShowDivs(false);

    setTimeout(() => {
      setLoadingOther(false); // Set loading state to false after 1 seconds
    }, 2000);
  };

  const handleClosedivTwo = () => {
    setShowDivsTwo(false);
    setSelectedRow(null);
  };

  const basicInfo = {
    fontWeight: "600",
    fontSize: "20px",
    marginBottom: "0px",
  };
  const bscHeader = {
    display: "flex",
    justifyContent: "space-between",
    // alignItems: "center",
    padding: "20px 20px",
  };
  const buttonStyle = {
    backgroundColor: "#00A1E4",
    color: "#fff",
    alignItems: "center",
    columnGap: "5px",
    border: "none",
    textTransform: "none",
    width: "110px",
    height: "36px",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "20px",
    fontWeight: "400",
  };
  // for box shedw
  const shadowStyle = {
    margin: "10px",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1)",
    "--tw-shadow-colored":
      "0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color)",
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    // work
    setShowDivsTwo(false);
    setSelectedRow(null);
    setAnchorEl(event.currentTarget);
    setShowDivs(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElMenue, setAnchorElMenue] = useState(null);
  const handleClickMenue = (event) => {
    setAnchorElMenue(event.currentTarget);
  };
  const handleCloseMenue = () => {
    setAnchorElMenue(null);
  };
  const openIconMenu = Boolean(anchorElMenue);

  const openIcon = Boolean(anchorEl);

  const Invoices = {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
  };

  const [loading, setLoading] = useState(false);
  const [loadingOther, setLoadingOther] = useState(false);
  const [Anoutherloading, AnouthersetLoading] = useState(false);

  useEffect(() => {
    // Simulating an asynchronous API call
    setLoading(true);
    setTimeout(() => {
      // Set the loading state to false and populate the data

      setData(departments);
      setLoading(false);
    }, 2000); // Simulated delay of 2 seconds
  }, []);

  const [anchorEl4, setAnchorEl4] = React.useState(null);

  // checkBox in Table
  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowCheckboxChange = (event, index) => {
    if (event.target.checked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, index]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((rowIndex) => rowIndex !== index)
      );
    }
  };

  const isSelected = (index) => selectedRows.includes(index);
 

  const deleteItems = (selectedRows) => {
    const updatedItems = [...departmentsData];

    setData(
      updatedItems.filter((item, index) => !selectedRows.includes(index))
    );

    setSelectedRows([]);
  };

  return (
    <>
      <div className={"webview"} style={{ display: "flex" }}>
        <div
          style={{
            width: showDivs || showDivsTwo ? "58.3%" : "100%",
            transition: "width 0.3s linear ",
          }}
        >
          <BottomNavigation
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingRight: "30px",
              paddingLeft: "3%",
             
              borderBottom: "2px solid #D3CECE",
            }}
          >
            <div
              style={{
                display: "flex",
                // justifyContent: "start",
                justifyContent: "center",
                alignContent: "center",
                columnGap: "30px",
              }}
            >
              <p style={Invoices}>Invoices</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  columnGap: "20px",
                }}
              >
                <img src="/assets/images/home.svg" />
                <p style={{ fontSize: "14px", fontWeight: "600" }}>
                  Home <span style={{ color: "#00A1E4" }}> / </span>
                  <span style={{ color: "#00A1E4" }}>Invoices</span>{" "}
                  <span style={{ color: "#00A1E4" }}> / </span>
                  <span style={{ color: "#00A1E4" }}>
                    {" "}
                    create new invoice
                  </span>{" "}
                </p>
              </div>
            </div>

            <div>
              <IconButton aria-label="delete">
                <Checkbox
                  style={{ color: "black" }}
                  checked
                  fontSize="medium"
                />
              </IconButton>
              <IconButton aria-label="delete">
                <img src="/assets/images/menuee.svg"></img>
              </IconButton>
              <IconButton aria-label="delete">
                <img src="/assets/images/dwn.svg"></img>
              </IconButton>
              <IconButton aria-label="delete">
                <img src="/assets/images/pri.svg"></img>
              </IconButton>
              <IconButton aria-label="delete">
                <FilterIcon fontSize="medium" style={{ color: "black" }} />
              </IconButton>
              <Button onClick={handleClickdiv} style={buttonStyle} size="small">
                Add
                <AddIcon
                  style={{
                    backgroundColor: "white",
                    height: "15px",
                    width: "15px",
                    borderRadius: "100%",
                    color: "00A1E4",
                  }}
                  fontSize="small"
                />
              </Button>
            </div>
          </BottomNavigation>

          {selectedRows.length > 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0px 30px",
                height: "40px",
              }}
            >
              <p
                style={{ fontSize: "15px", fontWeight: "400", color: "black" }}
              >
                Selected {selectedRows.length} items
              </p>
              <img
                onClick={() => deleteItems(selectedRows)}
                style={{ height: "17px", width: "17px" }}
                src="/assets/images/dlt.svg"
              ></img>
            </div>
          ) : (
            ""
          )}
          {loading ? (
            <CircularProgressWithLabel />
          ) : (
            <>
              <TableContainer
                component={Paper}
                sx={{
                  my: 1,
                  scrollbarWidth: "thin",
                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                    display: "block",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#f1f1f1",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#888",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#555",
                  },
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",

                  maxHeight: "100vh",
                  // paddingTop:'100px'
                }}
              >
                <Table aria-label="simple table">
                  <TableHead style={{ borderTop: "1px solid #f0f0f0" }}>
                    <TableRow className={"TR"}>
                      <TableCell
                        style={{ color: "#9F9999" }}
                        width={100}
                        height={60}
                        align="center"
                      >
                        <Checkbox
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < departmentsData.length
                          }
                          checked={
                            selectedRows.length === departmentsData.length &&
                            departmentsData.length > 0
                          }
                          onChange={(event) =>
                            event.target.checked
                              ? setSelectedRows(
                                  departmentsData.map((_, index) => index)
                                )
                              : setSelectedRows([])
                          }
                        />
                      </TableCell>

                      <TableCell
                        style={{ color: "#9F9999" }}
                        width={100}
                        height={60}
                        align="left"
                      ></TableCell>

                      <Tooltip title={Tooltipvalues1} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass1}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={300}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              // gridTemplateColumns: "85% 15%",
                              cursor: "pointer",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons1}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Client
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons1}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon1 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon1 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl1(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <SimpleListMenu2
                            anchor={anchorEl1}
                            setAnchor={setAnchorEl1}
                          />
                        </TableCell>
                      </Tooltip>

                      <Tooltip title={Tooltipvalues2} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass2}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={400}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              // gridTemplateColumns: "85% 15%",
                              cursor: "pointer",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtonsanouther}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Number
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtonsanouther}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon2 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon2 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl2(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <SimpleListMenu
                            anchor={anchorEl2}
                            setAnchor={setAnchorEl2}
                          />
                        </TableCell>
                      </Tooltip>

                      <Tooltip title={Tooltipvalues3} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass3}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={300}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              // gridTemplateColumns: "85% 15%",
                              cursor: "pointer",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons3}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Client
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons3}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon3 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon3 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl3(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <SimpleListMenu
                            anchor={anchorEl3}
                            setAnchor={setAnchorEl3}
                          />
                        </TableCell>
                      </Tooltip>

                      <Tooltip title={Tooltipvalues} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={400}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              cursor: "pointer",
                              // gridTemplateColumns: "85% 15%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Number
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl4(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <SimpleListMenu
                            anchor={anchorEl4}
                            setAnchor={setAnchorEl4}
                          />
                        </TableCell>
                      </Tooltip>

                      <Tooltip title={Tooltipvalues5} arrow placement="top">
                        <TableCell
                          className={`custom-button ${bgClass5}`}
                          style={{
                            padding: "0px",
                            height: "100%",
                          }}
                          width={300}
                          align="left"
                        >
                          <div
                            style={{
                              paddingLeft: "10px",
                              borderLeft: "1px solid #e5e7eb",
                              borderRight: "1px solid #e5e7eb",
                              margin: "5px 0px 5px 0px",
                              display: "flex",
                              cursor: "pointer",
                              // gridTemplateColumns: "85% 15%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              onClick={toggleButtons5}
                              style={{ fontSize: "14px", fontWeight: "700" }}
                            >
                              Amount
                            </span>
                            <div
                              style={{
                                display: "grid",
                                gridTemplateColumns: "50% 50%",
                                justifyContent: "start",
                                alignItems: "center",
                              }}
                            >
                              <span
                                onClick={toggleButtons5}
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "3px",
                                }}
                              >
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: ColorIcon5 }}
                                    d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"
                                  ></path>
                                </svg>

                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 1000 1000"
                                  style={{ height: "10px", width: "10px" }}
                                >
                                  <path
                                    style={{ fill: downColorIcon5 }}
                                    d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"
                                  ></path>
                                </svg>
                              </span>
                              <svg
                                onClick={(event) =>
                                  setAnchorEl5(event.currentTarget)
                                }
                                width="100"
                                height="100"
                                viewBox="0 0 1000 1000"
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  alignItems: "end",
                                  marginRight: "10px",
                                }}
                              >
                                <path
                                  style={{ fill: "#7C7C7C" }}
                                  d="M349 838c0 17.7 14.2 32 31.8 32h262.4c17.6 0 31.8-14.3 31.8-32V642H349v196zm531.1-684H143.9c-24.5 0-39.8 26.7-27.5 48l221.3 376h348.8l221.3-376c12.1-21.3-3.2-48-27.7-48z"
                                ></path>
                              </svg>
                            </div>
                          </div>

                          <SimpleListMenu
                            anchor={anchorEl5}
                            setAnchor={setAnchorEl5}
                          />
                        </TableCell>
                      </Tooltip>

                      <TableCell
                        style={{
                          color: "#9F9999",
                          paddingRight: "65px",
                          fontSize: "14px",
                          fontWeight: "700",
                          paddingLeft: "10px",
                        }}
                        width={200}
                        align="left"
                      >
                        Status
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    style={{
                      overflowY: "scroll",
                    }}
                  >
                    {departmentsData.map((department, index) => (
                      <TableRow
                        key={index}
                        style={{
                          backgroundColor:
                            selectedRow === index ? "Gainsboro" : "white",
                        }}
                        hover
                        role="checkbox"
                        aria-checked={isSelected(index)}
                        tabIndex={-1}
                        selected={isSelected(index)}

                        // onClick={handleClickdivTwo}
                      >
                        <TableCell
                          style={{
                            alignSelf: "center",
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                          width={100}
                          align="center"
                        >
                          <Checkbox
                            checked={isSelected(index)}
                            onChange={(event) =>
                              handleRowCheckboxChange(event, index)
                            }
                          />
                        </TableCell>

                        <TableCell align="left" style={{ padding: "0px" }}>
                          <IconButton
                            style={{ fontSize: "17px", fontWeight: "2000" }}
                            id="basic-button"
                            aria-controls={openIcon ? "basic-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={openIcon ? "true" : undefined}
                            onClick={handleClick}
                          >
                            <BsThreeDotsVertical />
                          </IconButton>
                        </TableCell>

                        <TableCell
                          style={{
                            alignSelf: "left",
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",
                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD1 === "snow"
                                ? bgColorCellTD1
                                : "white",
                          }}
                          width={300}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          // style={{
                          //   padding: "0px",
                          //   fontSize: "14px",
                          //   fontWeight: "400",
                          //   paddingLeft: "10px",

                          //   backgroundColor:
                          //     selectedRow === index
                          //       ? "Gainsboro"
                          //       : bgColorCellTD1 === "snow"
                          //       ? bgColorCellTD1
                          //       : "white",
                          // }}
                        >
                          {department?.name}
                        </TableCell>
                        <TableCell
                          width={400}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          style={{
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",

                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD2 === "snow"
                                ? bgColorCellTD2
                                : "white",
                          }}
                        >
                          {department?.number}
                        </TableCell>

                        <TableCell
                          style={{
                            alignSelf: "left",
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",
                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD3 === "snow"
                                ? bgColorCellTD3
                                : "white",
                          }}
                          width={300}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                          }}
                          // style={{
                          //   padding: "0px",
                          //   fontSize: "14px",
                          //   fontWeight: "400",
                          //   paddingLeft: "10px",

                          // }}
                        >
                          {department?.name2}
                        </TableCell>

                        <TableCell
                          width={400}
                          align="left"
                          key={index}
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          style={{
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",

                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD === "snow"
                                ? bgColorCellTD
                                : "white",
                          }}
                        >
                          {department?.number2}
                        </TableCell>

                        <TableCell
                          width={300}
                          align="left"
                          onClick={() => {
                            setSelectedRow(index);
                            handleClickdivTwo();
                          }}
                          style={{
                            padding: "0px",
                            fontSize: "14px",
                            fontWeight: "400",
                            paddingLeft: "10px",
                            backgroundColor:
                              selectedRow === index
                                ? "Gainsboro"
                                : bgColorCellTD5 === "snow"
                                ? bgColorCellTD5
                                : "white",
                          }}
                        >
                          {department?.Amount} $
                        </TableCell>

                        <TableCell
                          style={{ paddingLeft: "10px" }}
                          width={200}
                          align="left"
                        >
                          {/* {row.protein} */}
                          {department.Amount === 200 ? (
                            <Chip
                              label="Sent"
                              style={{
                                background: "#00A1E4",
                                width: "85px",
                                height: "26px",
                                borderRadius: "5px",
                                color: "white",
                                fontSize: "13px",
                                fontWeight: "500",
                                // marginLeft: "6px",
                              }}
                            />
                          ) : department.Amount === 150 ? (
                            <Chip
                              label="Paid"
                              style={{
                                background: "#4D9A19",
                                width: "85px",
                                height: "26px",
                                borderRadius: "5px",
                                color: "white",
                                fontSize: "13px",
                                fontWeight: "500",
                                // marginLeft: "8px",
                              }}
                            />
                          ) : department.Amount === 100 ? (
                            <Chip
                              label="Draft"
                              style={{
                                background: "#888888",
                                width: "85px",
                                height: "26px",
                                borderRadius: "5px",
                                color: "white",
                                fontSize: "13px",
                                fontWeight: "500",
                                // marginLeft: "6px",
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openIcon}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    // fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/pdf_icon.svg"
                    alt="Close"
                  />{" "}
                  View Statement
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/client.svg"
                    alt="Close"
                  />
                  Client Portal
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    borderBottom: "1 solid #e5e7eb!important",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/sett.svg"
                    alt="Close"
                  />
                  Settings
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Invoice
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Quote
                </MenuItem>
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  Enter Payment
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Task
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Expense
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/share.svg"
                    alt="Close"
                  />
                  Merge
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/aa.svg"
                    alt="Close"
                  />
                  Archive
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />{" "}
                  Delete
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleClose}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />
                  Purge
                </MenuItem>
              </Menu>

              <Menu
                id="basic-menu-two"
                anchorEl={anchorElMenue}
                open={openIconMenu}
                onClose={handleCloseMenue}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    // fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/pdf_icon.svg"
                    alt="Close"
                  />{" "}
                  View Statement
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/client.svg"
                    alt="Close"
                  />
                  Client Portal
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    borderBottom: "1 solid #e5e7eb!important",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/sett.svg"
                    alt="Close"
                  />
                  Settings
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Invoice
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Quote
                </MenuItem>
                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  Enter Payment
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Task
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/plu.svg"
                    alt="Close"
                  />
                  New Expense
                </MenuItem>
                <hr color="#e5e7eb" width="80%" />

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/share.svg"
                    alt="Close"
                  />
                  Merge
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/aa.svg"
                    alt="Close"
                  />
                  Archive
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />{" "}
                  Delete
                </MenuItem>

                <MenuItem
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    color: "black",
                    cursor: "pointer",
                    fontFamily: "Inter",
                  }}
                  onClick={handleCloseMenue}
                >
                  <img
                    style={{ marginRight: "5px" }}
                    src="/assets/images/delete.svg"
                    alt="Close"
                  />
                  Purge
                </MenuItem>
              </Menu>

              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#000000",

                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Stack style={{ marginRight: "70px", height: "80px" }}>
                  <Pagination
                    count={10}
                    shape="rounded"
                    classes={{ selected: classesPagination.selected }}
                  />
                </Stack>
              </div>
            </>
          )}
        </div>

        <div
          style={{
            width: showDivs ? "40%" : "0%",
            transition: "width 0.3s linear ",
            boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
            position: "absolute",
            right: "0",
            background: "white",
            minHeight: "100%",
            borderRadius: "2px",
          }}
        >
          <div
            style={{
              transition: "width 0.3s linear ",
              display: showDivs ? "block" : "none",
            }}
          >
            <div style={bscHeader}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                }}
              >
                <p style={basicInfo}>New company</p>
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "15px",
                    color: "#00A1E4",
                  }}
                >
                  Company Details
                </span>
              </div>

              <div style={{ display: "flex", columnGap: "20px" }}>
                <Button
                  onClick={handleClosediv}
                  style={{
                    backgroundColor: "white",
                    border: "1px solid rgba(0, 0, 0, 0.18)",
                    width: "99px",
                    height: "30px",
                    borderRadius: "5px",
                    marginLeft: "6px",
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "7px",
                  }}
                  size="small"
                >
                  <MdOutlineCancel
                    style={{
                      height: "20px",
                      width: "20px",
                      paddingRight: "2px",
                    }}
                  />{" "}
                  Cancel{" "}
                </Button>
                <Button
                  style={{
                    background: "#4D9A19",
                    color: "white",
                    width: "99px",
                    height: "30px",
                    borderRadius: "5px",
                    marginLeft: "6px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    columnGap: "7px",
                  }}
                  size="small"
                >
                  <MdOutlineCloudUpload
                    style={{
                      height: "20px",
                      width: "20px",
                      paddingRight: "2px",
                    }}
                  />{" "}
                  Save{" "}
                </Button>
              </div>
            </div>

            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  TabIndicatorProps={{ style: { backgroundColor: "white" } }}
                  valueOther={valueTwo}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  style={{
                    marginTop: "15px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.11)",
                  }}
                >
                  <Tab
                    style={{
                      padding: 0,
                      fontWeight: "600",
                      fontSize: "17px",
                      color: "black",
                      textTransform: "none",
                      borderBottom:
                        valueTwo === 0 ? "2px solid #00A1E4" : "none",
                      color: valueTwo === 0 ? "#00A1E4" : "black",
                      marginLeft: "20px",
                    }}
                    label={formatLabel("Basic info")}
                    {...a11yProps(0)}
                  />
                  <Tab
                    style={{
                      padding: 0,
                      marginLeft: "50px",
                      fontWeight: "600",
                      fontSize: "17px",
                      color: "black",
                      textTransform: "none",
                      borderBottom:
                        valueTwo === 1 ? "2px solid #00A1E4" : "none",
                      color: valueTwo === 1 ? "#00A1E4" : "black",
                    }}
                    label={formatLabel("Contact person")}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              {Anoutherloading ? (
                <CircularProgressWithLabel />
              ) : (
                <>
                  <TabPanel valueOther={valueTwo} i={0}>
                    <div className={"boxTwo"}>
                      <div className={classes.root}>
                        <List component="ul" sx={{ padding: 0 }}>
                          <ListItem
                            component="li"
                            style={listItem}
                            sx={{ padding: 0 }}
                          >
                            <input
                              className={"custom-input"}
                              type="text"
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              placeholder="Name"
                            />
                          </ListItem>
                          <ListItem
                            component="li"
                            style={listItem}
                            sx={{ padding: 0 }}
                          >
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="Name"
                            />
                          </ListItem>
                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="tel"
                              placeholder="Phone No"
                            />
                          </ListItem>
                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="tel"
                              placeholder="Phone No"
                            />
                          </ListItem>
                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="tel"
                              placeholder="Phone No"
                            />
                          </ListItem>
                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="tel"
                              placeholder="Phone No"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="Company Name"
                            />
                          </ListItem>
                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="Website"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="Street"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="Country"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="Country"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="email"
                              placeholder="Email"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="email"
                              placeholder="Email"
                            />
                          </ListItem>

                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="COC No"
                            />
                          </ListItem>
                          <ListItem component="li" style={listItemTwo}>
                            <input
                              className={"custom-input"}
                              style={{
                                border: "none",
                                outline: "none",
                                fontSize: "16px",
                                fontWeight: "400",
                                width: "95%",
                              }}
                              type="text"
                              placeholder="COC No"
                            />
                          </ListItem>
                        </List>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    className={"boxTwoTab"}
                    valueOther={valueTwo}
                    i={1}
                    style={{}}
                  >
                    <div className={classes.root}>
                      <List component="ul" sx={{ padding: 0 }}>
                        <ListItem
                          component="li"
                          style={listItem}
                          sx={{ padding: 0 }}
                        >
                          <input
                            className={"custom-input"}
                            type="text"
                            style={{
                              border: "none",
                              outline: "none",
                              fontSize: "16px",
                              fontWeight: "400",
                              width: "95%",
                            }}
                            placeholder="First Name"
                          />
                        </ListItem>
                        <ListItem
                          component="li"
                          style={listItem}
                          sx={{ padding: 0 }}
                        >
                          <input
                            className={"custom-input"}
                            style={{
                              border: "none",
                              outline: "none",
                              fontSize: "16px",
                              fontWeight: "400",
                              width: "95%",
                            }}
                            type="text"
                            placeholder="Last Name"
                          />
                        </ListItem>
                        <ListItem component="li" style={listItemTwo}>
                          <input
                            className={"custom-input"}
                            style={{
                              border: "none",
                              outline: "none",
                              fontSize: "16px",
                              fontWeight: "400",
                              width: "95%",
                            }}
                            type="tel"
                            placeholder="Phone No"
                          />
                        </ListItem>

                        <ListItem component="li" style={listItemTwo}>
                          <input
                            className={"custom-input"}
                            style={{
                              border: "none",
                              outline: "none",
                              fontSize: "16px",
                              fontWeight: "400",
                              width: "95%",
                            }}
                            type="email"
                            placeholder="Email"
                          />
                        </ListItem>
                      </List>
                    </div>
                  </TabPanel>
                </>
              )}
            </Box>
          </div>
        </div>

        <div
          className="drowTwo"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.22)",
            width: showDivsTwo ? "40%" : "0%",
            transition: "width 0.3s linear ",
            position: "absolute",
            right: "0",
            background: "white",
            minHeight: "100%",
          }}
        >
          <Box
            style={{
              transition: "width 0.3s linear ",
              display: showDivsTwo ? "block" : "none",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                columnGap: "33px",
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: { backgroundColor: "transparent", display: "none" },
                }}
                value={values}
                onChange={handleTabChange}
                aria-label="scrollable force tabs example"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
              >
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 0 ? "#00A1E4" : "transparent",
                    color: values === 0 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Summary")}
                  {...a11yPropss(0)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 1 ? "#00A1E4" : "transparent",
                    color: values === 1 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Invoice")}
                  {...a11yPropss(1)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 2 ? "#00A1E4" : "transparent",
                    color: values === 2 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Details")}
                  {...a11yPropss(2)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 3 ? "#00A1E4" : "transparent",
                    color: values === 3 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Projects")}
                  {...a11yPropss(3)}
                />
                <Tab
                  style={{
                    color: "#000000",
                    fontSize: "14px",
                    fontWeight: "600",
                    backgroundColor: values === 4 ? "#00A1E4" : "transparent",
                    color: values === 4 ? "white" : "black",
                    textTransform: "none",
                  }}
                  label={formatLabel("Tickets")}
                  {...a11yPropss(4)}
                />
              </Tabs>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                }}
              >
                <IconButton
                  id="basic-button"
                  aria-controls={openIconMenu ? "basic-menu-two" : undefined}
                  aria-haspopup="true"
                  aria-expanded={openIconMenu ? "true" : undefined}
                  onClick={handleClickMenue}
                >
                  <BsThreeDotsVertical style={{ color: "#000000" }} />
                </IconButton>
                <span
                  style={{ display: "inline-block" }}
                  onClick={handleClosedivTwo}
                >
                  <img src="/assets/images/cross.svg" alt="Close" />
                </span>
                {/* <span
                  icon={<img src="/assets/images/cross.svg"/>}
                  style={{ height: "30px", width: "30px" }}
                  onClick={handleClosedivTwo}
                /> */}
              </div>
            </Box>

            {loadingOther ? (
              <CircularProgressWithLabel />
            ) : (
              <>
                <TabPanelComponent className={"tab"} value={values} index={0}>
                  <span className={"box"}>
                    <div>
                      <div
                        style={{
                          textAlign: "center",
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          marginBottom: "10px",
                          height: "100px",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          marginTop: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <h2
                          style={{
                            fontWeight: "700",
                            fontSize: "20px",
                          }}
                        >
                          Lets do international Business BV
                        </h2>
                      </div>
                      <div
                        style={{
                          boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                          padding: "0px 20px",
                          margin: "20px 0px",
                        }}
                      >
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "33% 33% 33%",
                            alignItems: "center",
                            justifyContent: "center",
                            columnGap: "3%",
                          }}
                        >
                          {Summary.map((item, index) => (
                            <div
                              key={index}
                              style={{
                                margin: "10px 0px",
                                textAlign: "center",
                                border: "1px solid rgba(0,0,0,.1)",
                                borderRadius: "7px",
                                paddingBottom: "25px",
                                height: "107px",
                              }}
                            >
                              <h2
                                style={{
                                  marginTop: "9px",
                                  marginBottom: "2px",
                                  fontSize: "1.5rem",
                                  fontWeight: "700",
                                  color: "black",
                                }}
                              >
                                {item.point}{" "}
                              </h2>
                              <span
                                style={{ fontSize: "14px", fontWeight: "400" }}
                              >
                                {item.des}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          padding: "30px 20px 40px 20px",
                          display: "grid",
                          gridTemplateColumns: "35% 35%",
                          columnGap: "70px",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Net revenue
                            <br />
                            Debt excluded net sales total
                          </p>
                          <span style={{ fontWeight: "700", fontSize: "26px" }}>
                            20,000$
                          </span>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            These figures here are based on net
                            <br />
                            sales total excluded debt
                          </p>
                        </div>
                        <div>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            Gross revenue
                            <br />
                            Debt excluded net sales total
                          </p>
                          <span style={{ fontWeight: "700", fontSize: "26px" }}>
                            20,000$
                          </span>
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "14px",
                            }}
                          >
                            These figures here are based on net
                            <br />
                            sales total excluded debt
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          padding: "30px 20px 40px 20px",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            columnGap: "10px",
                            alignItems: "center",
                            paddingBottom: "20px",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <MdHomeFilled />
                          <span style={{ fontWeight: "700", fontSize: "14px" }}>
                            Contact Info{" "}
                          </span>
                        </div>

                        <ul>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                                paddingLeft: "0px",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Company name :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Address :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                VAT No :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Customer Id :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Phone No :
                              </li>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                            </Grid>
                          </Grid>
                        </ul>
                      </div>
                      <div
                        style={{
                          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
                          padding: "30px 20px 40px 20px",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            columnGap: "10px",
                            alignItems: "center",
                            paddingBottom: "20px",
                            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                          }}
                        >
                          <MdHomeFilled />
                          <span style={{ fontWeight: "700", fontSize: "14px" }}>
                            Contact Info{" "}
                          </span>
                        </div>

                        <ul>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                                paddingLeft: "0px",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Company name :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Address :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                VAT No :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Customer Id :
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Phone No :
                              </li>
                            </Grid>
                            <Grid
                              item
                              xs={8}
                              style={{
                                fontSize: "14px",
                                fontWeight: "400",
                                color: " rgba(0, 0, 0, 0.76)",
                                listStyleType: "none",
                              }}
                            >
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                              <li style={{ paddingBottom: "10px" }}>
                                Let’s do business international BV
                              </li>
                            </Grid>
                          </Grid>
                        </ul>
                      </div>
                    </div>
                  </span>
                </TabPanelComponent>
                <TabPanelComponent className={"tab"} value={values} index={1}>
                  <InvoicesComponent />
                </TabPanelComponent>
                <TabPanelComponent
                  className={"Othertab"}
                  value={values}
                  index={2}
                >
                  <span className={"box"}>
                    {Details.map((item, index) => (
                      <div key={index} style={shadowStyle}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            columnGap: "2%",
                            marginLeft: "12px",
                            paddingBottom: "10px",
                            borderBottom: "1px solid rgba(0,0,0,.1)",
                          }}
                        >
                          <img src="/assets/images/home.svg" />
                          <p style={{ fontSize: "14px", fontWeight: "700" }}>
                            {item.title}
                          </p>
                        </div>
                        <div>
                          <ul
                            style={{
                              paddingLeft: "42px",
                              marginTop: "0px",
                              paddingBottom: "15px",
                            }}
                          >
                            {Object.entries(item).map(
                              ([key, value]) =>
                                key !== "title" && (
                                  <li
                                    key={key}
                                    style={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      columnGap: "15%",
                                      color: "rgba(0,0,0,.76)",
                                      paddingTop: "10px",
                                    }}
                                  >
                                    <div style={{ width: "30%" }}>
                                      <strong
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {key}
                                      </strong>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {value}
                                      </span>
                                    </div>
                                  </li>
                                )
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </span>
                </TabPanelComponent>
                <TabPanelComponent value={values} index={3}>
                  Projects
                </TabPanelComponent>
                <TabPanelComponent value={values} index={4}>
                  Tickets
                </TabPanelComponent>
              </>
            )}
          </Box>
        </div>
      </div>

      <div className={"mobilVied"}>
        {loading ? (
          <CircularProgressWithLabel />
        ) : (
          <TableContainer
            style={{ margin: "150px 0px 90px 0px" }}
            component={Paper}
          >
            <Table>
              <TableBody className={"scroolDemMobile"}>
                {departmentsData.map((item, index) => (
                  <TableRow key={index} style={{ border: "none!important" }}>
                    <TableCell
                      align="left"
                      style={{ padding: "0px", paddingLeft: "10px" }}
                    >
                      <IconButton
                        style={{ fontSize: "17px", fontWeight: "2000" }}
                        id="basic-button"
                        aria-controls={openIcon ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openIcon ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <BsThreeDotsVertical />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      onClick={() => navigate("/company-details")}
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        padding: "10px 0px",
                      }}
                      align="left"
                    >
                      {item.number}
                      <br />
                      <span style={{ fontSize: "13px", fontWeight: "400" }}>
                        {item.name}
                      </span>
                    </TableCell>

                    <TableCell
                      onClick={() => navigate("/company-details")}
                      align="left"
                      style={{ padding: "10px 10px 10px 30px " }}
                    >
                      {item.status === "Complete" ? (
                        <span style={{ color: "#07A934", fontSize: "14px" }}>
                          "Complete"
                        </span>
                      ) : item.status === "Working on" ? (
                        <span style={{ color: "#CE0535", fontSize: "14px" }}>
                          "Working on"
                        </span>
                      ) : item.status === "Backlog" ? (
                        <span style={{ color: "#00B8C4", fontSize: "14px" }}>
                          "Backlog"
                        </span>
                      ) : item.status === "Open" ? (
                        <span style={{ color: "#00B8C4", fontSize: "14px" }}>
                          "Open"
                        </span>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </>
  );
}
