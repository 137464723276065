import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

const EmailProvider = [
  { label: "Default" },
  { label: "Option2" },
  { label: "Option3" },
  { label: "Option4" },
];
const SendTime = [
    { label: "6:00 Am" },
    { label: "7:00 Am" },
    { label: "8:00 Am" },
    { label: "9:00 Am" },
  ];


const QuoteDesign = [
  { label: "Single-line text" },
  { label: "When Saved" },
  { label: "option2" },
  { label: "Irregular" },
];







export default function ClientPortalTab({ handleDrawerClose,selectedTab }) {
  const [state, setState] = React.useState({

    antoine: true,
  });

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const [valueText, setValueText] = useState({
    fildOne: "",
    fildTwo: "",
    fildThree: "",
    fildFour: "",
    fildFive: "",
    fildSix: "",
    fildSeven: "",
  });

  const handleClear = () => {
    const temp = { ...valueText };
    temp.fildOne = "";
    setValueText(temp);
  };
    const handleClearTwo = () => {
    const temp = { ...valueText };
    temp.fildTwo = "";
    setValueText(temp);
  }; 
   const handleClearThree = () => {
    const temp = { ...valueText };
    temp.fildThree = "";
    setValueText(temp);
  };
    const handleClearFour = () => {
    const temp = { ...valueText };
    temp.fildFour = "";
    setValueText(temp);
  }; 
    const handleClearFive = () => {
    const temp = { ...valueText };
    temp.fildFive = "";
    setValueText(temp);
  }; 
     const handleClearSix = () => {
    const temp = { ...valueText };
    temp.fildSix = "";
    setValueText(temp);
  };
      const handleClearSeven = () => {
    const temp = { ...valueText };
    temp.fildSeven = "";
    setValueText(temp);
  };


 

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>

<Box className={"webview"}
        style={{ 
          display: 'flex',
          justifyContent:'end',
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
     

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>
       {/* for mobile */}

       <Box
        className={"mobilVied"}
        style={{
          backgroundColor: "#1975D2",

          height: "56px",
          paddingBottom: "47px",
          paddingTop: "119px",
          position: "fixed",
          width: "100%",
          zIndex: "2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 20px 0px 20px",
          }}
        >
          <Button onClick={handleDrawerClose}>
            <img
              style={{ width: "14.98px", height: "14.66px" }}
              src="/assets/images/leftl.png"
              alt=""
            />

            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              {selectedTab}
            </span>
          </Button>
          <Button>
            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              Save
            </span>
          </Button>
        </div>
      </Box>


      <div
        style={{}}
        className={"Othertab boxTwo mobilVied"}
        
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "195px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "40px",
          }}
        >
          {/* second portion */}

          {/* 1 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={EmailProvider}
            sx={{ width: "100%", margin: " 20px 0px 10px 0px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email Provider"
                variant="standard"
              />
            )}
          />
          {/* 2 */}
    
        </div>



        <div
          style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "100px",
          }}
        >
          {/* second portion */}

        

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="From Name"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />


          {/* 2 */}

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Reply-To Email "
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTwo}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* 3 */}

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Reply-To Name"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearThree}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* 4 */}

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="BCC Email"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFour}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* 1 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={SendTime}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField {...params} label="Send Time" variant="standard" />
            )}
          />
        </div>




        <div
          style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "100px",
          }}
        >
       

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Email Design"
            variant="standard"
            value={valueText.fildFive}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFive = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFive}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* 2 */}
          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Email Allgnment"
            variant="standard"
            value={valueText.fildSix}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSix = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSix}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* 3 */}
          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Email Signature"
            variant="standard"
            value={valueText.fildSeven}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSeven = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSeven}>
                      <ClearIcon  />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />












          <FormControl
            component="fieldset"
            variant="standard"
            style={{ paddingTop: "10px", width: "100%" }}
          >
         

            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
              }}
              sx={{ m: 1, width: "100%" }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <img src="/assets/images/fold.png" alt="Images" />
                  <Typography variant="body1" component="span">
                  Show Email Footer
                  </Typography>
                </div>
              }
              control={
                <Switch
                  checked={state.antoine}
                  onChange={handleChangebutton}
                  name="antoine"
                />
              }
              labelPlacement="start"
            />
          </FormControl>
          {/* 3 */}
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={CreditDesign}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField {...params} label="Reset Counter" variant="standard" />
            )}
          /> */}
        </div>
      </div> 

      <div
        style={{}}
        className={"Othertab boxTwo webview"}
        
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "40px",
          }}
        >
          {/* second portion */}

          {/* 1 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={EmailProvider}
            sx={{ width: "100%", margin: " 20px 0px 10px 0px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Email Provider"
                variant="standard"
              />
            )}
          />
          {/* 2 */}
    
        </div>



        <div
          style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "100px",
          }}
        >
          {/* second portion */}

        

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="From Name"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />


          {/* 2 */}

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Reply-To Email "
            variant="standard"
            value={valueText.fildTwo}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildTwo = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearTwo}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* 3 */}

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Reply-To Name"
            variant="standard"
            value={valueText.fildThree}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildThree = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearThree}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* 4 */}

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="BCC Email"
            variant="standard"
            value={valueText.fildFour}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFour = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFour}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          {/* 1 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={SendTime}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField {...params} label="Send Time" variant="standard" />
            )}
          />
        </div>




        <div
          style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 20px",
            marginBottom: "100px",
          }}
        >
       

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Email Design"
            variant="standard"
            value={valueText.fildFive}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildFive = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearFive}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* 2 */}
          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Email Allgnment"
            variant="standard"
            value={valueText.fildSix}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSix = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSix}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          {/* 3 */}
          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Email Signature"
            variant="standard"
            value={valueText.fildSeven}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildSeven = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClearSeven}>
                      <ClearIcon  />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />












          <FormControl
            component="fieldset"
            variant="standard"
            style={{ paddingTop: "10px", width: "100%" }}
          >
         

            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
              }}
              sx={{ m: 1, width: "100%" }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <img src="/assets/images/fold.png" alt="Images" />
                  <Typography variant="body1" component="span">
                  Show Email Footer
                  </Typography>
                </div>
              }
              control={
                <Switch
                  checked={state.antoine}
                  onChange={handleChangebutton}
                  name="antoine"
                />
              }
              labelPlacement="start"
            />
          </FormControl>
          {/* 3 */}
          {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={CreditDesign}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField {...params} label="Reset Counter" variant="standard" />
            )}
          /> */}
        </div>
      </div>

     
    </Box>
  );
}
