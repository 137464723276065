

import React, { useState } from "react";
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
export default function IndeterminateCheckbox({handleClose}) {
  const [checked, setChecked] = React.useState([false, false]);
  const [disabled, setdisabled] = useState(true);
  const [corsordisabled, setcorsordisabled] = useState("not-allowed");
//   const { onClose, selectedValue, open } = props;

  const handleChange1 = (event) => {
    setChecked([event.target.checked, event.target.checked]);
    if(event.target.checked ===true){
        setdisabled(false)
        setcorsordisabled("pointer")
    }
    else{
        setdisabled(true)
        setcorsordisabled("not-allowed")

    }

   
  };
 

  const handleChange2 = (event) => {
    setChecked([event.target.checked, checked[1]]);
    if(event.target.checked ===true){
        setdisabled(false)
        setcorsordisabled("pointer")
    }
    else{
        setdisabled(true)
        setcorsordisabled("not-allowed")

    }
 
  };

  const handleChange3 = (event) => {
    setChecked([checked[0], event.target.checked]);
    if(event.target.checked ===true){
        setdisabled(false)
        setcorsordisabled("pointer")
    }
    else{
        setdisabled(true)
        setcorsordisabled("not-allowed")

    }
   
  };


  const handleReset = () => {
    setChecked([false, false])
    setdisabled(true);
    setcorsordisabled('not-allowed');
  };

  const children = (
    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
      <FormControlLabel className={'checkboxItem'}
        label="Angelica Ramos"
        control={<Checkbox checked={checked[0]} onChange={handleChange2} />}
     
      />








      <FormControlLabel className={'checkboxItem'}
        label="Ramos Angelica"
        control={<Checkbox checked={checked[1]} onChange={handleChange3} />}
      />
    </Box>
  );

  return (<>
    <div>
      <FormControlLabel
        label="Select all items"
        control={
          <Checkbox
            checked={checked[0] && checked[1]}
            indeterminate={checked[0] !== checked[1]}
            onChange={handleChange1}
          />
        }
      />
      {children}

    </div>
  
  <div style={{  borderTop: '1px solid #f0f0f0',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
      <Button style={{ cursor:corsordisabled, pointerEvents: 'auto',marginTop:'14px' }} disabled={disabled} onClick={handleReset}>Reset
     
      </Button>

     
      <Button  onClick={handleClose} style={{backgroundColor:'#00A1E4',color:'white',borer:'none',padding:'2px',marginTop:'14px'}}>OK</Button>
     

      </div>
  </>
  
  );
}
