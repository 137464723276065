import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import AddCompany from "./newCompany";


export default function Footer() {
    const companyLogo={
        width: '128px',
        height: '28px'
    }

  
    const navigate = useNavigate();

  return (
    <>
        <div className={'footerMain'} style={{boxShadow: '5px -3px 4px rgba(0, 0, 0, 0.25)',position:'fixed',bottom:'0px',width:'100%',maxHeight:'100%',paddingRight:'70px'}}>
         <BottomNavigation style={{ backgroundColor: '#ffffff',display:'flex',justifyContent:'space-between',alignItems:'center',paddingLeft:'30px', }}>
     <img style={companyLogo} src='./assets/images/image 4.png'/>
     <div>
     <BottomNavigationAction label="Message" icon={<img src="/assets/images/msg.svg" alt="Message" />} />
    <BottomNavigationAction label="Email" icon={<img src='/assets/images/tex.svg' />} />
    <BottomNavigationAction label="Phone" icon={<img src='/assets/images/ph.svg' />} />
     </div>
    </BottomNavigation>
    </div>
    <div className='mobieFooter'>
      <span >
      <BottomNavigationAction  icon={<img src="/assets/images/bell.svg" alt="Message" />} />
    <p style={{margin:'0px',paddingLeft:'12px',color:'#00A1E4',fontSize:'10px',fontWeight:'400',paddingTop:'5px'}}>Notification</p>
      </span>

      <span >
      <BottomNavigationAction  icon={<img src="/assets/images/message.svg" alt="Message" />} />
    <p style={{margin:'0px',paddingLeft:'19px',color:'#00A1E4',fontSize:'10px',fontWeight:'400',paddingTop:'5px'}}>Messages</p>
      </span> 
      
      <span onClick={() => navigate("/add-company")} style={{textAlign: 'center',alignItems:'center',justifyContent:'center',display:'flex',top:'-32px',position:'relative'}} >
      <BottomNavigationAction  icon={<img src="/assets/images/pl.svg" alt="Message" />} />
      {/* <Route path="createInvoice" element={<AddCompany/>}/> */}
    
      </span>

      <span className={'bellSec'}>
      <BottomNavigationAction  icon={<img style={{height: '20px',width: '20px'}} src="/assets/images/support.svg" alt="Message" />} />
    <p style={{margin:'0px',paddingRight: '23px',color:'#00A1E4',fontSize:'10px',fontWeight:'400',paddingTop:'5px'}}>support</p>
      </span> 
        <span className={'bellSec'}>
      <BottomNavigationAction icon={<img style={{height: '20px',width: '20px'}} src="/assets/images/faq.svg" alt="Message" />} />
    <p style={{margin:'0px',paddingRight: '23px',color:'#00A1E4',fontSize:'10px',fontWeight:'400',paddingTop:'5px'}}>FAQs</p>
      </span>
   
   
    

    </div>
    </>

      
  )
}
