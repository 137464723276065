import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormHelperText from '@mui/material/FormHelperText';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
const autoBillStandardInvoices = [
    { label: "Off" },
    { label: "On" },
  
  ];
const autoBillRecurringInvoices = [
  { label: "Off" },
  { label: "On" },

];

const autoBillOn = [
  { label: "Off" },
  { label: "Due Date" },
  { label: "On" },

];

const useAvailableCredits = [
  { label: "Off" },
  { label: "Due Date" },
  { label: "On" },

];




export default function PaymentSettingTab({ handleDrawerClose,selectedTab}) {
    const [state, setState] = React.useState({

        jason: false,
        antoine: false,
         overPayment:false, 
        underPayment :false,
        onlinePaymentEmail:true,
        manualPaymentEmail:true,
        markPaidPaymentEmail:false 

      });
    
      const handleChangebutton = (event) => {
        setState({
          ...state,
          [event.target.name]: event.target.checked,
        });
      };

  //  inputFild with cross button

  const [valueText, setValueText] = useState({
    fildOne: "",
    
  });

  const handleClear = () => {
    const temp = { ...valueText };
    temp.fildOne = "";
    setValueText(temp);
  };

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{ 
          display: 'flex',
          justifyContent:'end',
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
     

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>

             {/* for mobile */}

             <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"47px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
       

      
      </Box>
  
      <div
        style={{}}
        className={"Othertab boxTwo mobilVied"}
     
      >
        <div
          style={{
            overflow: "hidden",
            margin: "190px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
           <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoBillStandardInvoices}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Auto Bill standard Invoices" variant="standard" />
            )}
          />

<Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoBillRecurringInvoices}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Auto Bill Recurring Invoices" variant="standard" />
            )}
          />

<Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoBillOn}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Auto Bill On" variant="standard" />
            )}
          />
          
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={useAvailableCredits}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Use Available Credits" variant="standard" />
            )}
          />

            <TextField
              label="Use Available Credits"
              variant="standard"
            
              value={valueText.fildOne}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildOne = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

           

          
            
          </Box>
        </div> 
        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "80%",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor:"#00A1E4",
              color:"#fff",
            
             
            }}
          > <span style={{display:'flex',alignItems:'center',justifyContent:'center',columnGap:'10px'}}>
             <img  src="/assets/images/set.svg" alt=""></img>
            CONFIGURE GATEWAYS
          </span>
           
          </button>
        </div>
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Enable Applying Payments Later"
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Support separately creating and applying payments</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Convert Currency"
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Set an exchange rate when entering a manual payment</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Allow Over Payment"
       control={<Switch checked={state.overPayment} onChange={handleChangebutton} name="overPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Support paying extra to accept tips in the client portal</FormHelperText>
  



      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Allow Under Payment"
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Support paying a minimum amount in the client portal</FormHelperText>
  


 </FormControl>

           

          
            
          </Box>
        </div>


        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"100px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Online Payment Email"
       control={<Switch checked={state.onlinePaymentEmail} onChange={handleChangebutton} name="onlinePaymentEmail" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when an online payment is made</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Manual Payment Email"
       control={<Switch checked={state.manualPaymentEmail} onChange={handleChangebutton} name="manualPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when manually entering a payment</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Mark Paid Payment Email "
       control={<Switch checked={state.markPaidPaymentEmail} onChange={handleChangebutton} name="markPaidPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when marking an invoice as paid</FormHelperText>
  



     


 </FormControl>

           

          
            
          </Box>
        </div>

        
      </div>

      <div
        style={{}}
        className={"Othertab boxTwo webview"}
     
      >
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
           <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoBillStandardInvoices}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Auto Bill standard Invoices" variant="standard" />
            )}
          />

<Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoBillRecurringInvoices}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Auto Bill Recurring Invoices" variant="standard" />
            )}
          />

<Autocomplete
            disablePortal
            id="combo-box-demo"
            options={autoBillOn}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Auto Bill On" variant="standard" />
            )}
          />
          
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={useAvailableCredits}
            sx={{ width: "100%", marginLeft: "8px" }}
            renderInput={(params) => (
              <TextField {...params} label="Use Available Credits" variant="standard" />
            )}
          />

            <TextField
              label="Use Available Credits"
              variant="standard"
            
              value={valueText.fildOne}
              onChange={(e) => {
                const temp = { ...valueText };
                temp.fildOne = e.target.value;
                setValueText(temp);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{ margin: "0px 20px 5px 0px" }}
                  >
                    {valueText && (
                      <IconButton edge="end" onClick={handleClear}>
                        <ClearIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />

           

          
            
          </Box>
        </div> 
        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "644px",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor:"#00A1E4",
              color:"#fff",
            
             
            }}
          > <span style={{display:'flex',alignItems:'center',justifyContent:'start',columnGap:'52px'}}>
             <img style={{marginLeft:"156px"}} src="/assets/images/set.svg"></img>
            CONFIGURE GATEWAYS
          </span>
           
          </button>
        </div>
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Enable Applying Payments Later"
       control={<Switch checked={state.jason} onChange={handleChangebutton} name="jason" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Support separately creating and applying payments</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Convert Currency"
       control={<Switch checked={state.antoine} onChange={handleChangebutton} name="antoine" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Set an exchange rate when entering a manual payment</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Allow Over Payment"
       control={<Switch checked={state.overPayment} onChange={handleChangebutton} name="overPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Support paying extra to accept tips in the client portal</FormHelperText>
  



      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Allow Under Payment"
       control={<Switch checked={state.underPayment} onChange={handleChangebutton} name="underPayment" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Support paying a minimum amount in the client portal</FormHelperText>
  


 </FormControl>

           

          
            
          </Box>
        </div>


        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom:"100px"
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
                <FormControl component="fieldset" variant="standard">
     
      
      
     <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}
     label ="Online Payment Email"
       control={<Switch checked={state.onlinePaymentEmail} onChange={handleChangebutton} name="onlinePaymentEmail" /> 
       }
     
       labelPlacement="start"
     />
          <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when an online payment is made</FormHelperText>

<FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Manual Payment Email"
       control={<Switch checked={state.manualPaymentEmail} onChange={handleChangebutton} name="manualPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when manually entering a payment</FormHelperText>
  

      <FormControlLabel style={{display:"flex",justifyContent:'space-between',alignItems:'center',paddingRight:'10px',margin:'0px'}} sx={{ m: 1, width: "100%" }}  
       label="Mark Paid Payment Email "
       control={<Switch checked={state.markPaidPaymentEmail} onChange={handleChangebutton} name="markPaidPaymentEmail" />}
       
       labelPlacement="start"
     />
      <FormHelperText style={{fontSize:"12px",fontWeight:'400',paddingLeft:"4px",margin:'-5px',paddingBottom:'10px'}}>Send an email when marking an invoice as paid</FormHelperText>
  



     


 </FormControl>

           

          
            
          </Box>
        </div>

        
      </div>
      
    </Box>
  );
}
