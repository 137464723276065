import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";

import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";

const FieldType = [
  { label: "001" },
  { label: "002" },
  { label: "003" },
  { label: "004" },
];
const QuoteDesign = [
  { label: "Single-line text" },
  { label: "When Saved" },
  { label: "option2" },
  { label: "Irregular" },
];
const CreditDesign = [
  { label: "Single-line text" },
  { label: "Never" },
  { label: "option2" },
  { label: "Irregular" },
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomFieldTab({ handleDrawerClose,selectedTab }) {
  const [state, setState] = React.useState({
    jason: false,
    antoine: false,
  });

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const [valueText, setValueText] = useState({
    fildOne: "",
  });

  const handleClear = () => {
    const temp = { ...valueText };
    temp.fildOne = "";
    setValueText(temp);
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const formatLabel = (label) => {
    const formattedLabel =
      label.charAt(0).toUpperCase() + label.slice(1).toLowerCase();
    return formattedLabel;
  };

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box className={"webview"}
        style={{
          display: "grid",
          gridTemplateColumns: "70% 30%",
          columnGap: "20px",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 0 ? "#00A1E4" : "white",
              color: value === 0 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Settings")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 1 ? "#00A1E4" : "white",
              color: value === 1 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Clients")}
            {...a11yProps(1)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 2 ? "#00A1E4" : "white",
              color: value === 2 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Invoices")}
            {...a11yProps(2)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 3 ? "#00A1E4" : "white",
              color: value === 3 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Recurring Invoices")}
            {...a11yProps(3)}
          />

          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 4 ? "#00A1E4" : "white",
              color: value === 4 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Payments")}
            {...a11yProps(4)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 5 ? "#00A1E4" : "white",
              color: value === 5 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Quotes")}
            {...a11yProps(4)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: value === 6 ? "#00A1E4" : "white",
              color: value === 6 ? "white" : "black",
              height: "56px",
            }}
            label={formatLabel("Credits")}
            {...a11yProps(4)}
          />
        </Tabs>

        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>
        {/* for mobile */}

        <Box className={"mobilVied"}
        style={{
   
          backgroundColor:'#1975D2',
 
       
          height: "56px",
          paddingBottom:"97px",
          paddingTop:'119px',
          position:"fixed",
          width:'100%',
          zIndex:"2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
          <div style={{ display: "flex",justifyContent:'space-between',margin:'0px 20px 0px 20px'}}>
          <Button
            onClick={handleDrawerClose}
           
          >
            <img style={{width:'14.98px',height:"14.66px"}} src="/assets/images/leftl.png" alt=""/>
        
            <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>{selectedTab}</span>
          </Button>
          <Button
         
      
          >
             <span style={{color:'white',paddingLeft:"15px",fontSize:'15px',fontWeight:"400",textTransform:"none"}}>Save</span>
            
          </Button>
        </div>
        <Tabs

          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          style={{width:"100%", backgroundColor:'#1975D2',}}
        >
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              height: "56px",
              borderBottom: value === 0 ? "4px solid white" : "none",
            }}
            label={formatLabel("Settings")}
            {...a11yProps(0)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 1 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Clients")}
            {...a11yProps(1)}
          />

<Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 2 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Invoices")}
            {...a11yProps(2)}
          />
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 3 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Recurring invoices")}
            {...a11yProps(3)}
          />
           <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 4 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Payments")}
            {...a11yProps(4)}
          /> 
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 5 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Quotes")}
            {...a11yProps(5)}
          /> 
          <Tab
            style={{
              textTransform: "none",
              fontWeight: "600",
              fontSize: "14px",
              backgroundColor: "#1975D2",
              color: "white" ,
              borderBottom: value === 6 ? "4px solid white" : "none",
              height: "56px",
            }}
            label={formatLabel("Credits ")}
            {...a11yProps(6)}
          />
          



        </Tabs>

      
      </Box>
<div className={"mobilVied"}>
<TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "245px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom: "100px",
          }}
        >
          {/* second portion */}

          {/* 1 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={FieldType}
            sx={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Number Padding"
                variant="standard"
              />
            )}
          />
          {/* 2 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={QuoteDesign}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Generate Number"
                variant="standard"
              />
            )}
          />

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Recurring Prefix"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <FormControl
            component="fieldset"
            variant="standard"
            style={{ paddingLeft: "50px", paddingTop: "10px", width: "100%" }}
          >
            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
              }}
              sx={{ m: 1, width: "100%" }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    columnGap: "20px",
                  }}
                >
                  <img src="/assets/images/ttt.svg" alt="Imagee" />
                  <Typography variant="body1" component="span">
                    Shared Invoice/Quote Counter
                  </Typography>
                </div>
              }
              control={
                <Switch
                  checked={state.jason}
                  onChange={handleChangebutton}
                  name="jason"
                />
              }
              labelPlacement="start"
            />

            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
              }}
              sx={{ m: 1, width: "100%" }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    columnGap: "20px",
                  }}
                >
                  <img src="/assets/images/ttt.svg" alt="Images" />
                  <Typography variant="body1" component="span">
                    Shared Invoice/Quote Counter
                  </Typography>
                </div>
              }
              control={
                <Switch
                  checked={state.antoine}
                  onChange={handleChangebutton}
                  name="antoine"
                />
              }
              labelPlacement="start"
            />
          </FormControl>
          {/* 3 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={CreditDesign}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField {...params} label="Reset Counter" variant="standard" />
            )}
          />
        </div>
      </TabPanel>
      <TabPanel className={"Othertab "} value={value} index={1}>
        Item Two
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={4}>
        Item Four
      </TabPanel>
</div>
<div className={"webview"}>
<TabPanel
        style={{}}
        className={"Othertab boxTwo"}
        value={value}
        index={0}
      >
        <div
          style={{
            overflow: "hidden",
            margin: "20px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
            marginBottom: "100px",
          }}
        >
          {/* second portion */}

          {/* 1 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={FieldType}
            sx={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Number Padding"
                variant="standard"
              />
            )}
          />
          {/* 2 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={QuoteDesign}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Generate Number"
                variant="standard"
              />
            )}
          />

          <TextField
            style={{ width: "100%", margin: " 10px 0px 20px 0px" }}
            label="Recurring Prefix"
            variant="standard"
            value={valueText.fildOne}
            onChange={(e) => {
              const temp = { ...valueText };
              temp.fildOne = e.target.value;
              setValueText(temp);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ margin: "0px 20px 5px 0px" }}
                >
                  {valueText && (
                    <IconButton edge="end" onClick={handleClear}>
                      <ClearIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />

          <FormControl
            component="fieldset"
            variant="standard"
            style={{ paddingLeft: "50px", paddingTop: "10px", width: "100%" }}
          >
            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
              }}
              sx={{ m: 1, width: "100%" }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    columnGap: "20px",
                  }}
                >
                  <img src="/assets/images/ttt.svg" alt="Imagee" />
                  <Typography variant="body1" component="span">
                    Shared Invoice/Quote Counter
                  </Typography>
                </div>
              }
              control={
                <Switch
                  checked={state.jason}
                  onChange={handleChangebutton}
                  name="jason"
                />
              }
              labelPlacement="start"
            />

            <FormControlLabel
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "0px",
              }}
              sx={{ m: 1, width: "100%" }}
              label={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    columnGap: "20px",
                  }}
                >
                  <img src="/assets/images/ttt.svg" alt="Images" />
                  <Typography variant="body1" component="span">
                    Shared Invoice/Quote Counter
                  </Typography>
                </div>
              }
              control={
                <Switch
                  checked={state.antoine}
                  onChange={handleChangebutton}
                  name="antoine"
                />
              }
              labelPlacement="start"
            />
          </FormControl>
          {/* 3 */}
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={CreditDesign}
            sx={{ width: "100%", margin: " 22px 0px 20px 0px" }}
            renderInput={(params) => (
              <TextField {...params} label="Reset Counter" variant="standard" />
            )}
          />
        </div>
      </TabPanel>
      <TabPanel className={"Othertab "} value={value} index={1}>
        Item Two
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={2}>
        Item Three
      </TabPanel>

      <TabPanel className={"Othertab"} value={value} index={3}>
        Item Three
      </TabPanel>
      <TabPanel className={"Othertab"} value={value} index={4}>
        Item Four
      </TabPanel>
</div>
   
    </Box>
  );
}
