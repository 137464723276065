import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import CompanyDetailsTab from "./CompanyDetails";
import UserDetailsTab from "./UserDetails";
import LocalizationTab from "./Localization";
import PaymentSettingTab from "./PaymentSetting";
import ProductSettingTab from "./ProductSetting";
import TaxSettingTab from "./TaxSetting";
import TaskSettingTab from "./TaskSetting";
import ExpenseSettingTab from "./ExpenseSetting";
import WorkflowSettingTab from "./WorkflowSetting";
import ImportExportTab from "./ImportExport";
import DeviceSettingTab from "./DeviceSetting";
import InvoiceDesignTab from "./InvoiceDesign";
import GeneratedMembersTab from "./GeneratedMembers";
import CustomFieldTab from "./CustomField";
import EmailSettingTab from "./EmailSetting";
import ClientPortalTab from "./ClientPortal";
import TemplatesReminderTab from "./TemplatesReminder";
import AccountManagementTab from "./AccountManagement";
import UserManagementTab from "./UserManagement";
import GroupSettingsTab from "./GroupSettings";
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';




import { BottomNavigation } from "@mui/material";

export default function SettingsMain() {
    const [selectedTab, setSelectedTab] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  // const navigate = useNavigate();


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setSelectedTab();




  };


  const setting = {
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#000000",
  };
  const openDrawer={
    backgroundColor:'white',
    display:'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '3%',
    alignItems: 'center',
    marginLeft: '11px',
    marginTop: '33px',
    marginBottom:'70px'
    
  }
  const MobileView={
    backgroundColor:' #F5F5F5',
    display:'grid',
    gridTemplateColumns: '45% 45%',
    columnGap: '3%',
    alignItems: 'center',
    justifyContent:'center',
    paddingTop: '173px',
    paddingBottom:'100px'
  }

  const closeDrawer={
    backgroundColor:'white',
    display:'grid',
    gridTemplateColumns: '31% 31% 31%' ,
    columnGap: '3%',
    alignItems: 'center',
    marginTop: '33px',
    marginBottom:'70px',
    marginLeft: '20px',
    marginRight:'20px'

  }
  const SettingsCard=[
    {
      icon:'/assets/images/company.svg',
      title:'Company Details',
      route:'Company Details'
   
    },
    {
      icon:'/assets/images/userL.svg',
      title:'User Details',
      route:'User Details'
      
    },
     {
      icon:'/assets/images/world.svg',
      title:'Localization',
      route:'Localization'
     
    },
    {
      icon:'/assets/images/payment.svg',
      title:'Payment Setting',
      route:'Payment Setting'
      
      
    }
    ,{
      icon:'/assets/images/persent.svg',
      title:'Tax Setting',
      route:'Tax Setting'
      
    },
    {
      icon:'/assets/images/tick.svg',
      title:'Product Setting',
      route:'Product Setting'
      
    },
     {
      icon:'/assets/images/clock.svg',
      title:'Task Setting',
      route:'Task Setting'
      
    } ,
    {
      icon:'/assets/images/ex.svg',
      title:'Expense Setting',
      route:'Expense Setting'
     
    } ,
     {
      icon:'/assets/images/workFlow.svg',
      title:'Workflow Setting',
      route:'Workflow Setting'
     
    } ,
    {
      icon:'/assets/images/import.svg',
      title:'Import / Export',
      route:'Import Export'
      
    },
      {
      icon:'/assets/images/device.svg',
      title:'Device Setting',
      route:'Device Setting'
     
    }, 
    {
      icon:'/assets/images/pen.svg',
      title:'Invoice Design',
      route:'Invoice Design'
      
    }, 
    {
      icon:'/assets/images/noname.svg',
      title:'Generated Members',
      route:'Generated Members'
      
    }, 
    {
      icon:'/assets/images/nn.svg',
      title:'Custom Field',
      route:'Custom Field'
      
    },
      {
      icon:'/assets/images/ee.svg',
      title:'Email Setting',
      route:'Email Setting'

    }, 
      {
      icon:'/assets/images/nnn.svg',
      title:'Client Portal',
      route:'Client Portal'
      
  
    },  
     {
      icon:'/assets/images/gru.svg',
      title:'Group Settings',
      route:'Group Settings'
    }, 
      {
      icon:'/assets/images/t.svg',
      title:'Templates& Reminder',
      route:'Templates And Reminder'
      
      
    },
      {
      icon:'/assets/images/per.svg',
      title:'User Management',
      route:'User Management'
    },  
      {
      icon:'/assets/images/ac.svg',
      title:'Account Management',
      route:'Account Management'
    },
  ] 
  // for mobile view
  // const [openMobile, setOpenMobile] = useState(false);
  // useEffect(() => {
  //   if(window.innerWidth <= 1270){ 
  //     console.log("WWWW",window.innerWidth)
  //   setOpenMobile(true);
  //   }else{  
  //   setOpenMobile(false);
  //   }
  // }, [openMobile]);
  // setOpenMobile(window.innerWidth <= 1270)
// console.log("openMobile", openMobile)
  // const isMobile = window.innerWidth < 1270;
  const getStyle = () => {
    if (open ) {
      return openDrawer;
    }
    else if (!open ) {
      return closeDrawer;
    }
     else {
      return {};
    }
  };

  // {open?openDrawer:closeDrawer || openMobile ?  MobileView : closeDrawer
  // const style = open ? openDrawer : (openMobile ? MobileView : closeDrawer);
  return (
  <>
     <Box className={"webview"} sx={{ display: 'flex' ,maxHeight: "88vh" }}>
  
  <div   style={{
         width: open ? "45%" : "100%",
         transition: "width 0.3s linear ",
       }} >

  <BottomNavigation
         style={{
           backgroundColor: "#ffffff",
           display: "flex",
           justifyContent: "space-between",
           alignItems: "center",
           paddingRight: "30px",
           paddingLeft: "3%",
           boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
           // borderBottom: "2px solid #D3CECE",
         }}
       >
         <div
           style={{
             display: "flex",
             // justifyContent: "start",
             justifyContent: "center",
             alignContent: "center",
             columnGap: "30px",
           }}
         >
           <p style={setting}>Settings</p>
           <div
             style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               columnGap: "20px",
             }}
           >
             <img src="/assets/images/home.svg" />
             {selectedTab? 
             <p style={{ fontSize: "14px", fontWeight: "600" }}>
             Home <span style={{ color: "#00A1E4" }}> / </span>
             <span style={{ color: "#00A1E4" }}>Settings /</span>{" "}
             <span style={{ color: "#00A1E4" }}>{selectedTab}</span>{" "}
          
           </p>:
           <p style={{ fontSize: "14px", fontWeight: "600" }}>
           Home <span style={{ color: "#00A1E4" }}> / </span>
           <span style={{ color: "#00A1E4" }}>Settings</span>{" "}
           </p>}
             
           </div>
         </div>

    
       </BottomNavigation>

  <div className={'scroolDemDashboard'}  onClick={handleDrawerOpen}>
  <div  style={getStyle()}>
   {/* work */}
   {SettingsCard.map((item,index)=>(

           <div  onClick={()=>setSelectedTab(item.route)} 
           key={index} style={{display:"flex",justifyContent:'center',alignItems:'center',columnGap:'10px',width:'100%',height:"50px",border:'1px solid rgba(0, 0, 0, 0.4)',marginBottom:'20px',backgroundColor: selectedTab === item.route ? "gainsboro" : "transparent",}}>
     <img style={{height:'14px',width:"15px"}} src={item.icon} alt=''/>
     <p style={{fontSize:"14px",fontWeight:'400',margin:'0px',fontFamily:'system-ui'}}>{item.title}</p>
   </div>
  
 

   ))}
      </div>  
  </div>
  </div>
   
 
   {/* </Main> */}

   <div style={{   width: open ? "60%" : "0%",
       transition: "width 0.3s linear ",
       boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",}}
       variant="persistent"
       anchor="right"
       // onClick={handleDrawerClose}
       >
       
      <div  style={{display:open?"block":'none'}}>
      
               {selectedTab === "Company Details" && <CompanyDetailsTab handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "User Details" && <UserDetailsTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Localization" && <LocalizationTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Payment Setting" && <PaymentSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Tax Setting" && <TaxSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Product Setting" && <ProductSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Task Setting" && <TaskSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Expense Setting" && <ExpenseSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Workflow Setting" && <WorkflowSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Import Export" && <ImportExportTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Device Setting" && <DeviceSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Invoice Design" && <InvoiceDesignTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Generated Members" && <GeneratedMembersTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Custom Field" && <CustomFieldTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Email Setting" && <EmailSettingTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Client Portal" && <ClientPortalTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Templates And Reminder" && <TemplatesReminderTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Account Management" && <AccountManagementTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "User Management" && <UserManagementTab  handleDrawerClose={handleDrawerClose} />}
               {selectedTab === "Group Settings" && <GroupSettingsTab  handleDrawerClose={handleDrawerClose} />}
       </div>   
         




   </div>
 </Box>

 <div  onClick={handleDrawerOpen}  className={'mobilVied'}>
  <div style={{display:open?"none":'block'}}>
  <div  style={MobileView}>
   {/* work */}
   {SettingsCard.map((item,index)=>(
// onClick={()=>setSelectedTab(item.route)}
           <div onClick={()=>setSelectedTab(item.route)}  key={index} style={{display:"flex",justifyContent:'center',alignItems:'center',columnGap:'10px',width:'100%',height:"50px",border:'1px solid rgba(0, 0, 0, 0.4)',marginBottom:'20px'}}>
     <img style={{height:'14px',width:"15px"}} src={item.icon} alt=''/>
     <p style={{fontSize:"14px",fontWeight:'400',margin:'0px',fontFamily:'system-ui'}}>{item.title}</p>
   </div>
  
 

   ))}
      </div> 
  </div>
 
  
 </div>
 <div className={'mobilVied'} style={{display:open?"block":'none'}}>
      
      {selectedTab === "Company Details" && <CompanyDetailsTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "User Details" && <UserDetailsTab  selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Localization" && <LocalizationTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Payment Setting" && <PaymentSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Tax Setting" && <TaxSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Product Setting" && <ProductSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Task Setting" && <TaskSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Expense Setting" && <ExpenseSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Workflow Setting" && <WorkflowSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Import Export" && <ImportExportTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Device Setting" && <DeviceSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Invoice Design" && <InvoiceDesignTab selectedTab={selectedTab}  handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Generated Members" && <GeneratedMembersTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Custom Field" && <CustomFieldTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Email Setting" && <EmailSettingTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Client Portal" && <ClientPortalTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Templates And Reminder" && <TemplatesReminderTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Account Management" && <AccountManagementTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "User Management" && <UserManagementTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
      {selectedTab === "Group Settings" && <GroupSettingsTab selectedTab={selectedTab} handleDrawerClose={handleDrawerClose} />}
</div>   
  </>
 
  );
}