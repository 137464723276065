import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { MdOutlineCancel } from "react-icons/md";
import Autocomplete from "@mui/material/Autocomplete";
import { MdOutlineCloudUpload } from "react-icons/md";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import FormHelperText from "@mui/material/FormHelperText";
import { Box, Button, TextField } from "@mui/material";

const invoiceTexRates = [{ label: "Disabled" }, { label: "Enabled" }];
const invoiceItemTaxRates = [{ label: "Disabled" }, { label: "Enabled" }];

const expenseTexRates = [{ label: "Disabled" }, { label: "Enabled" }];

export default function TaxSettingTab({ handleDrawerClose, selectedTab }) {
  const [state, setState] = React.useState({
    jason: false,
  });

  const handleChangebutton = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  //  inputFild with cross button

  return (
    <Box sx={{ width: "100%", maxHeight: "88vh" }}>
      <Box
        className={"webview"}
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          paddingRight: "20px",
          height: "56px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div style={{ display: "flex", columnGap: "20px" }}>
          <Button
            onClick={handleDrawerClose}
            style={{
              backgroundColor: "white",
              border: "1px solid rgba(0, 0, 0, 0.18)",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              color: "black",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCancel
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Cancel{" "}
          </Button>
          <Button
            style={{
              background: "#4D9A19",
              color: "white",
              width: "99px",
              height: "30px",
              borderRadius: "5px",
              marginLeft: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              columnGap: "7px",
            }}
            size="small"
          >
            <MdOutlineCloudUpload
              style={{
                height: "20px",
                width: "20px",
                paddingRight: "2px",
              }}
            />{" "}
            Save{" "}
          </Button>
        </div>
      </Box>
      {/* for mobile */}

      <Box
        className={"mobilVied"}
        style={{
          backgroundColor: "#1975D2",

          height: "56px",
          paddingBottom: "47px",
          paddingTop: "119px",
          position: "fixed",
          width: "100%",
          zIndex: "2",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "0px 20px 0px 20px",
          }}
        >
          <Button onClick={handleDrawerClose}>
            <img
              style={{ width: "14.98px", height: "14.66px" }}
              src="/assets/images/leftl.png"
              alt=""
            />

            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              {selectedTab}
            </span>
          </Button>
          <Button>
            <span
              style={{
                color: "white",
                paddingLeft: "15px",
                fontSize: "15px",
                fontWeight: "400",
                textTransform: "none",
              }}
            >
              Save
            </span>
          </Button>
        </div>
      </Box>

      <div style={{}} className={"Othertab boxTwo mobilVied"}>
        <div
          style={{
            overflow: "hidden",
            margin: "195px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Invoice Tex Rates"
                  variant="standard"
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceItemTaxRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="invoice item Tax Rates"
                  variant="standard"
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={expenseTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expense Tex Rates"
                  variant="standard"
                />
              )}
            />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "7% 94%",
                justifyContent: "start",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <img src="/assets/images/paecent.svg" alt=""></img>

              <FormControl component="fieldset" variant="standard">
                <FormControlLabel
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: "0px",
                  }}
                  sx={{ m: 1, width: "100%" }}
                  label="Inclusive Taxes"
                  control={
                    <Switch
                      checked={state.jason}
                      onChange={handleChangebutton}
                      name="jason"
                    />
                  }
                  labelPlacement="start"
                />
                <FormHelperText
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "4px",
                    margin: "-5px",
                    paddingBottom: "10px",
                  }}
                >
                  Exclusiv: 100 + 10% =100+10
                </FormHelperText>
                <FormHelperText
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "4px",
                    margin: "-5px",
                    paddingBottom: "10px",
                  }}
                >
                  Inclusive 100 + 10% =90.91+9.09
                </FormHelperText>
              </FormControl>
            </div>
          </Box>
        </div>
        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginBottom: "300px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "80%",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "#00A1E4",
              color: "#fff",
            }}
          >
            {" "}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "10px",
              }}
            >
              <img
               
                src="/assets/images/set.svg" alt=""
              ></img>
              CONFIGURE RATE
            </span>
          </button>
        </div>
      </div>

      <div style={{}} className={"Othertab boxTwo webview"}>
        <div
          style={{
            overflow: "hidden",
            margin: "25px 10px 25px 15px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "15px 10px",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "100%" },
            }}
            noValidate
            autoComplete="off"
          >
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Invoice Tex Rates"
                  variant="standard"
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={invoiceItemTaxRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="invoice item Tax Rates"
                  variant="standard"
                />
              )}
            />

            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={expenseTexRates}
              sx={{ width: "100%", marginLeft: "8px" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Expense Tex Rates"
                  variant="standard"
                />
              )}
            />

            <div
              style={{
                display: "grid",
                gridTemplateColumns: "7% 94%",
                justifyContent: "start",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              <img src="/assets/images/paecent.svg" alt=""></img>

              <FormControl component="fieldset" variant="standard">
                <FormControlLabel
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    margin: "0px",
                  }}
                  sx={{ m: 1, width: "100%" }}
                  label="Inclusive Taxes"
                  control={
                    <Switch
                      checked={state.jason}
                      onChange={handleChangebutton}
                      name="jason"
                    />
                  }
                  labelPlacement="start"
                />
                <FormHelperText
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "4px",
                    margin: "-5px",
                    paddingBottom: "10px",
                  }}
                >
                  Exclusiv: 100 + 10% =100+10
                </FormHelperText>
                <FormHelperText
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    paddingLeft: "4px",
                    margin: "-5px",
                    paddingBottom: "10px",
                  }}
                >
                  Inclusive 100 + 10% =90.91+9.09
                </FormHelperText>
              </FormControl>
            </div>
          </Box>
        </div>
        <div
          style={{
            alignItems: "center",
            textAlign: "center",
            marginBottom: "300px",
            marginTop: "20px",
          }}
        >
          <button
            style={{
              backgroundColor: "white",
              width: "644px",
              height: "43px",
              border: "1px solid rgba(0, 0, 0, 0.19)",
              outline: "none",
              fontWeight: "400",
              fontSize: "14px",
              backgroundColor: "#00A1E4",
              color: "#fff",
            }}
          >
            {" "}
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                columnGap: "52px",
              }}
            >
              <img
                style={{ marginLeft: "169px" }}
                src="/assets/images/set.svg"
              ></img>
              CONFIGURE RATE
            </span>
          </button>
        </div>
      </div>
    </Box>
  );
}
